import React from "react";
import "./App.css";
import Events from "./Components/Events/Events";
import Login from "./Components/Login/Login";
import Navbar from "./Components/Navbar/Navbar";
import Profile from "./Components/Profile/Profile";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Error404 from "./Components/Error/Error404";
import LoginButton from "./Components/Login/LoginButton";
import SingleEvent from "./Components/Events/SingleEvent";
import InviteCheck from "./Components/InviteCheck/InviteCheck";
import Signup from "./Components/Signup/Signup";
import Success from './Components/Login/Success';
import { CropperComp } from "./Components/Cropper/Cropper";
import IDCard from "./Components/IDCard/IDCard";
import InvitationLetter from "./Components/Invitation Letter/InvitationLetter";
import HotelVoucher from "./Components/Hotel Voucher/HotelVoucher";
import HotelValidate from "./Components/HotelValidate/HotelValidate";
import InvitationValidate from "./Components/Invitation Validate/InvitationValidate";
import InvitationCoreteam from "./Components/Invitation Validate/InvitationCoreteam";
import AcceptInvite from "./Components/Accept Invite/AcceptInvite";
import RoomSharing from "./Components/RoomSharing/RoomSharing";
import OperationsDashboard from "./Components/OperationsDashboard/OperationsDashboard";
import Certificate from "./Components/Certificate/Certificate";
import InvitaionAll from "./Components/InvitationAll/InvitaionAll";
import CertificateAll from "./Components/Certificate/CertificateAll";
import IDCardBulk from "./Components/IDCardBulk/IDCardBulk";
import Ambassdor from './Components/Ambassdor';
import Ticket from "./Components/Ticketops/Ticket";
import BulkDiplomaticStance from "./Components/BulkDiplomaticStance/BulkDiplomaticStance";
import IDCardGuest from "./Components/Registration/Guest/IDCardGuest";
import BulkIDCardGuest from "./Components/Registration/Guest/BulkIDCardGuest";
import IDCardCoreTeam from "./Components/Registration/CoreTeam/IDCardCoreTeam";
import LocalTeamIdCard from "./Components/Registration/LocalTeam/LocalTeamIdCard";
import ObservaersIdCard from "./Components/Registration/Observers/ObservaersIdCard";
import BulkIDCardCoreTeam from './Components/Registration/CoreTeam/BulkIDCardCoreTeam';
import BulkIDCardLocalTeam from "./Components/Registration/LocalTeam/BulkIDCardLocalTeam";
import BulkIDCardObservers from "./Components/Registration/Observers/BulkIDCardObservers";
import DiplomaticExperts from "./Components/Diplomatic Experts/DiplomaticExperts";
import CountryRep from "./Components/Country Rep/CountryRep";
import CertificateAllLocalTeam from "./Components/Registration/LocalTeam/CertificateAllLocalTeam";
import CertificateAllGuest from "./Components/Registration/Guest/CertificateAllGuest";
import CertificateAllCoreTeam from "./Components/Registration/CoreTeam/CertificateAllCoreTeam";
import HotelPassCheckInTeam from "./Components/HotelPassCheckInTeam/HotelPassCheckInTeam";
import BulkIdCardParticipants from "./Components/InvitationEvent/BulkIdCardParticipants";
import CertificateParticipants from "./Components/InvitationEvent/CertificateParticipants";
import CertificateParticipent from "./Components/InvitationEvent/CertificateParticipent";
import SingleCard from "./Components/InvitationEvent/SingleCard";
import DelegateRegistrationForm from './Components/Delegate Registration Form/DelegateRegistrationForm';
import ImpactDirectorCertificate from "./Components/Certificate/ImpactDirectorCertificate";
import CeritficateAllImpactDirectors from "./Components/Certificate/CeritficateAllImpactDirectors";
import CertificateAllImpactDirectorGuest from "./Components/Registration/Guest/CertificateAllImpactDirectorGuest";
import CertificateAllCoreTeamForImpactDirector from "./Components/Registration/CoreTeam/CertificateAllCoreTeamForImpactDirector";
import CertificateAllImpactDirectorForLocalTeam from "./Components/Registration/LocalTeam/CertificateAllImpactDirectorForLocalTeam";
import IDCardImpactDirector from "./Components/IDCard/IDCardImpactDirector";
import IDCardBulkForImpactDirector from "./Components/IDCardBulk/IDCardBulkForImpactDirector";
import BulkIDCardGuestForImpactDirector from "./Components/Registration/Guest/BulkIDCardGuestForImpactDirector";
import SingleIDCardGuestForImpactDirector from "./Components/Registration/Guest/SingleIDCardGuestForImpactDirector";
import BulkIDCardLocalTeamForImpactDirector from "./Components/Registration/LocalTeam/BulkIDCardLocalTeamForImpactDirector";
import SingleIDCardLocalTeamForImpactDirector from "./Components/Registration/LocalTeam/SingleIDCardLocalTeamForImpactDirector";
import BulkIDCardCoreTeamForImpactDirector from "./Components/Registration/CoreTeam/BulkIDCardCoreTeamForImpactDirector";
import SingleIDCardCoreTeamForImpactDirector from "./Components/Registration/CoreTeam/SingleIDCardCoreTeamForImpactDirector";






function App() {

  
    
  


  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          {/* <Route path="/delegate-form/:id" element={<DelegateRegistrationForm />} /> */}
          <Route path="/" element={<Events />} />
          <Route path="/ambassador" element={<Ambassdor/>} />
          <Route path="/diplomaticExperts" element={< DiplomaticExperts/>} />
          <Route path="/countryRep" element={< CountryRep/>} />
          <Route path="*" element={<Error404 />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logincomp" element={<LoginButton />} />
          <Route path="/eventdetail/:eventDetail" element={<SingleEvent/>} />
          <Route path="/invitelogin/:inviteId" element={<InviteCheck />}/>
          <Route path="/signupDelegate/:delegateEmail" element={<Signup />}/>
          <Route path="/successSignup" element={<Success />}/>
          <Route path="/IDCard/:delegateid" element={<IDCard />}/>
          <Route path="/IDCard-Impact-Director/:delegateid" element={<IDCardImpactDirector />}/>
          <Route path="/InvitationLetter/:eventId" element={<InvitationLetter />}/>
          <Route path="/InvitationValidate/:eventId/:username" element={<InvitationValidate />}/>
          <Route path="/InvitationLatterCorTeam/:eventId/:core_team_id/:username" element={<InvitationCoreteam />}/>
          <Route path="/HotelVoucher/:eventId" element={<HotelVoucher />}/>
          <Route path="/HotelValidate/:eventId/:username" element={<HotelValidate />}/>
          <Route path="/hotel-pass/:eventId/:username" element={<HotelPassCheckInTeam />}/>
          <Route path="/cropper" element={<CropperComp />}/>
          <Route path="/AcceptInvitation/:inviteId" element={<AcceptInvite />}/>
          <Route path="/RoomSharing/:eventID/:delegateID" element={<RoomSharing />}/>
          <Route path="/Certificate/:eventId/:delegateId" element={<Certificate />}/>
          <Route path="/Certificate-impact-directors/:eventId/:delegateId" element={<ImpactDirectorCertificate />}/>
          <Route path="/OperationsDashboard/:eventID/*" element={<OperationsDashboard />}/>
          <Route path="/InvitationAll/:eventId" element={<InvitaionAll />}/>
          <Route path="/ticket-details/:eventId" element={<Ticket />}/>
          <Route path="/CertificateAll/:eventId" element={<CertificateAll />}/>
          <Route path="/DownloadCertificateForImpactDirectors/:eventId" element={<CeritficateAllImpactDirectors />}/>
          <Route path="/IDCardBulk/:eventId" element={<IDCardBulk />}/>
          <Route path="/id-card-impact/:eventId" element={<IDCardBulkForImpactDirector />}/>
          <Route path="/CertificateParticipent/:eventId/:delegateId" element={<CertificateParticipent />}/>
          <Route path="/IdCardParticipent/:eventId/:delegateId" element={<SingleCard />}/>
          <Route path="/IDCardParticipants/:eventId" element={<BulkIdCardParticipants />}/>
          <Route path="/bulkCertificateParticipants/:eventId" element={<CertificateParticipants/>}/>
          <Route path="/DiplomaticStance/:eventId" element={<BulkDiplomaticStance />}/>
          <Route path="/guestIdCard/:guestid" element={<IDCardGuest />}/>
          <Route path="/guestIdCard-impact-director/:guestid" element={<SingleIDCardGuestForImpactDirector />}/>
          <Route path="/guestBulkIdCard/:eventid" element={<BulkIDCardGuest />}/>
          <Route path="/guestBulkIdCard-impact-director/:eventid" element={<BulkIDCardGuestForImpactDirector />}/>
          <Route path="/coreTeamIdCard/:corememberId" element={<IDCardCoreTeam />}/>
          <Route path="/coreTeamIdCard-impact-director/:corememberId" element={<SingleIDCardCoreTeamForImpactDirector />}/>
          <Route path="/bulkIDCardCoreTeam/:eventid" element={<BulkIDCardCoreTeam/>}/>
          <Route path="/bulkIDCardCoreTeam-impact-director/:eventid" element={<BulkIDCardCoreTeamForImpactDirector/>}/>
          <Route path="/localTeamIdCard/:corememberId" element={<LocalTeamIdCard/>}/>
          <Route path="/localTeamIdCard-impact-director/:corememberId" element={<SingleIDCardLocalTeamForImpactDirector/>}/>
          <Route path="/bulkIDCardLocalTeam/:eventid" element={<BulkIDCardLocalTeam/>}/>
          <Route path="/bulkIDCardLocalTeam-impact-director/:eventid" element={<BulkIDCardLocalTeamForImpactDirector/>}/>
          <Route path="/bulkIDCardLocalTeamImpact/:eventId" element={<CertificateAllImpactDirectorForLocalTeam/>}/>
          <Route path="/observaersIdCard/:corememberId" element={<ObservaersIdCard/>}/>
          <Route path="/bulkIDCardObservers/:eventid" element={<BulkIDCardObservers/>}/>
          <Route path="/bulkCertificateLocalTeam/:eventId" element={<CertificateAllLocalTeam/>}/>
          <Route path="/bulkCertificateCoreTeam/:eventId" element={<CertificateAllCoreTeam/>}/>
          <Route path="/bulkCertificateCoreTeamImpactDirector/:eventId" element={<CertificateAllCoreTeamForImpactDirector/>}/>
          <Route path="/bulkCertificateGuest/:eventId" element={<CertificateAllGuest/>}/>
          <Route path="/bulkCertificateGuestImpactDirector/:eventId" element={<CertificateAllImpactDirectorGuest/>}/>
         
          
          {/* If user is logged in */}
          {/* {login && <Route path="/profile" element={<Profile />} />} */}

          {/* If user is not logged in */}
          {/* {login ? null : <Route path="/login" element={<Login />} />} */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
