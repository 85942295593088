import React, { useState } from 'react'
import Avatar from 'react-avatar-edit'
import './Cropper.css'


export const CropperComp = () => {
    const [src , setSrc]=useState(null)
    const [preview , setPreview]=useState(null)
    const onClose=()=>{
        setPreview(null)
    }
    const onCrop=(view)=>{
        setPreview(view)
    }

  return (
    <div className='flex flex-col justify-center mt-12'>
    <div className='flex justify-center'>
        <Avatar
        width={400}
        height={300}
        onCrop={onCrop}
        exportSize={500}
        onClose={onClose}
        src={src}
        ></Avatar>
        </div>
        <div className='mt-12 flex justify-center'>
        {preview && <img src={preview} alt="Cropped Image" />}
        </div>
    </div>
  )
}
