import React, { useEffect } from "react";
import "./Login.css";
import LogoFull from "../../img/LogoFull.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as qs from "qs";
import axios from "axios";
import { login } from "../../features/auth/authSlice";
const Login = () => {

  const nevigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  var domainLink = window.location.href;
  const firstSplit = domainLink.split("code=");
  const secondSplit = firstSplit[1].split("&");
  const token = secondSplit[0];
  

  async function isLoggedin() {
    const response = await axios.post(
      "https://dash.bestdiplomats.org/api/method/frappe.integrations.oauth2.get_token",
      qs.stringify({
        grant_type: "authorization_code",
        code: token,
        redirect_uri: window.$FWDurl+"/login",
        client_id: window.$ClientID,
        scope: "all openid",
      })
    );
    var timeIn = Date.now();
    localStorage.setItem("date", timeIn);
    localStorage.setItem("first_reload", false);
console.log("response",response)
    dispatch(login(JSON.stringify(response.data)));

   
  }
  useEffect(() => {
    isLoggedin();
  }, []);

  useEffect(() => {
    if (isSuccess || user) {
      nevigate("/");

    }
    console.log("window.$FWDurl",window.$FWDurl);
    // dispatch(reset());
  }, [user, isLoading, isError, isSuccess, message, nevigate, dispatch]);

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <div className="overflow-hidden shadow-xl mt-5 col-span-6 md:mt-6">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <img
                      className="mx-auto h-12 w-auto mb-9"
                      src={LogoFull}
                      alt="Your Company"
                    />
                    <div className=" flex justify-center items-center">
                      <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-gray-900"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
