import React, { useEffect, useState } from 'react'

import BlankCertificate from '../../img/Certificatenew.png'
import FontAbhaya from '../../AbhayaLibre-SemiBold.ttf'
import RobotoBold from '../../Roboto-Bold.ttf'
import AbhayaRegular from '../../AbhayaLibre-Regular.ttf'
import AbhayaBold from '../../AbhayaLibre-Bold.ttf'
import QRCode from 'qrcode'
import { Document, Page, Text, View, StyleSheet, Image, Font, } from '@react-pdf/renderer';
import moment from "moment";

const AwardCertificate = (props) => {

    if (
        (
          props.FirstName +
          props.LastName
        ).length > 25
      ) {
        
        var setFontSize = 32;
        var setFullNameTop = 245;
        var setLowerViewTop = 20;
      } else {
        
        var setFontSize = 48;
        var setFullNameTop = 230;
        var setLowerViewTop = 0;
      }

    Font.register({
        family: "AbhayaLibreSemiBold",
        src: FontAbhaya,
      });
      Font.register({
        family: "RobotoBold",
        src: RobotoBold,
      });
      Font.register({
        family: "AbhayaRegular",
        src: AbhayaRegular,
      });
      Font.register({
        family: "AbhayaBold",
        src: AbhayaBold,
      });
      const styles = StyleSheet.create({
        page: {},
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
        viewer: {
          width: 870,
          height: 690,
        },
        bgimages: {
          height:595,
          width:842,
          position: 'absolute'
        },
        title: {
          top: 15,
          textAlign: "center",
          fontSize: 58,
          color: "white",
          fontFamily: "AbhayaLibreSemiBold",
        },
        participation: {
          top: 15,
          textAlign: "center",
          fontSize: 21,
          color: "#F3D649",
          fontFamily: "RobotoBold",
        },
        initialline: {
          letterSpacing: 1.5,
          top: 210,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        showcase: {
          top: 250,
          textAlign: "center",
          fontSize: 17,
          color: "#000000",
          fontFamily: "AbhayaRegular",
          marginHorizontal: 140,
        },
        showcase1: {
            top: 252,
            textAlign: "center",
            fontSize: 17,
            color: "#000000",
            fontFamily: "AbhayaRegular",
            marginHorizontal: 140,
          },
        complete: {
          top: 260,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        completeDate: {
          top: 260,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        },
        completeLocation: {
          top: 260,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        fullname: {
          textAlign: "center",
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        },
        headNameRight: {
          top:288,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
        },
        headNameLeft: {
          top:288,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
          marginLeft: 18,
        },
        headPositionRight: {
          top:286,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginRight: 45,
        },
        headPositionLeft: {
          top:286,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginLeft: 55,
        },
        QRImage: {
          top:504,
          left: 390,
          position: "absolute",
          width: 75,
        },
      });

    // States
   
    const [qrUriImageFile, setqrUriImageFile] = useState();
  
  const generateQR = async (text) => {
    try {
      setqrUriImageFile(
        await QRCode.toDataURL(text, {
          color: {
            dark: "#4591DC", // Blue dots
            light: "#0000", // Transparent background
          },
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  generateQR(props.qrText);
    
  return (
  
    <Document>
    <Page size={[842, 595]}>
      <Image
        src={BlankCertificate}
        style={styles.bgimages}
      ></Image>
      <Text style={styles.title}>CERTIFICATE</Text>
      <Text style={styles.participation}>OF AWARD</Text>
      <Text style={styles.initialline}>
        THIS CERTIFICATE IS ISSUED TO
      </Text>
      <Text
        style={{
          top: setFullNameTop,
          fontSize: setFontSize,
          textAlign: "center",
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        }}
      >
        {""}
        {"" +
          props.FirstName +
          " " +
          props.LastName}
      </Text>
      
      <View style={{ top: setLowerViewTop }}>
      <Text style={{
        top: 240,
          fontSize: 22,
          textAlign: "center",
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        }} >{props.type}</Text>
        <Text style={styles.showcase}>
       in recognition of his/her outstanding performance during the
       </Text>
       <Text style={styles.showcase1}>  Diplomatic Simulation at {props.event?.title}.
        </Text>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Text style={styles.complete}>
            {"Completed this day," + " "}
          </Text>
          <Text style={styles.completeDate}>
            {moment(props?.end_date).format(
              "D MMMM, YYYY"
            ) +
              "," +
              " "}
          </Text>
          <Text style={styles.completeLocation}>
            {""}
            {props?.country}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 50,
          }}
        >
          <Text style={styles.headNameLeft}>
          Oleksandra Mamchii
          </Text>

          <Text style={styles.headNameRight}>
            Fawad Ali Langah
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 25,
          }}
        >
          <Text style={styles.headPositionLeft}>
          Academic Lead
          </Text>

          <Text style={styles.headPositionRight}>
            Director General
          </Text>
        </View>
      </View>
      {/* <Image
        source={generateQR(
          `https://verify.bestdiplomats.org/verify/${response.data.data[index]?.delegate_profile.name}-${eventId}-${response.data.data[index]?.name}`
        )}
        style={styles.QRImage}
      ></Image> */}
    </Page>
  </Document>
  )
}



export default AwardCertificate