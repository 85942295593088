import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import "./RoomSharing.css"
import {
 
  ArrowUpCircleIcon,
  PlusCircleIcon,
  ArrowDownCircleIcon,
  CheckBadgeIcon,
  
} from '@heroicons/react/24/outline'
import axios from 'axios'
import { useParams } from 'react-router-dom'






function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const RoomSharing = () => {
    // Configrations 
    var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
    // -------States---------
    const { eventID } = useParams();
    const { delegateID } = useParams();
    const [loading, setLoading] = useState(false)
    // Alert States
    const [doneMassage, setDoneMessage] = useState(false)
    const [duplicateMassage, setDuplicateMessage] = useState(false)
    const [noLongerMassage, setNoLongerMessage] = useState(false)
    const [elseMassage, setElseMessage] = useState(false)
    const [requestCanceledMassage, setRequestCanceledMessage] = useState(false)
    const [approveCanceledMessage, setApproveCanceledMessage] = useState(false)
    const [requestAcceptMassage, setRequestAcceptMessage] = useState(false)
    const [noContentMassage, setNoContentMessage] = useState(false)
    const [noContentMassageRecived, setNoContentMessageRecived] = useState(false)
    const [noContentMassageApproved, setNoContentMessageApproved] = useState(false)
    // Data Storing States
    const [eventRegData, setEventRegData] = useState(null)
    const [approvedRequest, setApprovedRequest] = useState(null)
    const [currentUserRegistration, setCurrentUserRegistration] = useState(null)
    const [sentRequestsCurrentUser, setSentRequestsCurrentUser] = useState(null)
    const [recevieRequestsCurrentUser, setRecevieRequestsCurrentUser] = useState(null)
    const [approved, setApproved] = useState(true)
    // Tabs States
    const [sentRequest, setSentRequest] = useState(false)
    const [receivedRequest, setReceivedRequest] = useState(false)
    const [addRequest, setAddRequest] = useState(false)
   
    // Use Effect Calls
    useEffect(() => {
        const fetchEventRegisteration = async () => {
          try {
            setLoading(true);
            const result = await axios
              .get(
                "https://dash.bestdiplomats.org/api/method/get-registrations?event=" + eventID,
                config
              )
              .then((response) => {
                // console.log(response.data.data);
                setEventRegData(response.data.data);
                for (let index = 0; index < response.data.data.length; index++) {
                    if(response.data.data[index].delegate_profile.name==delegateID){
                        response.data.data.splice(index,1)
                        setEventRegData(response.data.data)
                    }
                    
                   }
               
              });
            setLoading(false);
          } catch (e) {
            console.log(e);
          }
        };
        fetchEventRegisteration();
      }, []);

    //   Approved Requests
    
    useEffect(() => {
        const fetchEventRegisteration = async () => {
            try {
              setLoading(true);
              const result = await axios
                .get(
                  `https://dash.bestdiplomats.org/api/method/approved-requests?filters={"request_status":"Approved","event":"${eventID}"}&or_filters={"requested_by":"${delegateID}","requested_for":"${delegateID}"}`,
                  config
                )
                .then((response) => {
                 
                  setApprovedRequest(response.data.data);
                  // console.log(response.data.data);
                
                });
              setLoading(false);
            } catch (e) {
              console.log(e);
              if (e.code == "ERR_NETWORK") {
                setNoContentMessageApproved(true);
            }else{
                setElseMessage(true)
                setTimeout(() => {
                    setElseMessage(false)
                }, 2000);
            }
            }
          };
          fetchEventRegisteration();
      }, [approveCanceledMessage]);
    //   cancel approved request
      const cancelApprovedRequest = (person) => {
        var updateJSON={
            request_status : "Rejected",
            unique_request_id: new Date()
        }
        const createNewRegistration = async () => {
            try {
              setLoading(true);
              const result = await axios
                .put(
                  `https://dash.bestdiplomats.org/api/resource/Room%20Sharing%20Requests/${person.name}`,updateJSON,config
                )
                .then((response) => {
                 
                  setApproveCanceledMessage(true)
                  setTimeout(() => {
                    setApproveCanceledMessage(false)
                  }, 2000);
                  
                 
                });
              setLoading(false);
            } catch (e) {
              console.log(e);
              setElseMessage(true)
              setTimeout(() => {
                  setElseMessage(false)
              }, 2000);
            }
          };
          createNewRegistration();
    }
    //   Current User Registeration
    
    useEffect(() => {
        const fetchEventRegisteration = async () => {
            try {
              setLoading(true);
              const result = await axios
                .get(
                  `https://dash.bestdiplomats.org/api/method/get-registration?event=${eventID}&delegate=${delegateID}`,
                  config
                )
                .then((response) => {
                 
                  setCurrentUserRegistration(response.data);
                //   console.log(response.data.data);
                 
                });
              setLoading(false);
            } catch (e) {
              console.log(e);
            }
          };
          fetchEventRegisteration();
      }, []);
      // Recevied Requests
useEffect(() => {
    const fetchSentRequests = async () => {
        try {
          setLoading(true);
          const result = await axios
            .get(
              `https://dash.bestdiplomats.org/api/method/get-sharing-requests?filters=[[%22requested_for%22,%22=%22,%22${delegateID}%22],[%22request_status%22,%22=%22,%22Pending%22]]`,
              config
            )
            .then((response) => {
             
              setRecevieRequestsCurrentUser(response.data.data);
            //   console.log(response.data.data);
             
            });
          setLoading(false);
        } catch (e) {
          // console.log(e.response?.status);
          if (e.code == "ERR_NETWORK") {
            setNoContentMessageRecived(true);
        }else{
            setElseMessage(true)
            setTimeout(() => {
                setElseMessage(false)
            }, 2000);
        }
        }
      };
      fetchSentRequests();
  }, [requestCanceledMassage,requestAcceptMassage]);

    //   Current User Sent Requesta
    
    useEffect(() => {
        const fetchSentRequests = async () => {
            try {
              setLoading(true);
              const result = await axios
                .get(
                  `https://dash.bestdiplomats.org/api/method/get-sharing-requests?filters=[[%22requested_by%22,%22=%22,%22${delegateID}%22],[%22request_status%22,%22=%22,%22Pending%22]]`,
                  config
                )
                .then((response) => {
                 setNoContentMessage(false);
                  setSentRequestsCurrentUser(response.data.data);
                //   console.log(response.data.data);
                 
                });
              setLoading(false);
            } catch (e) {
              // console.log(e.response?.status);
              if (e.code == "ERR_NETWORK") {
                setNoContentMessage(true);
            }else{
                setElseMessage(true)
                setTimeout(() => {
                    setElseMessage(false)
                }, 2000);
            }
            }
          };
          fetchSentRequests();
      }, [requestCanceledMassage,doneMassage]);
    //   --Cancel User Sent Request
    const cancelSentRequest = (person) => {
        var updateJSON={
            request_status : "Rejected",
            unique_request_id: new Date()
        }
        const createNewRegistration = async () => {
            try {
              setLoading(true);
              const result = await axios
                .put(
                  `https://dash.bestdiplomats.org/api/resource/Room%20Sharing%20Requests/${person.name}`,updateJSON,config
                )
                .then((response) => {
                 
                  setRequestCanceledMessage(true)
                  setTimeout(() => {
                    setRequestCanceledMessage(false)
                  }, 2000);
                  
                 
                });
              setLoading(false);
            } catch (e) {
              console.log(e);
              setElseMessage(true)
              setTimeout(() => {
                  setElseMessage(false)
              }, 2000);
            }
          };
          createNewRegistration();
    }
// Recevied Requests
useEffect(() => {
    const fetchSentRequests = async () => {
        try {
          setLoading(true);
          const result = await axios
            .get(
              `https://dash.bestdiplomats.org/api/method/get-sharing-requests?filters=[[%22requested_for%22,%22=%22,%22${delegateID}%22],[%22request_status%22,%22=%22,%22Pending%22]]`,
              config
            )
            .then((response) => {
             
              setRecevieRequestsCurrentUser(response.data.data);
            //   console.log(response.data.data);
             
            });
          setLoading(false);
        } catch (e) {
          // console.log(e.response?.status);
          if (e.code == "ERR_NETWORK") {
            setNoContentMessageRecived(true);
        }else{
            setElseMessage(true)
            setTimeout(() => {
                setElseMessage(false)
            }, 2000);
        }
        }
      };
      fetchSentRequests();
  }, [requestCanceledMassage,requestAcceptMassage]);
//   Reject Recived Request 
const cancelRecivedRequest = (person) => {
    var updateJSON={
        request_status : "Rejected",
        unique_request_id: new Date()
    }
    const createNewRegistration = async () => {
        try {
          setLoading(true);
          const result = await axios
            .put(
              `https://dash.bestdiplomats.org/api/resource/Room%20Sharing%20Requests/${person.name}`,updateJSON,config
            )
            .then((response) => {
             
              setRequestCanceledMessage(true)
              setTimeout(() => {
                setRequestCanceledMessage(false)
              }, 2000);
              
             
            });
          setLoading(false);
        } catch (e) {
          console.log(e);
          setElseMessage(true)
          setTimeout(() => {
              setElseMessage(false)
          }, 2000);
        }
      };
      createNewRegistration();
}
// Accept Recived Request
const acceptRecivedRequest = (person) => {
    var updateJSON={
        request_status : "Approved"
    }
    const createNewRegistration = async () => {
        try {
          setLoading(true);
          const result = await axios
            .put(
              `https://dash.bestdiplomats.org/api/resource/Room%20Sharing%20Requests/${person.name}`,updateJSON,config
            )
            .then((response) => {
             
              setRequestAcceptMessage(true)
              setTimeout(() => {
                setRequestAcceptMessage(false)
              }, 2000);
              
             
            });
          setLoading(false);
        } catch (e) {
          console.log(e);
          setElseMessage(true)
          setTimeout(() => {
              setElseMessage(false)
          }, 2000);
        }
      };
      createNewRegistration();
}
    //   Create New Registeration
    const newRegistration = (person)=>{
        const uniqueRoomSharing= (current,peer)=>{
            if(current>peer){
                return(`${eventID}-${current}-${peer}`)
            }else{
                return(`${eventID}-${peer}-${current}`)
            }
        }
        var payloadJSON={
            requested_by : currentUserRegistration.data.delegate_profile.name,
            sender_registration : currentUserRegistration.data.name,
            requested_for : person.delegate_profile.name,
            receiver_registration : person.name,
            event : eventID,
            unique_request_id : uniqueRoomSharing(currentUserRegistration.data.delegate_profile.name,person.delegate_profile.name)
        }
        const createNewRegistration = async () => {
            try {
              setLoading(true);
              const result = await axios
                .post(
                  `https://dash.bestdiplomats.org/api/resource/Room%20Sharing%20Requests`,payloadJSON,config
                )
                .then((response) => {
                 
                  
                  setDoneMessage(true)
                  setTimeout(() => {
                    setDoneMessage(false)
                  }, 2000);
                 
                });
              setLoading(false);
            } catch (e) {
              console.log(e);
              if (e.response?.status == 417) {
                setDuplicateMessage(true)
                setTimeout(() => {
                    setDuplicateMessage(false)
                }, 2000);
            }else if (e.response?.status == 403) {
                setNoLongerMessage(true)
                setTimeout(() => {
                    setNoLongerMessage(false)
                }, 2000);
            }else{
                setElseMessage(true)
                setTimeout(() => {
                    setElseMessage(false)
                }, 2000);
            }
            }
          };
          createNewRegistration();
    }


  
  return (
    <div>
    {doneMassage && (<div className="flex ml-4 shadow-xl bg-green-100 w-96 rounded-lg p-4 mb-4 fixed z-10">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>    <p className="ml-3 text-sm text-green-700">
    <span className="font-medium">Request Sent Successfully</span> 
  </p>
</div>)}
    {requestCanceledMassage && (<div className="flex ml-4 shadow-xl bg-green-100 w-96 rounded-lg p-4 mb-4 fixed z-10">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>    <p className="ml-3 text-sm text-green-700">
    <span className="font-medium">Request Canceled Successfully</span> 
  </p>
</div>)}
    {approveCanceledMessage && (<div className="flex ml-4 shadow-xl bg-green-100 w-96 rounded-lg p-4 mb-4 fixed z-10">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>    <p className="ml-3 text-sm text-green-700">
    <span className="font-medium">Request Canceled Successfully</span> 
  </p>
</div>)}
    {requestAcceptMassage && (<div className="flex ml-4 shadow-xl bg-green-100 w-96 rounded-lg p-4 mb-4 fixed z-10">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>    <p className="ml-3 text-sm text-green-700">
    <span className="font-medium">Request Accepted Successfully</span> 
  </p>
</div>)}
    {duplicateMassage && (<div className="flex ml-4 shadow-xl bg-red-100 w-96 rounded-lg p-4 mb-4 fixed z-10">
  <svg className="w-5 h-5 text-red-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <p className="ml-3 text-sm text-red-700">
    <span className="font-medium">Duplicate Request Not Allowed</span> 
  </p>
</div>)}
    {noLongerMassage && (<div className="flex ml-4 shadow-xl bg-red-100 w-96 rounded-lg p-4 mb-4 fixed z-10">
  <svg className="w-5 h-5 text-red-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <p className="ml-3 text-sm text-red-700">
    <span className="font-medium">Room Sharing request is no longer available</span> 
  </p>
</div>)}
    {elseMassage && (<div className="flex ml-4 shadow-xl bg-red-100 w-96 rounded-lg p-4 mb-4 fixed z-10">
  <svg className="w-5 h-5 text-red-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <p className="ml-3 text-sm text-red-700">
    <span className="font-medium">Something went wrong!</span> 
  </p>
</div>)}
    
      <Disclosure as="div" className="relative overflow-hidden bg-sky-700 pb-32">
        {({ open }) => (
          <>
           
            <div
              aria-hidden="true"
              className={classNames(
                open ? 'bottom-0' : 'inset-y-0',
                'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0'
              )}
            >
              <div className="absolute inset-0 flex">
                <div className="h-full w-1/2" style={{ backgroundColor: '#4591DC' }} />
                <div className="h-full w-1/2" style={{ backgroundColor: '#4591DC' }} />
              </div>
              <div className="relative flex justify-center">
                <svg
                  className="flex-shrink-0"
                  width={1750}
                  height={308}
                  viewBox="0 0 1750 308"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#4591DC" />
                  <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#4591DC" />
                  <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#4591DC" />
                  <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#4591DC" />
                </svg>
              </div>
            </div>
            <header className="relative py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-white">Room Sharing</h1>
              </div>
            </header>
          </>
        )}
      </Disclosure>
      
      <main className="relative -mt-32">
      
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  
                    <button
                      
                      onClick={()=>{setApproved(true)
                      setSentRequest(false)
                      setReceivedRequest(false)
                      setAddRequest(false)}}
                      className={classNames(
                        approved
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={approved ? 'page' : undefined}
                    >
                      <CheckBadgeIcon
                        className={classNames(
                          approved
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">Approved</span>
                    </button>
                    <button
                      
                      onClick={()=>{setApproved(false)
                      setSentRequest(true)
                      setReceivedRequest(false)
                      setAddRequest(false)}}
                      className={classNames(
                        sentRequest
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={sentRequest ? 'page' : undefined}
                    >
                      <ArrowUpCircleIcon
                        className={classNames(
                          sentRequest
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">Sent Request</span>
                    </button>
                    <button
                      
                      onClick={()=>{setApproved(false)
                      setSentRequest(false)
                      setReceivedRequest(true)
                      setAddRequest(false)}}
                      className={classNames(
                        receivedRequest
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={receivedRequest ? 'page' : undefined}
                    >
                      <ArrowDownCircleIcon
                        className={classNames(
                          receivedRequest
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">Recevied Request</span>
                    </button>
                    <button
                      
                      onClick={()=>{setApproved(false)
                      setSentRequest(false)
                      setReceivedRequest(false)
                      setAddRequest(true)}}
                      className={classNames(
                        addRequest
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={addRequest ? 'page' : undefined}
                    >
                      <PlusCircleIcon
                        className={classNames(
                          addRequest
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">Add New Request</span>
                    </button>
                 
                </nav>
              </aside>
              <div className="divide-y divide-gray-200 lg:col-span-9 pt-6 p-6">
              {/* <span className='flex flex-row justify-center '> <p className=' xl:text-white lg:text-white md:text-gray-500 sm:text-gray-500 '>---Scroll---</p></span> */}
              {/* Approved Tab */}
            {approved && (noContentMassageApproved ? (<span className='flex justify-center'>No approved roommate request found</span>):(<div className="mt-4 flex flex-col">
        <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className=" min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-scroll">
              <table className="min-w-full divide-y divide-gray-300 overflow-x-scroll">
                <thead className="bg-gray-50">
                  <tr>
                    
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Requested By
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nationality
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Requested For
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nationality
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {approvedRequest?.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={"https://dash.bestdiplomats.org"+person.requested_by.user.user_image} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{person.requested_by.user.first_name+" "+person.requested_by.user.last_name}</div>
                            <div className="text-gray-500">{person.requested_by.user.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        {person.requested_by.nationality}
                        </span>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={"https://dash.bestdiplomats.org"+person.requested_for.user.user_image} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{person.requested_for.user.first_name+" "+person.requested_for.user.last_name}</div>
                            <div className="text-gray-500">{person.requested_for.user.email}</div>
                          </div>
                        </div>
                      </td>
                      
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        {person.requested_for.nationality}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button onClick={()=>{cancelApprovedRequest(person)}} className="text-blue-700 hover:text-indigo-900">
                          Cancel<span className="sr-only">, {person.name}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>) )}
      {/* Sent Request Tab */}
            {sentRequest && (noContentMassage ? ( <span className='flex justify-center'>No Roommate request found. Try sending one!</span> ):(<div className="mt-4 flex flex-col">
        <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className=" min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-scroll">
              <table className="min-w-full divide-y divide-gray-300 overflow-x-scroll">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nationality
                    </th>
                    
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {sentRequestsCurrentUser?.map((person) => (
                    <tr key={person.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={"https://dash.bestdiplomats.org"+person.requested_for.user.user_image} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{person.requested_for.user.first_name+" "+person.requested_for.user.last_name}</div>
                            <div className="text-gray-500">{person.requested_for.user.email}</div>
                          </div>
                        </div>
                      </td>
                     
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {person.requested_for.nationality
}
                        </span>
                      </td>
                     
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button onClick={()=>{
                            cancelSentRequest(person)
                        }} className="text-indigo-600 hover:text-indigo-900">
                          Cancel
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>))}
      {/* Recevied Request */}
            {receivedRequest && (noContentMassageRecived ? (<span className='flex justify-center'>No Roommate request found. Try sending one!</span> ):(<div className="mt-4 flex flex-col">
        <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className=" min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-scroll">
              <table className="min-w-full divide-y divide-gray-300 overflow-x-scroll">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nationality
                    </th>
                    
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {recevieRequestsCurrentUser?.map((person) => (
                    <tr key={person.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={"https://dash.bestdiplomats.org"+person.requested_by.user.user_image} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{person.requested_by.user.first_name+" "+ person.requested_by.user.last_name}</div>
                            <div className="text-gray-500">{person.requested_by.user.email}</div>
                          </div>
                        </div>
                      </td>
                      
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {person.requested_by.nationality}
                        </span>
                      </td>
                      
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button onClick={()=>{
                         acceptRecivedRequest(person);
                        }} className="text-indigo-600 hover:text-indigo-900">
                          Approve
                        </button>
                        <button onClick={()=>{
                         cancelRecivedRequest(person);
                        }} className="text-red-600 ml-2">
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>) )}
      {/* Add Request */}
            {addRequest && (<div className="mt-4 flex flex-col">
        <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className=" min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-scroll">
              <table className="min-w-full divide-y divide-gray-300 overflow-x-scroll">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nationality
                    </th>
                    
                    
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {eventRegData?.map((person) => (
                    <tr key={person.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={`https://dash.bestdiplomats.org${person.delegate_profile.user.user_image}`} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{person.delegate_profile.user.first_name + person.delegate_profile.user.last_name}</div>
                            <div className="text-gray-500">{person.delegate_profile.user.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        {person.representing_country}
                        </span>
                        </div>
                      </td>
                      
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button onClick={()=>{newRegistration(person)}} className="text-indigo-600 hover:text-indigo-900">
                         <span>Send Request</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)}
            
           
            </div>
             
              
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default RoomSharing