import React, { useEffect, useState } from "react";

import FontRoboto from "../../Roboto-Bold.ttf";
import Gold from '../../img/Gold Full Experience.png'
import Blue from '../../img/Blue Accommodation.png'
import Black from '../../img/Black Non Accommodation.png'
import QRCode from "qrcode";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink
} from "@react-pdf/renderer";

const SingleCard = () => {
    const [PDFFinalDoc, setPDFFinalDoc] = useState(null);
    const [qrUriImageFile, setqrUriImageFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState([]);
    const [eventResponse, setEventResponse] = useState();
    const [profileImage, setProfileImage] = useState();
    const [typeLink, setTypeLink] = useState();
    const [fullName, setFullname] = useState();
   
  const {eventId,delegateId}= useParams();
  
    Font.register({
      family:"RobotoBold",
      src: FontRoboto,
    });
    // Create styles
    const styles = StyleSheet.create({
      page: {backgroundColor: '#24579D'},
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
      },
      viewer: {
        width: 500,
        height: 770,
      },
      images: {
        height: 416,
        position: "absolute",
      },
      imagesProfile: {
        width: 154.1,
        top: 81.7,
        left: 61.7,
        position: "absolute",
        borderRadius: 100,
        overflow:'hidden'
      },
      title: {
        fontFamily:"RobotoBold",
        color: "#4591DC",
        top: 248,
        paddingHorizontal: 20,
        textAlign: "center",
      },
      designation: {
        color: "#000000",
        fontFamily:"RobotoBold",
        paddingHorizontal:10,
        fontSize: 14,
        marginTop: 4,
        textAlign:'center',
        top: 248,
      }, designation1: {
        color: "#000000",
        fontFamily:"RobotoBold",
        paddingHorizontal:10,
        fontSize: 14,
        marginTop: 4,
        textAlign:'center',
        top: 249,
      },
      eventname: {
        color: "#ffffff",
        textAlign: "center",
        position: "absolute",
        fontSize: 13,
        top: 395,
        right:0 ,
        left:0,
        margin:'auto',
      },
      qrimage: {
        width: 50,
        top: 246,
        position: "absolute",
        left: 112,
        top: 340,
        display:"none"
      },
      representingtwo: {
        color: "#000000",
        fontFamily:"RobotoBold",
        textAlign:'center',
        paddingHorizontal:10,
        marginTop:4,
        fontSize: 14,
        top: 250,
      } ,
       representingtwo1: {
        color: "#000000",
        fontFamily:"RobotoBold",
        textAlign:'center',
        paddingHorizontal:10,
        marginTop:4,
        fontSize: 14,
        top: 251,
      }
    });
    var JSToken;
    var accessToken;
    if (localStorage.user) {
      JSToken = JSON.parse(localStorage.user);
      accessToken = JSToken.access_token;
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  
    // var splitData = delegateid.split("-");
    // var DelgateID = splitData[0];
    // var EventID = splitData[1];
  
    // const nevigate = useNavigate();
    // const { user } = useSelector((state) => state.auth);
    // useEffect(() => {
    //   if (user === null) {
    //     nevigate("/logincomp");
    //   } else if (user === true) {
    //     nevigate("/IdCard/" + DelgateID + "-" + EventID);
    //   }
    // }, [user, nevigate]);
  
    useEffect(() => {
      const fetchUserData = async () => {
        setIsLoading(true);
        console.log(delegateId);
        try {
          await axios
            .get(
                `https://dash.bestdiplomats.org/api/resource/Delegate Invitations?fields=["*"]&filters=[["event","=","${eventId}"]]&limit_page_length=999`,
              config
            )
            .then((response) => {
            console.log(response.data.data);
                const filteredData = response.data?.data?.filter(item => item.invitation_email=== delegateId);
                    console.log(filteredData[0]?.name);
                    if(filteredData){
              setCurrentUser(filteredData);
              // console.log("Registeration>>>>", response.data.data);
              const toTitleCase = (fullName) => {
                return fullName
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0)?.toUpperCase() + word.slice(1))
                  .join(" ");
              };
              setFullname(
                toTitleCase(filteredData[0]?.delegate_name)
              );
              if (filteredData[0].payment_package=="Pay on Arrival" || filteredData[0].payment_package=="Accommodation" || filteredData[0].payment_package=="Accommodation (First Payment Pending)" || filteredData[0].payment_package=="Accommodation (Second Payment Pending)") {
                setTypeLink("Blue")
               
              }else if(filteredData[0].payment_package=="Non-Accommodation" || filteredData[0].payment_package=="Non-Accommodation (Second Payment Pending)" || filteredData[0].payment_package=="Non-Accommodation (First Payment Pending)"){
                setTypeLink("Black")
               
              }else if(filteredData[0].payment_package=="Full Experience" || filteredData[0].payment_package=="Full Experience (Second Payment Pending)"){
                setTypeLink("Gold")
              
              }else{
                setTypeLink("Blue")
              }
  
              generateQR(filteredData[0].name+"-d");
              const fetchEventData = async () => {
                try {
                  const result = await axios
                    .get(
                      "https://dash.bestdiplomats.org/api/resource/Events/" +
                      eventId
                    )
                    .then((response) => {
                      setEventResponse(response.data.data.title);
                      // console.log("Event Details>>>>",response.data.data);
                    });
                } catch (e) {
                  console.log(e);
                }
              };
              fetchEventData();
              setIsLoading(false);}
            });
        } catch (e) {
          console.log(e);
        }
      };
      fetchUserData();
    }, []);
  
    useEffect(() => {
      const getImage = async () => {
        try {
          const result = await axios
            .get(
              `https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url=/private/files/Logo-02.jpg`,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
                responseType: "blob",
                timeout: 30000,
              }
            )
            .then((response) => {
              var reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onloadend = function () {
                var base64data = reader.result;
                setProfileImage(base64data);
              };
            });
        } catch (e) {
          // console.log(">>>>>");
          console.log(e);
        }
      };
      getImage();
    }, [currentUser]);
    const generateQR = async (text) => {
      try {
        setqrUriImageFile(
          await QRCode.toDataURL(text, {
            color: {
              dark: "#4591DC", // Blue dots
              light: "#0000", // Transparent background
            },
          })
        );
      } catch (err) {
        console.error(err);
      }
    };
  
    return (
      <>
        {isLoading ? (
          <div
            style={{
              "background-color": "#4591DC",
              position: "absolute",
              left: "50%",
              top: "50%",
            }}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <svg
              fill="#87CEEB"
              viewBox="0 0 1792 1792"
              className="h-8 flex-no-shrink animate-spin"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
            </svg>
          </div>
        ) : (
          <>
          <PDFDownloadLink
          document={
            
            typeLink=="Black" ? <Document>
                <Page size={[277, 420]} style={styles.page}>
                  <Image
                    style={styles.images}
                    src={Black}
                  />
                
                  <Image style={styles.imagesProfile} src={profileImage} />
                  
                  <Text style={styles.title}>
                    {currentUser[0]?.delegate_name}
                  </Text>
                  <Text style={styles.designation}> {"Simulation Representation:____________"}</Text>
                  
                  
                  <Text style={styles.representingtwo}>
                    {"Diplomat of_______________"}
                  </Text>
                 
                  
                  <Text style={styles.eventname}>{"" + eventResponse}</Text>
                  <Image style={styles.qrimage} src={qrUriImageFile} />
                </Page>
              </Document> :
              typeLink=="Blue" ? <Document>
                <Page size={[277, 420]} style={styles.page}>
                  <Image
                    style={styles.images}
                    src={Blue}
                  />
                
                  <Image style={styles.imagesProfile} src={profileImage} />
                  
                  <Text style={styles.title}>
                    {currentUser[0]?.delegate_name}
                  </Text>
                  <Text style={styles.designation}> {"Simulation Representation:____________"}</Text>
                  
                  
                  <Text style={styles.representingtwo}>
                    {"Diplomat of_______________"}
                  </Text>
                  
                  <Text style={styles.eventname}>{"" + eventResponse} </Text>
                  <Image style={styles.qrimage} src={qrUriImageFile} />
                </Page>
              </Document> :
              typeLink=="Gold" ? <Document>
                <Page size={[277, 420]} style={styles.page}>
                  <Image
                    style={styles.images}
                    src={Gold}
                  />
                
                  <Image style={styles.imagesProfile} src={profileImage} />
                  
                  <Text style={styles.title}>
                    {currentUser[0]?.delegate_name}
                  </Text>
                  <Text style={styles.designation}> {"Simulation Representation:____________"}</Text>
                  
                  
                  <Text style={styles.representingtwo}>
                    {"Diplomat of_______________"}
                  </Text>
                  
                  <Text style={styles.eventname}>{"" + eventResponse}</Text>
                  <Image style={styles.qrimage} src={qrUriImageFile} />
                </Page>
              </Document>: null
              
          }
          fileName={`IDCard-${currentUser[0]?.delegate_name}.pdf`}
        >
          {" "}
          <span className="group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            {" "}
            Download PDF
          </span>
        </PDFDownloadLink>
            <PDFViewer style={styles.viewer}>
            {typeLink=="Black" && <Document>
                <Page size={[277, 420]} style={styles.page}>
                  <Image
                    style={styles.images}
                    src={Black}
                  />
                
                  <Image style={styles.imagesProfile} src={profileImage} />
                  
                  <Text style={styles.title}>
                    {currentUser[0]?.delegate_name }
                  </Text>
                  <Text style={styles.designation}> {"Simulation Representation:____________"}</Text>
                  
                  
                  <Text style={styles.representingtwo}>
                    {"Diplomat of_______________"}
                  </Text>
                  
                  <Text style={styles.eventname}>{"" + eventResponse}</Text>
                  <Image style={styles.qrimage} src={qrUriImageFile} />
                </Page>
              </Document>}
              {typeLink=="Blue" && <Document>
                <Page size={[277, 420]} style={styles.page}>
                  <Image
                    style={styles.images}
                    src={Blue}
                  />
                
                  <Image style={styles.imagesProfile} src={profileImage} />
                  
                  <Text style={styles.title}>
                    {currentUser[0]?.delegate_name }
                  </Text>
                  <Text style={styles.designation}> {"Simulation Representation:____________"}</Text>
                  
                  
                  <Text style={styles.representingtwo}>
                    {"Diplomat of_______________"}
                  </Text>
                  
                  <Text style={styles.eventname}>{"" + eventResponse}</Text>
                  <Image style={styles.qrimage} src={qrUriImageFile} />
                </Page>
              </Document>}
              {typeLink=="Gold" && <Document>
                <Page size={[277, 420]} style={styles.page}>
                  <Image
                    style={styles.images}
                    src={Gold}
                  />
                
                  <Image style={styles.imagesProfile} src={profileImage} />
                  
                  <Text style={styles.title}>
                    {currentUser[0]?.delegate_name}
                  </Text>
                  <Text style={styles.designation}> {"Simulation Representation:____________"}</Text>
                  
                  
                  <Text style={styles.representingtwo}>
                    {"Diplomat of_______________"}
                  </Text>
                  
                  <Text style={styles.eventname}>{"" + eventResponse}</Text>
                  <Image style={styles.qrimage} src={qrUriImageFile} />
                </Page>
              </Document>}
              
            </PDFViewer>
          </>
        )}
      </>
    );
  };
  

export default SingleCard