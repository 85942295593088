import React, { useEffect, useState } from "react";
import LogoFull from "../../img/LogoFull.png";
import { useFormik } from "formik";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Avatar from "react-avatar-edit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Tooltip from "../Small Components/tooltip";
import { useSelector } from "react-redux";

const Signup = (props) => {
  const [loading, setLoading] = useState(false);
  const [isCropingPicture, setIsCropingPicture] = useState(false);
  const { delegateEmail } = useParams();
  const [delegateData, setDelegateData] = useState(null);
  const [delegateExist, setDelegateExist] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [uploadPictureResponse, setUploadPictureResponse] = useState(null);
  const [uploadPassportResponse, setUploadPassportResponse] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [emergencyPhoneNo, setEmergencyPhoneNo] = useState(null);
  const [passportNumber, setPassportNumber] = useState(null);

  const handleOnChangePhone = value => {
    setPhoneNo(value);
  };
  const handleOnChangeEmergency = value => {
    setEmergencyPhoneNo(value);
  };

  // -------Banner State Handle

  const [bannerShow, setBannerShow] = useState(true);

  function handleBannerCross() {
    setBannerShow(false);
  }
  //--------End Banner States
  const nevigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (user === true) {
      nevigate("/");
    }
  }, [user, nevigate]);

  // Profile Picture Cropper
  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [profilePictureUploaded, setProfilePictureUploaded] = useState(false);
  const onClose = () => {
    setPreview(null);
  };
  const onCrop = (view) => {
    setPreview(view);
  };
  const onPopUpClose = (view) => {
    setIsCropingPicture(false);
  };

  function cropFirst() {
    setIsCropingPicture(true);
  }
  const urlToFile = async (url, filename, mimeType) => {
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  };

  var result;
  function getCroppedImage() {
    // console.log(">>>");
    var random = Math.random().toString();
    var file = urlToFile(
      preview,
      "profilePicture" + random + ".png",
      "image/png"
    );
    file.then((a) => {
      console.log(a);
      handlePictureUpload(a);
    });
    setIsCropingPicture(false);
  }
  function closePopup() {
    setPreview(null);
  }
  // Upload Picture File
  function handlePictureUpload(e) {
    let filePicture = e;
    const formData = new FormData();
    formData.append("file", filePicture);
    formData.append("doctype", "User");

    const uploadPictureApi = async () => {
      try {
        // setLoaderPassportFile(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData
            // config
          )
          .then((responsePicture) => {
            console.log(responsePicture.data);
            setUploadPictureResponse(responsePicture.data.message);
            setProfilePictureUploaded(true);
          });
      } catch (e) {
        console.log(e);
      }
    };
    uploadPictureApi();
  }
  // Upload Passport File
  function handlePassportUpload(e) {
    let filePicture = e.target.files[0];
    const formData = new FormData();
    formData.append("file", filePicture);
    formData.append("doctype", "User");

    const uploadPassportApi = async () => {
      try {
        setIsFileLoading(true)
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData
          )
          .then((responsePicture) => {
            setIsFileLoading(false)
            setUploadPassportResponse(responsePicture.data.message);
          });
      } catch (e) {
        console.log(e);
      }
    };
    uploadPassportApi();
  }

  const signUpDelegate = useFormik({
    initialValues: {
      email: delegateEmail,
      password: "",
      first_name: "",
      last_name: "",
    },
    onSubmit: (values) => {
      console.log(values.gender);
      setPassportNumber((values.passport_number).toUpperCase())
      const signUpDelegateProfile = async () => {
        try {
          setIsFileLoading(true)
          await axios
            .post(
              "https://dash.bestdiplomats.org/api/method/bestdiplomats.api.add_delegate_account_web?email=" +
                values.email +
                "&password=" +
                values.password +
                "&first_name=" +
                values.first_name +
                "&last_name=" +
                values.last_name +
                "&gender=" +
                values.gender +
                "&nationality=" +
                values.nationality +
                "&address=" +
                values.street +
                ", " +
                values.city +
                ", " +
                values.state +
                ", " +
                values.zipcode +
                ", " +
                values.country +
                "&mobile_number=" +
                phoneNo +
                "&emergency_contact_name=" +
                values.emergency_contact_name +
                "&emergency_contact_number=" +
                emergencyPhoneNo +
                "&dob=" +
                values.birthday +
                "&passport_number=" +
                passportNumber +
                "&passport_expiry=" +
                values.passport_expiry +
                "&user_image=" +
                uploadPictureResponse?.file_url +
                "&passport_scan_name=" +
                uploadPassportResponse?.name +
                "&tshirt_size=" +
                uploadPassportResponse?.tshirt_size
            )
            .then((response) => {
              window.location.assign(
                `https://dash.bestdiplomats.org/login?redirect-to=https%3A%2F%2Fdash.bestdiplomats.org%2Fapi%2Fmethod%2Ffrappe.integrations.oauth2.authorize%3Fclient_id%3D${window.$ClientID}%26state%3D444%26response_type%3Dcode%26scope%3Dopenid%2520all%26redirect_uri%3D${window.$FWDurl}%252Flogin#login`
              );
              setIsFileLoading(false);
            });
          //   setLoading(false);
        } catch (e) {
          console.error(e);
          if (e.response?.status == 409) {
            setDelegateExist(true);
            setTimeout(() => {
              window.location.assign(window.$FWDurl + "/logincomp");
            }, 3000);
          }
        }
      };

      signUpDelegateProfile();
    },
    enableReinitialize: true,
  });

  return (
    <>
      {/* Banner */}
      {bannerShow && (
        <div className="bg-orange-600">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className="flex rounded-lg bg-red-800 p-2">
                  <MegaphoneIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium text-white">
                  <span className="md:hidden">
                    You need to fill in this information in order to access the
                    ID card, invitation letter and hotel entry pass.
                  </span>

                  <span className="hidden md:inline">
                    You need to fill in this information in order to access the
                    ID card, Invitation Letter and Hotel Entry Pass.
                  </span>
                </p>
              </div>

              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  onClick={handleBannerCross}
                  className="-mr-1 flex rounded-md p-2 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Banner End */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={LogoFull}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Complete your Registration
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={signUpDelegate.handleSubmit}
          >
            <div className="-space-y-px rounded-md shadow-sm">
              {loading ? (
                <div
                  style={{ "background-color": "#4591DC" }}
                  className="flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <svg
                    fill="#87CEEB"
                    viewBox="0 0 1792 1792"
                    className="h-8 flex-no-shrink animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                  </svg>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className=" ">
                    <label htmlFor="email" className="font-light">
                      Email address
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.email}
                      disabled
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-200 px-3 mb-2 py-2 text-gray-500 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="font-light">
                      Set Password <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.password}
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="first_name"
                      className="font-light flex flex-row gap-2"
                    >
                      First Name (As per Passport){" "}
                      <span className="text-red-500">*</span>{" "}
                      <Tooltip data="This will be used on your Certificates and other official document and will not be modified." />
                    </label>
                    <span className="text-red-500 text-sm">
                      You'll have to put in a request to update your name
                      afterwards make sure you're writing correct name which
                      matches exactly as your passport.
                    </span>
                    <input
                      id="first_name"
                      name="first_name"
                      type="first_name"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.first_name}
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="last_name"
                      className="font-light flex flex-row gap-2"
                    >
                      Last Name (As per Passport){" "}
                      <span className="text-red-500">*</span>{" "}
                      <Tooltip data="This will be used on your Certificates and other official document and will not be modified." />
                    </label>
                    <span className="text-red-500 text-sm">
                      You'll have to put in a request to update your name
                      afterwards make sure you're writing correct name which
                      matches exactly as your passport.
                    </span>
                    <input
                      id="last_name"
                      name="last_name"
                      type="last_name"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.last_name}
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="street"
                      className="font-light flex flex-row gap-2"
                    >
                      Address <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      id="street"
                      name="street"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.street}
                      placeholder="Street"
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="city"
                      className="font-light flex flex-row gap-2 sr-only"
                    >
                      Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="city"
                      name="city"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.city}
                      placeholder="City"
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="state"
                      className="font-light flex flex-row gap-2 sr-only"
                    >
                      Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="state"
                      name="state"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.state}
                      placeholder="State / Province / Region"
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="zipcode"
                      className="font-light flex flex-row gap-2 sr-only"
                    >
                      Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="zipcode"
                      name="zipcode"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.zipcode}
                      placeholder="ZIP / Postal Code"
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="country"
                      className="font-light flex flex-row gap-2 sr-only"
                    >
                      Address <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="country"
                      name="country"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.country}
                      placeholder="Country"
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="Not Selected">Country</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">
                        British Indian Ocean Territory
                      </option>
                      <option value="Brunei Darussalam">
                        Brunei Darussalam
                      </option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, The Democratic Republic of The">
                        Congo, The Democratic Republic of The
                      </option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'ivoire">Cote D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">
                        Falkland Islands (Malvinas)
                      </option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">
                        French Southern Territories
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value="Holy See (Vatican City State)">
                        Holy See (Vatican City State)
                      </option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">
                        Iran, Islamic Republic of
                      </option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">
                        Korea, Democratic People's Republic of
                      </option>
                      <option value="Korea, Republic of">
                        Korea, Republic of
                      </option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">
                        Lao People's Democratic Republic
                      </option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">
                        Libyan Arab Jamahiriya
                      </option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, The Former Yugoslav Republic of">
                        Macedonia, The Former Yugoslav Republic of
                      </option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">
                        Micronesia, Federated States of
                      </option>
                      <option value="Moldova, Republic of">
                        Moldova, Republic of
                      </option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">
                        Netherlands Antilles
                      </option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">
                        Palestinian Territory, Occupied
                      </option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">
                        Russian Federation
                      </option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">
                        Saint Kitts and Nevis
                      </option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">
                        Saint Pierre and Miquelon
                      </option>
                      <option value="Saint Vincent and The Grenadines">
                        Saint Vincent and The Grenadines
                      </option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">
                        Sao Tome and Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Somaliland">Somaliland</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">
                        South Georgia and The South Sandwich Islands
                      </option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">
                        Svalbard and Jan Mayen
                      </option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">
                        Syrian Arab Republic
                      </option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">
                        Tanzania, United Republic of
                      </option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">
                        Trinidad and Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">
                        Turks and Caicos Islands
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">
                        United States Minor Outlying Islands
                      </option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">
                        Virgin Islands, British
                      </option>
                      <option value="Virgin Islands, U.S.">
                        Virgin Islands, U.S.
                      </option>
                      <option value="Wallis and Futuna">
                        Wallis and Futuna
                      </option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="contact_number" className="font-light">
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      inputProps={{
                        name: "contact_number",
                        required: true,
                      }}
                      country={"us"}
                      onChange={handleOnChangePhone}
                      value={phoneNo}
                      // inputClass="relative w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="user_image"
                      className="font-light flex flex-row gap-2"
                    >
                      Picture (White Background; 2 by 2 inch){" "}
                      <span className="text-red-500">*</span>
                      <Tooltip data="The background has to be white, without any patterns The picture should be colored, not black and white You must be in the center Your head, hair, ears, and shoulders have to be in the picture Your face should take up 70% – 80% of the picture The picture should be of good quality: there should be no shadows, over- or under-exposure, or glare The picture must show your real skin tone." />
                    </label>
                    <div className="flex flex-row gap-4 items-center">
                      <button
                        onClick={cropFirst}
                        style={{ "background-color": "#4591DC" }}
                        className="mt-4 text-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Upload Profile Picture
                      </button>
                      {profilePictureUploaded ? (
                        <span className="text-green-700 ">
                          Picture Uploaded
                        </span>
                      ) : null}
                    </div>
                    <input
                      id="user_image"
                      name="user_image"
                      type="file"
                      value={signUpDelegate.values.user_image}
                      className="sr-only"
                    />
                  </div>
                  <div>
                    <label htmlFor="gender" className="font-light">
                      Gender <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.gender}
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                            <label
                              htmlFor="tshirt_size"
                              className="font-light"
                            >
                              T-Shirt Size
                            </label>
                            <select
                              type="text"
                              name="tshirt_size"
                              id="tshirt_size"
                              required
                              onChange={signUpDelegate.handleChange}
                              value={
                                signUpDelegate.values.tshirt_size
                              }
                              className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                              <option value="Not Selected">Not Selected</option>
                              <option value="Small (S)">Small (S)</option>
                              <option value="Medium (M)">Medium (M)</option>
                              <option value="Large (L)">Large (L)</option>
                              <option value="Extra Large (XL)">
                                Extra Large (XL)
                              </option>
                              <option value="Double XL (XXL)">
                              Double XL (XXL)
                              </option>
                              <option value="Triple XL (XXXL)">
                              Triple XL (XXXL)
                              </option>
                            </select>
                          </div>
                  <div>
                    <label htmlFor="nationality" className="font-light">
                      Nationality <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="nationality"
                      name="nationality"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.nationality}
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="Not selected">Not selected</option>
                      <option value="Afghanistan">Afghanistan</option>
                              <option value="Åland Islands">
                                Åland Islands
                              </option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antarctica">Antarctica</option>
                              <option value="Antigua and Barbuda">
                                Antigua and Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">
                                Bosnia and Herzegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island">
                                Bouvet Island
                              </option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Territory">
                                British Indian Ocean Territory
                              </option>
                              <option value="Brunei Darussalam">
                                Brunei Darussalam
                              </option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos (Keeling) Islands">
                                Cocos (Keeling) Islands
                              </option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Congo, The Democratic Republic of The">
                                Congo, The Democratic Republic of The
                              </option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote D'ivoire">
                                Cote D'ivoire
                              </option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands (Malvinas)">
                                Falkland Islands (Malvinas)
                              </option>
                              <option value="Faroe Islands">
                                Faroe Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern Territories">
                                French Southern Territories
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guernsey">Guernsey</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-bissau">
                                Guinea-bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard Island and Mcdonald Islands">
                                Heard Island and Mcdonald Islands
                              </option>
                              <option value="Holy See (Vatican City State)">
                                Holy See (Vatican City State)
                              </option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran, Islamic Republic of">
                                Iran, Islamic Republic of
                              </option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea, Democratic People's Republic of">
                                Korea, Democratic People's Republic of
                              </option>
                              <option value="Korea, Republic of">
                                Korea, Republic of
                              </option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao People's Democratic Republic">
                                Lao People's Democratic Republic
                              </option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">
                                Libyan Arab Jamahiriya
                              </option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macao">Macao</option>
                              <option value="Macedonia, The Former Yugoslav Republic of">
                                Macedonia, The Former Yugoslav Republic of
                              </option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">
                                Micronesia, Federated States of
                              </option>
                              <option value="Moldova, Republic of">
                                Moldova, Republic of
                              </option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">
                                Netherlands Antilles
                              </option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="Northern Mariana Islands">
                                Northern Mariana Islands
                              </option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestinian Territory, Occupied">
                                Palestinian Territory, Occupied
                              </option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn">Pitcairn</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russian Federation">
                                Russian Federation
                              </option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Helena">Saint Helena</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Pierre and Miquelon">
                                Saint Pierre and Miquelon
                              </option>
                              <option value="Saint Vincent and The Grenadines">
                                Saint Vincent and The Grenadines
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia and The South Sandwich Islands">
                                South Georgia and The South Sandwich Islands
                              </option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard and Jan Mayen">
                                Svalbard and Jan Mayen
                              </option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">
                                Syrian Arab Republic
                              </option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania, United Republic of">
                                Tanzania, United Republic of
                              </option>
                              <option value="Thailand">Thailand</option>
                              <option value="Timor-leste">Timor-leste</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks and Caicos Islands">
                                Turks and Caicos Islands
                              </option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="United States Minor Outlying Islands">
                                United States Minor Outlying Islands
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Viet Nam">Viet Nam</option>
                              <option value="Virgin Islands, British">
                                Virgin Islands, British
                              </option>
                              <option value="Virgin Islands, U.S.">
                                Virgin Islands, U.S.
                              </option>
                              <option value="Wallis and Futuna">
                                Wallis and Futuna
                              </option>
                              <option value="Western Sahara">
                                Western Sahara
                              </option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="birthday" className="font-light">
                      Date of birth <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="birthday"
                      name="birthday"
                      type="date"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.passport_expiry}
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="emergency_contact_name"
                      className="font-light"
                    >
                      Emergency Contact Name{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="emergency_contact_name"
                      name="emergency_contact_name"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.emergency_contact_name}
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="emergency_contact_number"
                      className="font-light"
                    >
                      Emergency Contact Number{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      inputProps={{
                        name: "emergency_contact_number",
                        required: true,
                      }}
                      country={"us"}
                      onChange={handleOnChangeEmergency}
                      value={emergencyPhoneNo}
                      // inputClass="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  

                  <div>
                    <label htmlFor="passport_number" className="font-light">
                      Passport Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="passport_number"
                      name="passport_number"
                      type="text"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.passport_number}
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="passport_expiry" className="font-light">
                      Passport Expiry Date{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="passport_expiry"
                      name="passport_expiry"
                      type="date"
                      onChange={signUpDelegate.handleChange}
                      value={signUpDelegate.values.passport_expiry}
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="passport_upload"
                      className="font-light flex flex-row gap-2"
                    >
                      Scanned Passport (Front Page){" "}
                      <span className="text-red-500">*</span>
                    </label>
                    {isFileLoading ? (<svg
                      fill="#87CEEB"
                      viewBox="0 0 1792 1792"
                      className="h-8 flex-no-shrink animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                    </svg>):(<input
                      id="passport_upload"
                      name="passport_upload"
                      type="file"
                      onChange={(e) => {
                        handlePassportUpload(e);
                      }}
                      required
                      className="relative block w-full appearance-none rounded mt-1 border border-gray-300 px-3 mb-2 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />)}
                    
                  </div>
                </div>
              )}
            </div>

            <div>
            {isFileLoading ? (<><span style={{ "background-color": "#4591DC" }}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <svg
                      fill="#87CEEB"
                      viewBox="0 0 1792 1792"
                      className="h-8 flex-no-shrink animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                    </svg>
                </span>
                    </>):(<button
                type="submit"
                style={{ "background-color": "#4591DC" }}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Sign up
              </button>)}
              
            </div>
          </form>
          {delegateExist ? (
            <p className="font-medium text-red-400 text-center">
              This Profile already exist
            </p>
          ) : null}
        </div>
      </div>
      {/* Cropper Popup */}
      {isCropingPicture ? (
        <div className="flex flex-col justify-center mt-0 bg-gray-100 bg-opacity-75 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full">
          <div className="flex justify-center">
            <Avatar
              width={400}
              height={300}
              onCrop={onCrop}
              exportSize={500}
              onClose={onClose}
              src={src}
            ></Avatar>
          </div>
          <div className="mt-12 flex justify-center ">
            {preview && (
              <img
                className="border-4 border-blue-400 rounded"
                src={preview}
                alt="Cropped Image"
              />
            )}
            {/* {console.log(preview)} */}
          </div>
          <div className="mt-12 flex flex-row justify-center ">
            <button
              onClick={getCroppedImage}
              style={{ "background-color": "#4591DC" }}
              className="rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
            <button
              onClick={onPopUpClose}
              className="rounded-md border  border-transparent ml-4 bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Close
            </button>
          </div>
        </div>
      ) : null}

      {/* <CropperComp /> */}
    </>
  );
};

export default Signup;
