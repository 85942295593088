import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
 apiKey: "AIzaSyAo3Td0so07WipdCTI_0MuAzCHXUAlXM_Q",
  authDomain: "bestdipapp.firebaseapp.com",
  projectId: "bestdipapp",
  storageBucket: "bestdipapp.appspot.com",
  messagingSenderId: "319491109606",
  appId: "1:319491109606:web:7f647accb471363d688127",
  measurementId: "G-SH3KYQYT7W"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const analytics = getAnalytics(app);

// apiKey: "AIzaSyAo3Td0so07WipdCTI_0MuAzCHXUAlXM_Q",
//   authDomain: "bestdipapp.firebaseapp.com",
//   projectId: "bestdipapp",
//   storageBucket: "bestdipapp.appspot.com",
//   messagingSenderId: "319491109606",
//   appId: "1:319491109606:web:7f647accb471363d688127",
//   measurementId: "G-SH3KYQYT7W"