import axios from "axios";

//Logout

var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
const logoutFromFrappe = async () => {
  try {
    //   setLoading(true);
    await axios
      .get(
        "https://dash.bestdiplomats.org/api/method/bestdiplomats.api.clear_all_sessions_of_user?user="+userEmail, config
      )
      .then((response) => {
        window.location.reload();
      });
    //   setLoading(false);
  } catch (e) {
      console.error(e)
     }
  };


const logout = () => {
  logoutFromFrappe();
  localStorage.removeItem("user");
  localStorage.removeItem("date");
  localStorage.removeItem("user_name");
  localStorage.removeItem("picture");
  localStorage.removeItem("user_email");
  localStorage.removeItem("first_reload");
  localStorage.removeItem("role");
  localStorage.removeItem("inviteurl");
  localStorage.removeItem("redirect");
  localStorage.removeItem("activeNavigationIndex")
  localStorage.removeItem("activeNavigationmainIndex")


};

//Login

const login = async (userData) => {
  if (userData) {
    localStorage.setItem("user", userData);
  }
  return userData;
};
//Check Logged In








const authService = {
  logout,
  login,
 
};




export default authService;
