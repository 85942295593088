import React, { useTransition } from "react";
import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Chat from "../Chat";

var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

const OneToOneChat = ({ eventID }) => {
  // const { eventID } = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPending, startTransition] = useTransition();

  const [allRoomSharing, setAllRoomSharing] = useState(null);

  console.log(">>>>>>>>>>", filteredData);

  useEffect(() => {
    const fetchEventRegisteration = async () => {
      try {
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/internal-registrations?filters=[["event","=","${eventID}"]]`,
            config
          )
          .then((response) => {
            setAllRoomSharing(response.data.data);
            // setFilteredData(response.data.data);
            console.log(">>>><<<<<<<<>>>", response.data.data);
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventRegisteration();
  }, []);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    startTransition(() => {
      setSearchTerm(searchTerm);
    });
    setSearchTerm(searchTerm);

    // Filter data based on search term
    const filteredData = allRoomSharing.filter((item) =>
      item?.delegate_profile?.user?.full_name.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filteredData);
  };
   const PassIdCreateChat=(id)=>{
  
   return <Chat chatid={id}/>
  }
  
  return (
    <div className="border-r border-gray-100 lg:col-span-1">
     <div className=" border  rounded-b " >
      <span className="block px-3 pt-2  font-bold text-gray-600" >New Chat</span>
      <div className="mx-3 my-3">
        <div className="relative text-gray-600">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              className="w-6 h-6 text-gray-300"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </span>
          <input
            type="search"
            value={searchTerm}
            onChange={handleSearch}
            className=" border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full    py-2 pl-10  rounded outline-none"
            placeholder="Search "
          />
        </div>
      </div>

      <ul className="overflow-auto h-24">
        {filteredData?.map((item) => (
          <li className="">
            <a className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
              <img
                className="object-cover w-10 h-10 rounded-full"
                src={
                  "https://dash.bestdiplomats.org" +
                  item?.delegate_profile?.user?.user_image
                }
                alt="username"
              />
              <div className="w-full pb-2" onClick={()=>PassIdCreateChat(item?.name)}>
                <div className="flex justify-between ">
                  <span className="block  ml-2 font-semibold text-gray-600">
                    {item?.delegate_profile?.user?.full_name}
                  </span>
                  <span className="block ml-2 text-sm text-gray-600"></span>
                </div>
                <span className="block ml-2 text-sm text-gray-600">bye</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
      </div> 
      <div className="divider pb-0"> <span className="block  font-bold text-gray-600" >Old Conservation </span></div> 
     
      <ul className="overflow-auto   h-[24rem]">
        {filteredData?.map((item) => (
          <li className="">
            <a className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
              <img
                className="object-cover w-10 h-10 rounded-full"
                src={
                  "https://dash.bestdiplomats.org" +
                  item?.delegate_profile?.user?.user_image
                }
                alt="username"
              />
              <div className="w-full pb-2" onClick={()=>PassIdCreateChat(item?.name)}>
                <div className="flex justify-between ">
                  <span className="block  ml-2 font-semibold text-gray-600">
                    {item?.delegate_profile?.user?.full_name}
                  </span>
                  <span className="block ml-2 text-sm text-gray-600"></span>
                </div>
                <span className="block ml-2 text-sm text-gray-600">bye</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OneToOneChat;
