import React from 'react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import LogoFull from "../../img/LogoFull.png";
import { Link } from "react-router-dom";

const LoginButton = () => {
  console.log(" window.$FWDurl loginbutton", window.$FWDurl);
  return (
    <>
      
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-28">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={LogoFull}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            
            </div>

            <div className='flex flex-col justify-center text-center'>
              <a
                type="submit"
                style={{"background-color": "#4591DC"}}
                href={'https://dash.bestdiplomats.org/api/method/frappe.integrations.oauth2.authorize?client_id='+window.$ClientID+'&state=444&response_type=code&scope=openid%20all&redirect_uri='+ window.$FWDurl +'/login'}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-white " aria-hidden="true" />
                </span>
                Login with your account
              </a>
              {/* <h1 className='text-gray-400 mt-4 mb-4'>OR</h1>
              <Link to="/invitelogin">
              <button
                
                style={{"background-color": "#4591DC"}}
                
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Signup
              </button>
              </Link> */}
            </div>
        
        </div>
      </div>
    </>
  )
}

export default LoginButton