import { WifiIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { Link } from "react-router-dom";

const Success = () => {
  
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Hey!</span>
          <span style={{"color": "#4591DC"}} className="block text-indigo-600">You've successfully signed up.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
          <Link to="/logincomp">
            <a
              style={{"background-color": "#4591DC"}}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
            >
              Login
            </a>
            </Link>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
          <Link to="/">
            <a
              href="#"
              style={{"color": "#4591DC"}}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50"
            >
              Home
            </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success