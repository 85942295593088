import { Fragment, useState, useCallback, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Editor } from "@tinymce/tinymce-react";
import { Toaster, toast } from "sonner";
import { Link, useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import axios from "axios";
import PrivateImage from "../../PrivateImage/PrivateImage";
import { useFormik } from "formik";
import moment from "moment";





var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

const DiplomaticAllTask = (props) => {
  const [updateEditorValue, setUpdateEditorValue] = useState("");
  const [allTask, setAllTask] = useState([]);
  const [TaskDetail, setTaskDetail] = useState(null);
  const [openAnnouncementsDetails, setOPenAnnouncementsDetails] =
    useState(false);
  const [announcementsDetails, setAnnouncementsDetails] = useState({});
  const [createAnnouncementSuccess, setCreateAnnouncementSuccess] =
    useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [totalAnnouncements, setTotalAnnouncements] = useState(false);
  const [isNotFound, setIsNotFound] = useState(0);
  const navigation=useNavigate()
  const [uploadFileResponse, setUploadFileResponse] = useState(null);
  const { eventID } = useParams();
  const [apiFilters, setApiFilters] = useState(`["diplomatic_expert_tasks","=","${eventID}"]`);
  const goBack = () => {
    window.history.back();
  };
 
 
  useEffect(() => {
    const fetchAllTask = async () => {
      try {
        const result = await axios
          .get(
            `${window.$domain}/api/resource/Diplomatic Expert Tasks/${eventID}`,
            config
          )
          .then((response) => {
            setTaskDetail(response.data.data);
            console.log("><>>>>>>>>>>>>>>", response.data);

            setLoadingSkeleton(false);
            setIsNotFound(false);
            if (response.data.data == 0) {
              setIsNotFound(true);
              setLoadingSkeleton(true);
              toast.error("No Data Found");
            }
          });
      } catch (e) {
        console.log(e);

        if (e.code == "ERR_NETWORK" || e.response.status == 404) {
          setIsNotFound(true);

          toast.error("No Data Found");
          setLoadingSkeleton(true);
        }
      }
    };
    fetchAllTask();
  }, []);

  useEffect(() => {
    const fetchAllTask = async () => {
      try {
        const result = await axios
          .get(
            `${window.$domain}/api/method/get-diplomatic-submissions?filters=[${apiFilters}]`,
            config
          )
          .then((response) => {
            setAllTask(response.data.data);
            setTotalAnnouncements(response.data.data.length);
            setLoadingSkeleton(false);
            setIsNotFound(false);
            if (response.data.data == 0) {
              setIsNotFound(true);
              setLoadingSkeleton(true);
              toast.error("No Data Found");
            }
          });
      } catch (e) {
        console.log(e);

        if (e.code == "ERR_NETWORK" || e.response.status == 404) {
          setIsNotFound(true);

          toast.error("No Data Found");
          setLoadingSkeleton(true);
        }
      }
    };
    fetchAllTask();
  }, [apiFilters]);


console.log("apiFilters",apiFilters);
  const applyFiltersTicket = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      console.log("status", values);
      setApiFilters(`["diplomatic_expert_tasks","=","${eventID}"]`);
      //  Payment Package
      if (values.submission_status) {
        if (values.submission_status != "None") {
      
          setApiFilters((prevState) => [
            prevState + `,["submission_status","=","${values?.submission_status}"]`,
          ]);
        } else {
          setApiFilters(`["diplomatic_expert_tasks","=","${eventID}"]`);
        }
      }
    },
    enableReinitialize: true,
  });
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  if (!window.location.pathname.includes("/dpl-all-submission")) {
   localStorage.removeItem("Single user submission")
    localStorage.removeItem('Single_user_submission');
  }
  const handelPassData=(data)=>{
    props.sendDataToParent(data);
   
    localStorage.setItem("Single_user_submission", JSON.stringify(data))
     navigation(`dpl-all-submission/${data?.name}`)
  }

  return (
    <>
      
      

      <div className="pt-3">
        <Toaster position="bottom-left" richColors />
        <div className="flex  divide-y flex-col justify-end">
         
          <form onSubmit={applyFiltersTicket.handleSubmit} className="flex pt-3 justify-between items-center">
          <div className=" sm:ml-6  flex justify-start items-center   sm:flex-none">

<span onClick={() => goBack()} className="hover:cursor-pointer flex justify-start items-center">
  <ArrowLeftIcon className="h-5 w-5 mr-1 flex justify-center items-center  text-gray-500 cursor-pointer" /> Back</span>


</div>
       <div className=" flex justify-end gap-3 pb-1 items-center  sm:mt-0 sm:ml-5 sm:flex-none">
       <label htmlFor="submission_status" className="sr-only"></label>
            <select
              id="submission_status"
              name="submission_status"
              className=" block w-60  rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              onChange={applyFiltersTicket.handleChange}
              value={applyFiltersTicket.values.submission_status}
            >
              <option value="None">All</option>
              <option value="Submitted">Submitted</option>
              <option value="Approved">Approved</option>
              <option value="In Review">In Review</option>
              <option value="Closed">Closed</option>
              <option value="Rejected">Rejected</option>
            </select>
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Apply Filter
            </button>
          </div>
        </form>
        </div>
        <div className="mx-4 ">

          <div className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">

            <div key={TaskDetail?.name} className="col-span-6 flex rounded-md shadow-sm">

              <div className="flex flex-1 px-4 py-4 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
                <div className="flex-1 truncate  text-sm">
                  <h2 className=" font-semibold  text-gray-800 hover:text-gray-600">
                  <span className="font-semibold  text-gray-900 hover:text-gray-600">Title: </span>  {TaskDetail?.title}
                  </h2>
                  <p className="text-gray-500  whitespace-nowrap" dangerouslySetInnerHTML={{
                    __html: TaskDetail?.description,
                  }}></p>
                </div>
                           
                <div> Due Date:<span className="inline-flex rounded-full ml-1 bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800" >  { moment(TaskDetail?.due_date).format('LL')}</span></div>
              </div>
            </div>

          </div>
        </div>
        <div className="mt-4 flow-root">
          <h1 className="text-sm px-3 py-2 font-semibold text-gray-900">Submission</h1>
          <div className="-mx-4 -my-2 max-h-screen overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {isNotFound ? (
                <div className="h-screen flex justify-center flex-col items-center ">
                  <svg
                    fill="none"
                    className="w-40 h-40 text-gray-400"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                    />
                  </svg>{" "}
                  <span className=" text-lg text-gray-400"> No Data Found</span>
                </div>
              ) : (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Submitted

                        </th>
                       

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    {loadingSkeleton ? (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900 ">
                            <div className="flex items-center">
                              <div className="h-12 w-12 flex-shrink-0 flex items-center">
                                <Skeleton
                                  borderRadius={40}
                                  width={50}
                                  height={50}
                                />
                              </div>
                              <div className="ml-2 flex items-center">
                                <div className="font-medium text-gray-900">
                                  <Skeleton width={90} height={20} />
                                </div>
                                {/* <div className="mt-1 text-gray-500">
                                {person.email}
                              </div> */}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton width={90} height={30} />
                          </td>
                          
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {" "}
                            <Skeleton
                              borderRadius={20}
                              width={60}
                              height={30}
                            />
                          </td>

                          <td className="text-indigo-600 hover:text-indigo-900">
                            <Skeleton width={60} height={20} />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {allTask?.map((person) => (
                          <tr key={person.name}>
                            <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900 ">
                              <div className="flex items-center">
                                <div
                                  className="h-12 cursor-pointer w-12 flex-shrink-0 flex "
                                  onClick={()=>handelPassData(person)}
                                >
                                  <PrivateImage
                                    imageUrl={person.submitted_by.user_image}
                                    className={"h-12 w-12 rounded-full"}
                                  />
                                </div>
                                <div className="ml-2  ">
                                  <div className="font-medium cursor-pointer text-gray-900" onClick={()=>handelPassData(person)}>
                                    {person.submitted_by.first_name + " " + person.submitted_by.last_name}
                                  </div>
                                  <div className="mt-1 text-gray-500">
                                    {person.submitted_by.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.submission_status == "Rejected" ? (
                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                              {person.submission_status}
                            </span>
                          ) : person.submission_status == "Closed" ? (
                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                              {person.submission_status}
                            </span>
                          ) :person.submission_status == "In Review" ? (
                            <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-700">
                              {person.submission_status}
                            </span>
                          ) :(
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {person.submission_status}
                            </span>
                          )}
                              
                            </td>
                            <td className=" truncate  px-3 py-4 text-sm text-gray-500">
                              <span className={`inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800`}>
                              {person?.submitted_at==null||person?.submitted_at==undefined?"":moment(person?.submitted_at).format('LLL')}
                              </span>
                            </td>

                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <div className="flex gap-2">

                                <p
                               onClick={()=>handelPassData(person)}

                                >
                                  <ArrowRightIcon className="h-6 w-6 flex justify-center items-center text-gray-500 cursor-pointer" />
                                </p>
                              </div>
                            </td>
                          </tr>))}

                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default DiplomaticAllTask