import React, { useEffect, useRef, useState } from "react";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik, useFormikContext } from "formik";
import axios from "axios";
import Avatar from "react-avatar-edit";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";


const Profile = () => {
  const nevigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [covidDocument, setCovidDocument] = useState(null);
  const [visaDocument, setVisaDocument] = useState(null);
  const [passportDocument, setPassportDocument] = useState(null);
  const [ProfilePictureAvatar, setProfilePictureAvatar] = useState(null);
  const [ProfilePictureAvatarCheck, setProfilePictureAvatarCheck] =
    useState(null);
  const [ProfilePicture, setProfilePicture] = useState(null);
  const [FlightDocument, setFlightDocument] = useState(null);
  const [loaderCovidFile, setLoaderCovidFile] = useState(null);
  const [loaderVisaFile, setLoaderVisaFile] = useState(null);
  const [loaderPassportFile, setLoaderPassportFile] = useState(null);
  const [loaderFlightFile, setLoaderFlightFile] = useState(null);
  const [loaderProfilePictureFile, setLoaderProfilePicture] = useState(null);
  const [uploadCovidResponse, setUploadCovidResponse] = useState(null);
  const [uploadVisaResponse, setUploadVisaResponse] = useState(null);
  const [uploadPassportResponse, setUploadPassportResponse] = useState(null);
  const [uploadFlightResponse, setUploadFlightResponse] = useState(null);
  const [uploadProfilePictureResponse, setUploadProfilePictureResponse] =
    useState(null);
  const [initialCovidValue, setInitialCovidValue] = useState(null);
  const [initialVisaValue, setInitialVisaValue] = useState(null);
  const [initialPassportValue, setInitialPassportValue] = useState(null);
  const [initialFlightValue, setInitialFlightValue] = useState(null);
  const [arrival_flight_date, setarrival_flight_date] = useState(null);
  const [arrival_flight_timeOnly, setarrival_flight_timeOnly] = useState(null);
  const [arrival_flight_check, setarrival_flight_check] = useState(null);
  const [departure_flight_date, setdeparture_flight_date] = useState(null);
  const [limitedRequestDone, setLimitedRequestDone] = useState(false);
  const [departure_flight_timeOnly, setdeparture_flight_timeOnly] = useState(null);
  const [legalFileResponse, setLegalFileResponse] = useState(null);
  const [initialProfilePictureValue, setInitialProfilePictureValue] =
    useState(null);
    const [openDraw, setOpenDraw] = useState(false);
    const [nameChangeLoader, setNameChangeLoader] = useState(false);
  const [isSaved, setIsSaved] = useState(null);
  const [RequestMade, setRequestMade] = useState(false);
  const [isCropingPicture, setIsCropingPicture] = useState(false);
  const formRef = useRef(null);

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  useEffect(() => {
    if (user === null) {
      nevigate("/");
    } else if (user === true) {
      nevigate("/profile");
    }
  }, [user, nevigate]);

  // Covid Document Upload

  function uploadCovidDocument(e) {
    setCovidDocument(e.target.files[0].name);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("doctype", "Delegate Profile");
    formData.append("docname", currentUser?.data?.name);
    setInitialCovidValue(null);
    const uploadCovidApi = async () => {
      try {
        setLoaderCovidFile(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then((responseCovid) => {
            setUploadCovidResponse(responseCovid.data);
            var updateJSONCovid= {
              participantProfile: {
                id: currentUser.data.name,
                updateData: {
                  covid_vaccination_certificate: responseCovid?.data.message.file_url,
                  },
              },
              relatedUser: {
                id: currentUser.data.user.name,
                updateData: {
                  
                },
              },
            };
            
      
            const updateUserDataCovid = async () => {
              try {
                setLoading(true);
                await axios
                  .post(
                    "https://dash.bestdiplomats.org/api/method/update-delegate",
                    updateJSONCovid,
                    config
                  )
                  .then((response) => {
                    setCurrentUser(response.data);
                    setIsSaved(true);
                    setTimeout(() => {
                      setIsSaved(false);
                    }, 2000);
                  });
                setLoading(false);
                
              } catch (e) {
                console.log(e);
              }
            };
      
            updateUserDataCovid();
          });
        setLoaderCovidFile(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadCovidApi();
  }

  // Visa Document Upload

  function uploadVisaDocument(e) {
    setVisaDocument(e.target.files[0].name);
    const fileVisa = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileVisa);
    formData.append("doctype", "Delegate Profile");
    formData.append("docname", currentUser?.data?.name);
    setInitialVisaValue(null);
    const uploadVisaApi = async () => {
      try {
        setLoaderVisaFile(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then((responseVisa) => {
            setUploadVisaResponse(responseVisa.data);
            var updateJSONVisa= {
              participantProfile: {
                id: currentUser.data.name,
                updateData: {
                  visa_scan: responseVisa?.data.message.file_url,
                  },
              },
              relatedUser: {
                id: currentUser.data.user.name,
                updateData: {
                  
                },
              },
            };
            
      
            const updateUserDataVisa = async () => {
              try {
                setLoading(true);
                await axios
                  .post(
                    "https://dash.bestdiplomats.org/api/method/update-delegate",
                    updateJSONVisa,
                    config
                  )
                  .then((response) => {
                    setCurrentUser(response.data);
                    setIsSaved(true);
                    setTimeout(() => {
                      setIsSaved(false);
                    }, 2000);
                  });
                setLoading(false);
                
              } catch (e) {
                console.log(e);
              }
            };
      
            updateUserDataVisa();
          });
        setLoaderVisaFile(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadVisaApi();
  }

  // Passport Document Upload
  function uploadPassportDocument(e) {
    setPassportDocument(e.target.files[0].name);
    const filePassport = e.target.files[0];
    const formData = new FormData();
    formData.append("file", filePassport);
    formData.append("doctype", "Delegate Profile");
    formData.append("docname", currentUser?.data?.name);
    setInitialPassportValue(null);
    const uploadPassportApi = async () => {
      try {
        setLoaderPassportFile(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then((responseProfile) => {
            
            setUploadPassportResponse(responseProfile.data);
            var updateJSONPassport = {
              participantProfile: {
                id: currentUser.data.name,
                updateData: {
                  passport_upload: responseProfile?.data.message.file_url,
                  },
              },
              relatedUser: {
                id: currentUser.data.user.name,
                updateData: {
                  
                },
              },
            };
            
      
            const updateUserDataPassport = async () => {
              try {
                setLoading(true);
                await axios
                  .post(
                    "https://dash.bestdiplomats.org/api/method/update-delegate",
                    updateJSONPassport,
                    config
                  )
                  .then((response) => {
                    // console.log(response.data);
                    setCurrentUser(response.data);
                    setIsSaved(true);
                    setTimeout(() => {
                      setIsSaved(false);
                    }, 2000);
                  });
                setLoading(false);
                
              } catch (e) {
                console.log(e);
              }
            };
      
            updateUserDataPassport();
          });
        setLoaderPassportFile(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadPassportApi();
  }
   // Upload Passport File
   function handleLegalFileUpload(e) {
    let filePicture = e.target.files[0];
    const formData = new FormData();
    formData.append("file", filePicture);
    formData.append("doctype", "Delegate Name Revision Request");

    const uploadLegalFileApi = async () => {
      try {
        setNameChangeLoader(true)
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData
          )
          .then((responsePicture) => {
            
            setLegalFileResponse(responsePicture.data.message);
            
          });
      } catch (e) {
        console.log(e);
      }
      setNameChangeLoader(false)
    };
    uploadLegalFileApi();
  }

  // Flight Document Upload
  function uploadFlightDocument(e) {
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("doctype", "Delegate Profile");
    formData.append("docname", currentUser?.data?.name);
    setInitialFlightValue(null);
    const uploadFlightApi = async () => {
      try {
        setLoaderFlightFile(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then((responseFlight) => {
            setUploadFlightResponse(responseFlight.data);
            var updateJSONFlight= {
              participantProfile: {
                id: currentUser.data.name,
                updateData: {
                  flight_ticket_scan: responseFlight?.data.message.file_url,
                  },
              },
              relatedUser: {
                id: currentUser.data.user.name,
                updateData: {
                  
                },
              },
            };
            
      
            const updateUserDataFlight = async () => {
              try {
                setLoading(true);
                await axios
                  .post(
                    "https://dash.bestdiplomats.org/api/method/update-delegate",
                    updateJSONFlight,
                    config
                  )
                  .then((response) => {
                    setCurrentUser(response.data);
                    setIsSaved(true);
                    setTimeout(() => {
                      setIsSaved(false);
                    }, 2000);
                  });
                setLoading(false);
                
              } catch (e) {
                console.log(e);
              }
            };
      
            updateUserDataFlight();
          });
        setLoaderFlightFile(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadFlightApi();
  }
  // Profile Picture Cropper
  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const onClose = () => {
    setPreview(null);
  };
  const onCrop = (view) => {
    setPreview(view);
  };
  const onPopUpClose = (view) => {
    setIsCropingPicture(false);
  };

  function cropFirst() {
    setIsCropingPicture(true);
  }
  const urlToFile = async (url, filename, mimeType) => {
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  };
 
  var result;
  function getCroppedImage() {
    // console.log(">>>");
    var random=Math.random().toString();
    var file=urlToFile(preview, currentUser.data.user.first_name+random+'.png' , 'image/png')
    file.then((a) => {
      uploadProfilePicture(a);
    })
    setIsCropingPicture(false);
  }
  function closePopup() {
    setPreview(null);
  }

  // Profile Picture Upload
  function uploadProfilePicture(e) {
    setProfilePicture(e);
    const fileProfilePicture = e;
    const formData = new FormData();
    formData.append("file", fileProfilePicture);
    formData.append("doctype", "Delegate Profile");
    formData.append("docname", currentUser?.data?.name);
    setInitialProfilePictureValue(null);
    const uploadProfilePictureApi = async () => {
      try {
        setLoaderFlightFile(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then((response) => {
            // console.log(response.data);
            setProfilePictureAvatar(response.data.message.file_url);
            if (response.data.message.file_url == "") {
              setProfilePictureAvatarCheck(true);
            } else {
              setProfilePictureAvatarCheck(false);
            }
            setUploadProfilePictureResponse(response.data);
          });
        setLoaderProfilePicture(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadProfilePictureApi();
  }
  
  
  
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            "https://dash.bestdiplomats.org/api/method/get-delegate?email=" +
              userEmail,
            config
          )
          .then((response) => {
            setCurrentUser(response.data);
            setInitialPassportValue(response.data.data.passport_upload);
            setInitialFlightValue(response.data.data.flight_ticket_scan);
            setInitialVisaValue(response.data.data.visa_scan);
            setInitialProfilePictureValue(response.data.data.user.user_image);
            setInitialCovidValue(
              response.data.data.covid_vaccination_certificate
            );
            setProfilePictureAvatar(response.data.data.user.user_image);
            if (response.data.data.user.user_image == "") {
              setProfilePictureAvatarCheck(true);
            } else {
              setProfilePictureAvatarCheck(false);
            }
            if (response.data.data.arrival_flight_time != null) {
              var splitArray = response.data.data.arrival_flight_time.split(" ");
              setarrival_flight_date(splitArray[0]);
              setarrival_flight_timeOnly(splitArray[1]);
            }

            if (response.data.data.departure_flight_time != null) {
              var splitArrayDep = response.data.data.departure_flight_time.split(" ");
              setdeparture_flight_date(splitArrayDep[0]);
              setdeparture_flight_timeOnly(splitArrayDep[1]);
            }
            const checkPreviousRequest = async () => {
              try {
                setLoadingRequest(true);
                await axios
                  .get(
                    `https://dash.bestdiplomats.org/api/resource/Delegate Name Revision Request?filters=[["delegate_account","=","${response?.data?.data?.name}"],["status","=","Pending"]]`,
                    config
                  )
                  .then((response) => {
                    
                    if((response.data.data).length>0){
                      setLimitedRequestDone(true);
                      }
                  });
                setLoadingRequest(false);
                // setTimeout(() => {
                //   window.location.reload();
                // }, 2000);
                
              } catch (e) {
                console.log(e);
              }
            };
            checkPreviousRequest();

          });
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUserData();
    
  }, []);
  const nameChangeRequest = useFormik({
    initialValues:{
      first_name: currentUser?.data?.user?.first_name,
      last_name: currentUser?.data?.user?.last_name,
    },
    onSubmit:(values)=>{
      var updateJSONrequest={
        old_first_name: currentUser?.data?.user?.first_name,
        old_last_name: currentUser?.data?.user?.last_name,
        new_first_name: values.new_first_name,
        new_last_name: values.new_last_name,
        delegate_account: currentUser?.data?.name,
        legal_file : legalFileResponse.file_url
      }

      
      const requestNameChange = async () => {
        try {
          setNameChangeLoader(true)
          setLoadingRequest(true);
          await axios
            .post(
              "https://dash.bestdiplomats.org/api/resource/Delegate Name Revision Request",
              updateJSONrequest,
              config
            )
            .then((response) => {
              setRequestMade(true)
              setTimeout(() => {
                setOpenDraw(false)
              }, 1000);
              
            });
          setLoadingRequest(false);
          setNameChangeLoader(false)
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
          
        } catch (e) {
          console.log(e);
        }
      };
      
         requestNameChange();
      
      
      
    },
    enableReinitialize: true,
  })

  const updateUserInfo = useFormik({
    initialValues: {
      first_name: currentUser?.data?.user?.first_name,
      last_name: currentUser?.data?.user?.last_name,
      email: currentUser?.data?.user?.email,
      emergency_contact_name: currentUser?.data?.emergency_contact_name,
      nationality: currentUser?.data?.nationality,
      contact_number: currentUser?.data?.contact_number,
      gender: currentUser?.data?.gender,
      address: currentUser?.data?.address,
      emergency_contact_number: currentUser?.data?.emergency_contact_number,
      passport_number: currentUser?.data?.passport_number,
      passport_expiry: currentUser?.data?.passport_expiry,
      // arrival_flight_time: currentUser?.data?.arrival_flight_time,
      arrival_flight_date: arrival_flight_date,
      arrival_flight_timeOnly: arrival_flight_timeOnly,
      arrival_flight_code: currentUser?.data?.arrival_flight_code,
      // departure_flight_time: currentUser?.data?.departure_flight_time,
      departure_flight_date: departure_flight_date,
      departure_flight_timeOnly: departure_flight_timeOnly,
      departure_flight_code: currentUser?.data?.departure_flight_code,
    },
    innerRef:{formRef},
    onSubmit: async (values) => {
    
      if(values.arrival_flight_date == null || values.arrival_flight_timeOnly == null || values.arrival_flight_date == "" || values.arrival_flight_timeOnly == ""){
        if(values.departure_flight_date == null || values.departure_flight_timeOnly == null || values.departure_flight_date == "" || values.departure_flight_timeOnly == ""){
          var updateJSON = {
            participantProfile: {
              id: currentUser.data.name,
              updateData: {
                emergency_contact_name: values.emergency_contact_name,
                nationality: values.nationality,
                contact_number: values.contact_number,
                gender: values.gender,
                address: values.address,
                emergency_contact_number: values.emergency_contact_number,
                passport_number: (values.passport_number).toUpperCase(),
                passport_expiry: values.passport_expiry,
                arrival_flight_time: null,
                arrival_flight_code: values.arrival_flight_code,
                departure_flight_time:  null,
                departure_flight_code: values.departure_flight_code,
                covid_vaccination_certificate:
                  uploadCovidResponse?.message.file_url,
                passport_upload: uploadPassportResponse?.message.file_url,
                flight_ticket_scan: uploadFlightResponse?.message.file_url,
                visa_scan: uploadVisaResponse?.message.file_url,
              },
            },
            relatedUser: {
              id: currentUser.data.user.name,
              updateData: {
                first_name: values.first_name,
                last_name: values.last_name,
                user_image: uploadProfilePictureResponse?.message.file_url,
              },
            },
          };
          
    
          const updateUserData = async () => {
            try {
              setLoading(true);
              await axios
                .post(
                  "https://dash.bestdiplomats.org/api/method/update-delegate",
                  updateJSON,
                  config
                )
                .then((response) => {
                  setCurrentUser(response.data);
                  // console.log(response.data);
                  setIsSaved(true);
                  setTimeout(() => {
                    setIsSaved(false);
                  }, 2000);
                });
              setLoading(false);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 2000);
              
            } catch (e) {
              console.log(e);
            }
          };
    
          updateUserData();
        }else{
          var updateJSON = {
            participantProfile: {
              id: currentUser.data.name,
              updateData: {
                emergency_contact_name: values.emergency_contact_name,
                nationality: values.nationality,
                contact_number: values.contact_number,
                gender: values.gender,
                address: values.address,
                emergency_contact_number: values.emergency_contact_number,
                passport_number: (values.passport_number).toUpperCase(),
                passport_expiry: values.passport_expiry,
                arrival_flight_time: null,
                arrival_flight_code: values.arrival_flight_code,
                departure_flight_time: values.departure_flight_date+" "+values.departure_flight_timeOnly,
                departure_flight_code: values.departure_flight_code,
                covid_vaccination_certificate:
                  uploadCovidResponse?.message.file_url,
                passport_upload: uploadPassportResponse?.message.file_url,
                flight_ticket_scan: uploadFlightResponse?.message.file_url,
                visa_scan: uploadVisaResponse?.message.file_url,
              },
            },
            relatedUser: {
              id: currentUser.data.user.name,
              updateData: {
                first_name: values.first_name,
                last_name: values.last_name,
                user_image: uploadProfilePictureResponse?.message.file_url,
              },
            },
          };
          
    
          const updateUserData = async () => {
            try {
              setLoading(true);
              await axios
                .post(
                  "https://dash.bestdiplomats.org/api/method/update-delegate",
                  updateJSON,
                  config
                )
                .then((response) => {
                  setCurrentUser(response.data);
                  // console.log(response.data);
                  setIsSaved(true);
                  setTimeout(() => {
                    setIsSaved(false);
                  }, 2000);
                });
              setLoading(false);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 2000);
              
            } catch (e) {
              console.log(e);
            }
          };
    
          updateUserData();
        }
      }else{
        if (values.departure_flight_date==null || values.departure_flight_timeOnly==null || values.departure_flight_date == "" || values.departure_flight_timeOnly == "") {
          if (values.arrival_flight_date==null || values.arrival_flight_timeOnly==null || values.arrival_flight_date == "" || values.arrival_flight_timeOnly == ""){
            var updateJSON = {
              participantProfile: {
                id: currentUser.data.name,
                updateData: {
                  emergency_contact_name: values.emergency_contact_name,
                  nationality: values.nationality,
                  contact_number: values.contact_number,
                  gender: values.gender,
                  address: values.address,
                  emergency_contact_number: values.emergency_contact_number,
                  passport_number: (values.passport_number).toUpperCase(),
                  passport_expiry: values.passport_expiry,
                  arrival_flight_time: null,
                  arrival_flight_code: values.arrival_flight_code,
                  departure_flight_time:  null,
                  departure_flight_code: values.departure_flight_code,
                  covid_vaccination_certificate:
                    uploadCovidResponse?.message.file_url,
                  passport_upload: uploadPassportResponse?.message.file_url,
                  flight_ticket_scan: uploadFlightResponse?.message.file_url,
                  visa_scan: uploadVisaResponse?.message.file_url,
                },
              },
              relatedUser: {
                id: currentUser.data.user.name,
                updateData: {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  user_image: uploadProfilePictureResponse?.message.file_url,
                },
              },
            };
            
      
            const updateUserData = async () => {
              try {
                setLoading(true);
                await axios
                  .post(
                    "https://dash.bestdiplomats.org/api/method/update-delegate",
                    updateJSON,
                    config
                  )
                  .then((response) => {
                    setCurrentUser(response.data);
                    // console.log(response.data);
                    setIsSaved(true);
                    setTimeout(() => {
                      setIsSaved(false);
                    }, 2000);
                  });
                setLoading(false);
                // setTimeout(() => {
                //   window.location.reload();
                // }, 2000);
                
              } catch (e) {
                console.log(e);
              }
            };
      
            updateUserData();
          }else{
            var updateJSON = {
              participantProfile: {
                id: currentUser.data.name,
                updateData: {
                  emergency_contact_name: values.emergency_contact_name,
                  nationality: values.nationality,
                  contact_number: values.contact_number,
                  gender: values.gender,
                  address: values.address,
                  emergency_contact_number: values.emergency_contact_number,
                  passport_number: (values.passport_number).toUpperCase(),
                  passport_expiry: values.passport_expiry,
                  arrival_flight_time: values.arrival_flight_date+" "+values.arrival_flight_timeOnly,
                  arrival_flight_code: values.arrival_flight_code,
                  departure_flight_time:  null,
                  departure_flight_code: values.departure_flight_code,
                  covid_vaccination_certificate:
                    uploadCovidResponse?.message.file_url,
                  passport_upload: uploadPassportResponse?.message.file_url,
                  flight_ticket_scan: uploadFlightResponse?.message.file_url,
                  visa_scan: uploadVisaResponse?.message.file_url,
                },
              },
              relatedUser: {
                id: currentUser.data.user.name,
                updateData: {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  user_image: uploadProfilePictureResponse?.message.file_url,
                },
              },
            };
            
      
            const updateUserData = async () => {
              try {
                setLoading(true);
                await axios
                  .post(
                    "https://dash.bestdiplomats.org/api/method/update-delegate",
                    updateJSON,
                    config
                  )
                  .then((response) => {
                    setCurrentUser(response.data);
                    // console.log(response.data);
                    setIsSaved(true);
                    setTimeout(() => {
                      setIsSaved(false);
                    }, 2000);
                  });
                setLoading(false);
                // setTimeout(() => {
                //   window.location.reload();
                // }, 2000);
                
              } catch (e) {
                console.log(e);
              }
            };
      
            updateUserData();
          }
        }else {
          if (values.arrival_flight_date==null || values.arrival_flight_timeOnly==null || values.arrival_flight_date == "" || values.arrival_flight_timeOnly == ""){
            var updateJSON = {
              participantProfile: {
                id: currentUser.data.name,
                updateData: {
                  emergency_contact_name: values.emergency_contact_name,
                  nationality: values.nationality,
                  contact_number: values.contact_number,
                  gender: values.gender,
                  address: values.address,
                  emergency_contact_number: values.emergency_contact_number,
                  passport_number: (values.passport_number).toUpperCase(),
                  passport_expiry: values.passport_expiry,
                  arrival_flight_time: null,
                  arrival_flight_code: values.arrival_flight_code,
                  departure_flight_time:  values.departure_flight_date+" "+values.departure_flight_timeOnly,
                  departure_flight_code: values.departure_flight_code,
                  covid_vaccination_certificate:
                    uploadCovidResponse?.message.file_url,
                  passport_upload: uploadPassportResponse?.message.file_url,
                  flight_ticket_scan: uploadFlightResponse?.message.file_url,
                  visa_scan: uploadVisaResponse?.message.file_url,
                },
              },
              relatedUser: {
                id: currentUser.data.user.name,
                updateData: {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  user_image: uploadProfilePictureResponse?.message.file_url,
                },
              },
            };
            
      
            const updateUserData = async () => {
              try {
                setLoading(true);
                await axios
                  .post(
                    "https://dash.bestdiplomats.org/api/method/update-delegate",
                    updateJSON,
                    config
                  )
                  .then((response) => {
                    setCurrentUser(response.data);
                    // console.log(response.data);
                    setIsSaved(true);
                    setTimeout(() => {
                      setIsSaved(false);
                    }, 2000);
                  });
                setLoading(false);
                // setTimeout(() => {
                //   window.location.reload();
                // }, 2000);
                
              } catch (e) {
                console.log(e);
              }
            };
      
            updateUserData();
          }else{
            
              var updateJSON = {
                participantProfile: {
                  id: currentUser.data.name,
                  updateData: {
                    emergency_contact_name: values.emergency_contact_name,
                    nationality: values.nationality,
                    contact_number: values.contact_number,
                    gender: values.gender,
                    address: values.address,
                    emergency_contact_number: values.emergency_contact_number,
                    passport_number: (values.passport_number).toUpperCase(),
                    passport_expiry: values.passport_expiry,
                    arrival_flight_time: values.arrival_flight_date+" "+values.arrival_flight_timeOnly,
                    arrival_flight_code: values.arrival_flight_code,
                    departure_flight_time:  values.departure_flight_date+" "+values.departure_flight_timeOnly,
                    departure_flight_code: values.departure_flight_code,
                    covid_vaccination_certificate:
                      uploadCovidResponse?.message.file_url,
                    passport_upload: uploadPassportResponse?.message.file_url,
                    flight_ticket_scan: uploadFlightResponse?.message.file_url,
                    visa_scan: uploadVisaResponse?.message.file_url,
                  },
                },
                relatedUser: {
                  id: currentUser.data.user.name,
                  updateData: {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    user_image: uploadProfilePictureResponse?.message.file_url,
                  },
                },
              };
              
        
              const updateUserData = async () => {
                try {
                  setLoading(true);
                  await axios
                    .post(
                      "https://dash.bestdiplomats.org/api/method/update-delegate",
                      updateJSON,
                      config
                    )
                    .then((response) => {
                      setCurrentUser(response.data);
                      console.log(response.data);
                      setIsSaved(true);
                      setTimeout(() => {
                        setIsSaved(false);
                      }, 2000);
                    });
                  setLoading(false);
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 2000);
                  
                } catch (e) {
                  console.log(e);
                }
              };
        
              updateUserData();
            
          }
        }
      }
      

      
      
    },
    enableReinitialize: true,
  });

  return (
    <>
    <div className="mt-10 sm:mt-0 ">
      <div className="md:grid md:grid-cols-3 md:gap-6 bodyprofile">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0 mt-8">
            <h3 className="text-2xl font-medium leading-6 text-gray-900">
              Delegate Profile Details
            </h3>
            <p className="mt-1 text-xl text-gray-600">
              Make sure to hit save after updating your information.
            </p>
          </div>
          {loading ? (
            <div
              role="status"
              className="animate-pulse rounded-full mt-10 w-full md:w-6/12 mb-6 md:mb-0 md:p-3"
            >
              <div className="flex justify-center items-center mb-4 h-48 bg-gray-100 rounded dark:bg-gray-200">
                <svg
                  className="w-12 h-12 text-gray-200 dark:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 640 512"
                >
                  <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                </svg>
              </div>
            </div>
          ) : (
            <div className="mt-4 flex justify-center lg:justify-start">
              <div className="flex flex-col justify-center">
                {ProfilePictureAvatarCheck ? (
                  <img
                    className="w-56 h-56 rounded-full content-center justify-center mt-10 border-solid border-2 border-sky-500 focus:border-indigo-500 focus:ring-indigo-500"
                    src={window.$domain + "/files/avatar.jpg"}
                    alt="Extra large avatar"
                  />
                ) : (
                  <img
                    className="w-56 h-56 rounded-full content-center justify-center mt-10 border-solid border-2 border-sky-500 focus:border-indigo-500 focus:ring-indigo-500"
                    src={window.$domain + "/" + ProfilePictureAvatar}
                    alt="Extra large avatar"
                  />
                )}

                <label
                  htmlFor="user_image"
                  className="text-sm font-medium text-gray-700 flex justify-center"
                >
                <div className="flex flex-col">
                  <button onClick={cropFirst} style={{"background-color": "#4591DC"}} className="mt-4 text-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Edit Profile Picture
                  </button>
                  {limitedRequestDone ? (
                    <button className="mt-4 text-center rounded-md border border-transparent bg-gray-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                    Request Name Change
                  </button>
                  ):(<button onClick={()=>{setOpenDraw(true)}} style={{"background-color": "#4591DC"}} className="mt-4 text-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Request Name Change
                  </button>)}
                  
                  
                  </div>
                </label>
                <input
                  type="file"
                  name="user_image"
                  id="user_image"
                  value={updateUserInfo.values.user_image}
                  // onChange={(e) => {
                  //   setIsCropingPicture(true);
                  //   // uploadProfilePicture(e);
                  // }}
                  className="mt-1 sr-only block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              
            </div>
          )}
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form id="submit_profile_form" ref={formRef} onSubmit={updateUserInfo.handleSubmit}>
            <div className="overflow-hidden shadow-xl sm:rounded-md">
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                {loading ? (
                  <div style={{"background-color": "#4591DC"}} className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <svg
                      fill="#87CEEB"
                      viewBox="0 0 1792 1792"
                      className="h-8 flex-no-shrink animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                    </svg>
                  </div>
                ) : isSaved ? (
                  <p className="font-medium text-xl text-blue-400 text-center">
                    Saved ✅
                  </p>
                ) : (
                  <button
                  
                    type="submit"
                    style={{"background-color": "#4591DC"}}
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                )}
              </div>
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  {/* -----------Section Breake ----------------*/}
                  <div className="overflow-hidden shadow-md mt-5 col-span-6 md:mt-6">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        {/*---------------- Section Breake Div End ----------------*/}

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="first_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.first_name}
                            className="mt-1 block w-full rounded-md border-gray-200 text-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            disabled
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="last_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            required
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.last_name}
                            className="mt-1 block w-full rounded-md border-gray-200 text-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            disabled
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.email}
                            className="mt-1 block w-full rounded-md border-gray-200 text-gray-400 shadow-sm  sm:text-sm"
                            disabled
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="contact_number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone Number
                          </label>
                          <input
                            type="number"
                            name="contact_number"
                            id="contact_number"
                            pattern="\d*"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.contact_number}
                            autoComplete="phone"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Nationality
                          </label>
                          <select
                            id="nationality"
                            name="nationality"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.nationality}
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          >
                             <option value="Not selected">Not selected</option>
                             <option value="Afghanistan">Afghanistan</option>
                              <option value="Åland Islands">
                                Åland Islands
                              </option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antarctica">Antarctica</option>
                              <option value="Antigua and Barbuda">
                                Antigua and Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">
                                Bosnia and Herzegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island">
                                Bouvet Island
                              </option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Territory">
                                British Indian Ocean Territory
                              </option>
                              <option value="Brunei Darussalam">
                                Brunei Darussalam
                              </option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos (Keeling) Islands">
                                Cocos (Keeling) Islands
                              </option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Congo, The Democratic Republic of The">
                                Congo, The Democratic Republic of The
                              </option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote D'ivoire">
                                Cote D'ivoire
                              </option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands (Malvinas)">
                                Falkland Islands (Malvinas)
                              </option>
                              <option value="Faroe Islands">
                                Faroe Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern Territories">
                                French Southern Territories
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guernsey">Guernsey</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-bissau">
                                Guinea-bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard Island and Mcdonald Islands">
                                Heard Island and Mcdonald Islands
                              </option>
                              <option value="Holy See (Vatican City State)">
                                Holy See (Vatican City State)
                              </option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran, Islamic Republic of">
                                Iran, Islamic Republic of
                              </option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea, Democratic People's Republic of">
                                Korea, Democratic People's Republic of
                              </option>
                              <option value="Korea, Republic of">
                                Korea, Republic of
                              </option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao People's Democratic Republic">
                                Lao People's Democratic Republic
                              </option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">
                                Libyan Arab Jamahiriya
                              </option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macao">Macao</option>
                              <option value="Macedonia, The Former Yugoslav Republic of">
                                Macedonia, The Former Yugoslav Republic of
                              </option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">
                                Micronesia, Federated States of
                              </option>
                              <option value="Moldova, Republic of">
                                Moldova, Republic of
                              </option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">
                                Netherlands Antilles
                              </option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="Northern Mariana Islands">
                                Northern Mariana Islands
                              </option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestinian Territory, Occupied">
                                Palestinian Territory, Occupied
                              </option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn">Pitcairn</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Republic of Somaliland">Republic of Somaliland</option>
                              <option value="Russian Federation">
                                Russian Federation
                              </option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Helena">Saint Helena</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Pierre and Miquelon">
                                Saint Pierre and Miquelon
                              </option>
                              <option value="Saint Vincent and The Grenadines">
                                Saint Vincent and The Grenadines
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Somaliland">Somaliland</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia and The South Sandwich Islands">
                                South Georgia and The South Sandwich Islands
                              </option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard and Jan Mayen">
                                Svalbard and Jan Mayen
                              </option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">
                                Syrian Arab Republic
                              </option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania, United Republic of">
                                Tanzania, United Republic of
                              </option>
                              <option value="Thailand">Thailand</option>
                              <option value="Timor-leste">Timor-leste</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks and Caicos Islands">
                                Turks and Caicos Islands
                              </option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="United States Minor Outlying Islands">
                                United States Minor Outlying Islands
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Viet Nam">Viet Nam</option>
                              <option value="Virgin Islands, British">
                                Virgin Islands, British
                              </option>
                              <option value="Virgin Islands, U.S.">
                                Virgin Islands, U.S.
                              </option>
                              <option value="Wallis and Futuna">
                                Wallis and Futuna
                              </option>
                              <option value="Western Sahara">
                                Western Sahara
                              </option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="gender"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Gender
                          </label>
                          <select
                            id="gender"
                            name="gender"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.gender}
                            autoComplete="gender-name"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          >
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                          </select>
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Address
                          </label>
                          <textarea
                            type="text"
                            name="address"
                            id="address"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.address}
                            autoComplete="street-address"
                            className="mt-1 block w-full h-48 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overflow-hidden shadow-md mt-5 col-span-6 md:mt-6">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                          <label
                            htmlFor="emergency_contact_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Emergency Contact Name
                          </label>
                          <input
                            type="text"
                            name="emergency_contact_name"
                            id="emergency_contact_name"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.emergency_contact_name}
                            autoComplete="address-level2"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                          <label
                            htmlFor="emergency_contact_number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Emergency Contact Number
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            name="emergency_contact_number"
                            id="emergency_contact_number"
                            onChange={updateUserInfo.handleChange}
                            value={
                              updateUserInfo.values.emergency_contact_number
                            }
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overflow-hidden shadow-md mt-5 col-span-6 md:mt-6">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            htmlFor="passport_number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Passport Number
                          </label>
                          <input
                            type="text"
                            name="passport_number"
                            id="passport_number"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.passport_number}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            htmlFor="passport_expiry"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Passport Expiry Date
                          </label>
                          <input
                            type="date"
                            name="passport_expiry"
                            id="passport_expiry"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.passport_expiry}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-6">
                          <label
                            htmlFor="passport_upload"
                            className="mb-2 block text-xl font-semibold text-[#07074D] "
                          >
                            Upload Passport Document
                          </label>

                          <div class="mb-1">
                            <input
                              type="file"
                              name="passport_upload"
                              id="passport_upload"
                              class="sr-only"
                              value={updateUserInfo.values.passport_upload}
                              onChange={(e) => {
                                uploadPassportDocument(e);
                              }}
                            />
                            <label
                              htmlFor="passport_upload"
                              class="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                            >
                              <div>
                                {initialPassportValue ? (
                                  <>
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      {initialPassportValue.split("/")[3]}
                                    </span>
                                    <span class="mb-2 block text-base font-medium text-[#6B7280]">
                                      Or
                                    </span>
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      Update File
                                    </span>
                                  </>
                                ) : passportDocument ? (
                                  loaderPassportFile ? (
                                    <>
                                      <svg
                                        fill="#87CEEB"
                                        viewBox="0 0 1792 1792"
                                        className="h-8 flex-no-shrink animate-spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                                      </svg>
                                    </>
                                  ) : (
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      {passportDocument}
                                    </span>
                                  )
                                ) : (
                                  <>
                                  <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      Add File
                                    </span>
                                  </>
                                )}
                              </div>
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            htmlFor="arrival_flight_time"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Arrival Flight Time
                          </label>
                          <input
                            type="datetime-local"
                            name="arrival_flight_time"
                            id="arrival_flight_time"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.arrival_flight_time}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div> */}
                        
                        {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="arrival_flight_date"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Arrival Flight Date
                          </label>
                          <input
                            type="date"
                            name="arrival_flight_date"
                            id="arrival_flight_date"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.arrival_flight_date}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="arrival_flight_timeOnly"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Arrival Flight Time
                          </label>
                          <input
                            type="time"
                            name="arrival_flight_timeOnly"
                            id="arrival_flight_timeOnly"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.arrival_flight_timeOnly}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="arrival_flight_code"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Arrival Flight Code
                          </label>
                          <input
                            type="text"
                            name="arrival_flight_code"
                            id="arrival_flight_code"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.arrival_flight_code}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div> */}
                        {/* <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            htmlFor="passport_number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Departure Flight Time
                          </label>
                          <input
                            type="datetime-local"
                            name="departure_flight_time"
                            id="departure_flight_time"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.departure_flight_time}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div> */}
                        {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="departure_flight_date"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Departure Flight Time
                          </label>
                          <input
                            type="date"
                            name="departure_flight_date"
                            id="departure_flight_date"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.departure_flight_date}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="departure_flight_timeOnly"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Departure Flight Time
                          </label>
                          <input
                            type="time"
                            name="departure_flight_timeOnly"
                            id="departure_flight_timeOnly"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.departure_flight_timeOnly}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="passport_number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Departure Flight Code
                          </label>
                          <input
                            type="text"
                            name="departure_flight_code"
                            id="departure_flight_code"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.departure_flight_code}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div> */}
                        {/* <div className="col-span-6 sm:col-span-3 lg:col-span-6">
                          <label
                            htmlFor="flight_ticket_scan"
                            className="mb-2 block text-xl font-semibold text-[#07074D] "
                          >
                            Upload Flight Ticket
                          </label>

                          <div class="mb-1">
                            <input
                              type="file"
                              name="flight_ticket_scan"
                              id="flight_ticket_scan"
                              class="sr-only"
                              value={updateUserInfo.values.flight_ticket_scan}
                              onChange={(e) => {
                                uploadFlightDocument(e);
                              }}
                            />
                            <label
                              htmlFor="flight_ticket_scan"
                              class="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                            >
                              <div>
                                {initialFlightValue ? (
                                  <>
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      {initialFlightValue.split("/")[2]}
                                    </span>
                                    <span class="mb-2 block text-base font-medium text-[#6B7280]">
                                      Or
                                    </span>
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      Update File
                                    </span>
                                  </>
                                ) : FlightDocument ? (
                                  loaderFlightFile ? (
                                    <>
                                      <svg
                                        fill="#87CEEB"
                                        viewBox="0 0 1792 1792"
                                        className="h-8 flex-no-shrink animate-spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                                      </svg>
                                    </>
                                  ) : (
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      {FlightDocument}
                                    </span>
                                  )
                                ) : (
                                  <>
                                    
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      Add File
                                    </span>
                                  </>
                                )}
                              </div>
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="overflow-hidden shadow-md mt-5 col-span-6 md:mt-6">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3 lg:col-span-6">
                          <label
                            htmlFor="visa_scan"
                            class="mb-2 block text-xl font-semibold text-[#07074D]"
                          >
                            Upload Visa Document
                          </label>

                          <div class="mb-1">
                            <input
                              type="file"
                              name="visa_scan"
                              id="visa_scan"
                              class="sr-only"
                              value={updateUserInfo.values.visa_scan}
                              onChange={(e) => {
                                uploadVisaDocument(e);
                              }}
                            />
                            <label
                              htmlFor="visa_scan"
                              class="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                            >
                              <div>
                                {initialVisaValue ? (
                                  <>
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      {initialVisaValue.split("/")[2]}
                                    </span>
                                    <span class="mb-2 block text-base font-medium text-[#6B7280]">
                                      Or
                                    </span>
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      Update File
                                    </span>
                                  </>
                                ) : visaDocument ? (
                                  loaderVisaFile ? (
                                    <>
                                      <svg
                                        fill="#87CEEB"
                                        viewBox="0 0 1792 1792"
                                        className="h-8 flex-no-shrink animate-spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                                      </svg>
                                    </>
                                  ) : (
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      {visaDocument}
                                    </span>
                                  )
                                ) : (
                                  <>
                                  <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      Add File
                                    </span>
                                  </>
                                )}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="overflow-hidden shadow-md mt-5 col-span-6 md:mt-6">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3 lg:col-span-6">
                          <label
                            htmlFor="covid_vaccination_certificate"
                            class="mb-2 block text-xl font-semibold text-[#07074D]"
                          >
                            Upload Covid Certificate Document
                          </label>

                          <div class="mb-1">
                            <input
                              type="file"
                              name="covid_vaccination_certificate"
                              id="covid_vaccination_certificate"
                              class="sr-only"
                              value={
                                updateUserInfo.values
                                  .covid_vaccination_certificate
                              }
                              onChange={(e) => {
                                uploadCovidDocument(e);
                              }}
                            />
                            <label
                              htmlFor="covid_vaccination_certificate"
                              class="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                            >
                              <div>
                                {initialCovidValue ? (
                                  <>
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      {initialCovidValue.split("/")[2]}
                                    </span>
                                    <span class="mb-2 block text-base font-medium text-[#6B7280]">
                                      Or
                                    </span>
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      Update File
                                    </span>
                                  </>
                                ) : covidDocument ? (
                                  loaderCovidFile ? (
                                    <>
                                      <svg
                                        fill="#87CEEB"
                                        viewBox="0 0 1792 1792"
                                        className="h-8 flex-no-shrink animate-spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                                      </svg>
                                    </>
                                  ) : (
                                    <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      {covidDocument}
                                    </span>
                                  )
                                ) : (
                                  <>
                                  <span class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                                      Add File
                                    </span>
                                  </>
                                )}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* Cropper Popup */}
        {isCropingPicture ? (
          <div className="flex flex-col justify-center mt-0 bg-gray-100 bg-opacity-75 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full">
            <div className="flex justify-center">
              <Avatar
                width={400}
                height={300}
                onCrop={onCrop}
                onClose={onClose}
                exportSize={500}
                src={src}
              ></Avatar>
            </div>
            <div className="mt-12 flex justify-center ">
              {preview && (
                <img
                  className="border-4 border-blue-400 rounded"
                  src={preview}
                  alt="Cropped Image"
                />
              )}
              {/* {console.log(preview)} */}
            </div>
            <div className="mt-12 flex flex-row justify-center ">
              <button
                onClick={getCroppedImage}
                style={{"background-color": "#4591DC"}}
                className="rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save
              </button>
              <button
                onClick={onPopUpClose}
                className="rounded-md border  border-transparent ml-4 bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Close
              </button>
              
            </div>
          </div>
        ) : null}
      </div>
      {/* <CropperComp /> */}
      
    </div>
    <Transition.Root show={openDraw} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenDraw}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                      onSubmit={nameChangeRequest.handleSubmit}
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-100 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Request Name Change
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started..
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpenDraw(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          {/* Profile Pic */}
                          <div className="divide-y divide-gray-200">
                            <div className="pb-6">
                              <div className="h-10 bg-gray-100 sm:h-10 lg:h-10" />
                              <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                                
                                <div className="mt-6 sm:ml-6 sm:flex-1">
                                  <div>
                                    <div className="flex items-center">
                                      <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                        {/* {currentUser?.data?.user?.full_name} */}
                                      </h3>
                                    </div>
                                    <p className="text-sm text-gray-500">
                                      {/* {currentUser?.data?.user?.email} */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* First name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="first_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                First name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.first_name}
                                disabled
                                className="block w-full rounded-md text-gray-400 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* Last name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="last_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Last name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.last_name}
                                disabled
                                className="block w-full rounded-md text-gray-400 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* First name Updated*/}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="new_first_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                New First name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="new_first_name"
                                id="new_first_name"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.new_first_name}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* Last name Updated */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="new_last_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                New Last name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="new_last_name"
                                id="new_last_name"
                                onChange={nameChangeRequest.handleChange}
                                value={nameChangeRequest.values.new_last_name}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* Prove File */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="legal_file"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Upload an official document which should contain your full legal name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                           
                    <input
                                type="file"
                                name="legal_file"
                                id="legal_file"
                                onChange={(e) => {
                        handleLegalFileUpload(e);
                      }}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                              {nameChangeLoader && (<><svg
                      fill="#87CEEB"
                      viewBox="0 0 1792 1792"
                      className="h-8 flex-no-shrink animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                    </svg>
                    </>)}
                            </div>
                          </div>
                          
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpenDraw(false)}
                          >
                            Cancel
                          </button>
                          {RequestMade ? (<a
                            className="inline-flex  justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          >
                           Rquest Submitted Successfully
                          </a>):(nameChangeLoader ? (<svg
                      fill="#87CEEB"
                      viewBox="0 0 1792 1792"
                      className="h-8 flex-no-shrink animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                    </svg>):(<button
                            type="submit"
                            className="inline-flex color justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                           Submit Request
                          </button>) )}
                          
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Profile;
