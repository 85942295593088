import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

import { EyeSlashIcon,EyeIcon } from "@heroicons/react/24/outline";
const people = [
  { id: 1, name: "Leslie Alexander" },
  // More users...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DelegateRegistrationForm = () => {
  const options = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, The Democratic Republic of The",
    "Republic of the Congo",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-bissau",
    "Guyana",
    "Haiti",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "North Korea",
    "South Korea",
    "Kuwait",
    "Kosovo",
    "Lao People's Democratic Republic",
    "Kyrgyzstan",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "North Macedonia",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territories",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Republic of Somaliland",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "Scotland",
    "Sudan",
    "South Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan (ROC)",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "International Observer",
    "Ambassador",
    "Diplomatic Experts",
    "Local Team Members",
    "Ambassadors",
    "Speakers and Guest of Honours",
    "International Observers",
    "Volunteers",
  ];

  const optionsArray = options.map((option, index) => ({
    id: index + 1,
    value: option,
    name: option,
  }));

  console.log(optionsArray);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isReadonly, setIsReadonly] = useState(false);
  
  const { id } = useParams();
  console.log(id);
  const handleCheckboxChanges = () => {
    setIsReadonly(!isReadonly);
  };
  const nevigate = useNavigate();
  const createambassador = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      password:"",
      nationality:"",
      street_address:"",
      city_address:"",
      state_address:"",
      country_address:"",
      referred_by_cr_profile:"",
      partial_registration:""
    },
    onSubmit: (values) => {
      var jsontoPass = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        nationality: selectedPerson?.value,
        password:values.password,
        street_address:values.street_address,
        city_address:values.city_address,
        state_address:values.state_address,
        country_address:selectedCountry.value,
        referred_by_cr_profile:id,
        partial_registration:1
      };
      setIsLoading(true);
      // console.log(jsontoPass);
      const fetchAmbData = async (jsontoPass) => {
        // console.log(jsontoPass);
        try {
          await axios
            .post(
              `https://dash.bestdiplomats.org/api/method/bestdiplomats.api.add_delegate_account_referral_web`,
              jsontoPass
            )
            .then((res) => {
              toast.success("Create Account successfully");
              setIsLoading(false);
              createambassador.resetForm()
              setSelectedCountry(null)
              setSelectedPerson(null)
              setIsReadonly(!isReadonly);
              // nevigate("/registration-successfully");

              //   setCreateAnnouncementSuccess(!createAnnouncementSuccess);
            });
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          if (error.response && error.response.status === 409) {
            toast.error(
              "Your Account Already Exist Please Countinue Your Registration on the App"
            );
          } else {
            toast.error("Please try again later.");
          }
          // setTimeout(() => {

          //     // nevigate("/registration-already");
          // }, 1500);
        }
      };
      fetchAmbData(jsontoPass);
    },

    enableReinitialize: true,
  });

  const filteredPeople =
    query === ""
      ? optionsArray
      : optionsArray.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        }); 
   const filteredCountry =
   searchQuery === ""
      ? optionsArray
      : optionsArray.filter((person) => {
          return person.name.toLowerCase().includes(searchQuery.toLowerCase());
        });
        
  const [shownewPassword, setShownewPassword] = useState(false);
        const handleTogglenewPassword = () => {
          setShownewPassword(!shownewPassword);
        };

  return (
    <>
      <Toaster position="bottom-left" richColors />

      <form
        className="flex min-h-full mx-auto max-w-7xl  flex-col justify-center px-3 py-3 lg:items-center"
        onSubmit={createambassador.handleSubmit}
      >
        <div className="space-y-2 ">
          <div className="border-0 border-gray-900/10 pb-12">
            <h2 className="mt-5 text-center sm:text-xl text-lg font-bold leading-6 tracking-tight text-gray-900">
              Join Best Diplomats Program
            </h2>

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-12">
              <div className="sm:col-span-6">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="Enter First Name"
                    required
                    onChange={createambassador.handleChange}
                    value={createambassador.values.first_name}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Enter Last Name"
                    required
                    onChange={createambassador.handleChange}
                    value={createambassador.values.last_name}
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="email@gmail.com"
                    
                    required
                    onChange={createambassador.handleChange}
                    value={createambassador.values.email}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
               <div className="sm:col-span-full">
              
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900 text-start"
          >
            Password
          </label>
          <div className="relative mt-1 flex items-center">
     
      <div className="flex-1">
        <input
          id="password"
          name="password"
          type={shownewPassword ? 'text' : 'password'}
          placeholder="Enter Password"
          onChange={createambassador.handleChange}
          value={createambassador.values.password}
          className="block w-full rounded-md border-0 py-1.5 pr-7 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          required
        />
         <button
        type="button"
        onClick={handleTogglenewPassword}
        className=" absolute top-2 w-5 right-1"
      >
        {shownewPassword ?  <EyeIcon className="h-5 w-5  flex justify-center items-center  text-gray-500 cursor-pointer" /> : <EyeSlashIcon className="h-5 w-5  flex justify-center items-center  text-gray-500 cursor-pointer" />}
      </button>
      </div>

        </div>
              </div>

              <div className="sm:col-span-full">
                <Combobox
                  as="div"
                  value={selectedPerson}
                  onChange={setSelectedPerson}
                >
                  <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    Nationality
                  </Combobox.Label>
                  <div className="relative mt-1">
                    <Combobox.Input
                      className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(event) => setQuery(event.target.value)}
                      displayValue={(person) => person?.name}
                      placeholder="Nationality"
                      autoComplete="off"
                      required
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>

                    {filteredPeople.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredPeople.map((person) => (
                          <Combobox.Option
                            key={person.id}
                            value={person}
                            className={({ active }) =>
                              classNames(
                                "relative cursor-default select-none py-2 pl-3 pr-9",
                                active
                                  ? "bg-indigo-600 text-white"
                                  : "text-gray-900"
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <span
                                  className={classNames(
                                    "block truncate",
                                    selected && "font-semibold"
                                  )}
                                >
                                  {person.name}
                                </span>

                                {selected && (
                                  <span
                                    className={classNames(
                                      "absolute inset-y-0 right-0 flex items-center pr-4",
                                      active ? "text-white" : "text-indigo-600"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>

              <div className="col-span-full ">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Address Detail
                </label>
              </div>

              <div className="sm:col-span-full">
                <label
                  htmlFor="street_address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Street
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    onChange={createambassador.handleChange}
                    value={createambassador.values.street_address}
                    name="street_address"
                    placeholder="Street"
                    required
                    id="street_address"
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-5">
                <label
                  htmlFor="city_address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  City
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    onChange={createambassador.handleChange}
                    value={createambassador.values.city_address}
                    name="city_address"
                    placeholder="City"
                    required
                    id="city_address"
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-7">
                <label
                  htmlFor="state_address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  State/Province/Region
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="state_address"
                    onChange={createambassador.handleChange}
                    value={createambassador.values.state_address}
                    required
                    placeholder="State"
                    id="state_address"
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-full">
                <Combobox
                  as="div"
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                >
                  <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    Country
                  </Combobox.Label>
                  <div className="relative mt-1">
                    <Combobox.Input
                    autoComplete="off"
                      className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 placeholder:text-gray-400 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(event) => setSearchQuery(event.target.value)}
                      displayValue={(person) => person?.name}
                      placeholder="Country"
                      required
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>

                    {filteredCountry.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredCountry.map((person) => (
                          <Combobox.Option
                            key={person.id}
                            value={person}
                            className={({ active }) =>
                              classNames(
                                "relative cursor-default select-none py-2 pl-3 pr-9",
                                active
                                  ? "bg-indigo-600 text-white"
                                  : "text-gray-900"
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <span
                                  className={classNames(
                                    "block truncate",
                                    selected && "font-semibold"
                                  )}
                                >
                                  {person.name}
                                </span>

                                {selected && (
                                  <span
                                    className={classNames(
                                      "absolute inset-y-0 right-0 flex items-center pr-4",
                                      active ? "text-white" : "text-indigo-600"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>

              <div className="col-span-full ">
                <fieldset>
                  <div className="mt-1 space-y-6">
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input
                          id="comments"
                          name="comments"
                          checked={isReadonly}
                          onChange={handleCheckboxChanges}
                          type="checkbox"
                          required
                          className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900"
                        >
                          I agree to the terms and conditions
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="col-span-full  mt-3">
                <div className=" flex items-center justify-center  gap-x-6">
                  {isloading ? (
                    <button
                      type="button"
                      className="flec justify-center items-center"
                      role="status"
                    >
                      <svg
                        aria-hidden="true"
                        class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="rounded-md w-full bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default DelegateRegistrationForm;
