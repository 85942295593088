import { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Dialog, Menu,Listbox, Transition } from "@headlessui/react";

import {  ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import { Toaster, toast } from "sonner";
import PrivateImage from "../PrivateImage/PrivateImage";
var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const Registrationstatus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [transferRequst, setTransferRequst] = useState(null);
  const [isNotFoundName, setIsNotFoundName] = useState(false)
  const [open, setOpen] = useState(false);
  const [statusUpdateSuc, setStatusUpdateSuc] = useState(false);
  const [entriesLength, SetEntriesLength] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [inviteFilter, setInviteFilter] = useState(
    `["approval_status","is","set"]`
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const [getAllEvents, setGetAllEvents] = useState([]);

  const tableRef = useRef(null);


const [selected, setSelected] = useState({name:"None",title:"All"})
// console.log(selected);
  useEffect(() => {
    // console.log(`https://dash.bestdiplomats.org/api/method/get-conference-transfer-request?filters=[${inviteFilter}]`);
    const geteventdata = async () => {
      const result = await axios
        .get(
          `https://dash.bestdiplomats.org/api/method/get-conference-transfer-request?filters=[${inviteFilter}]`,
          config
        )
        .then((res) => {
          setTransferRequst(res.data.data);
          setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(res.data.data.length / 60);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              // console.log(pageNumbers);
            }
          SetEntriesLength(res.data.data.length);
          setLoadingSkeleton(false)
          setIsNotFoundName(false);
          // console.log(res.data.data.length);
          if(res.data.data.length==[]){
              setLoadingSkeleton(true)
            setIsNotFoundName(true);
          }
          // var Difference_In_Time =
          //     new Date(res.data.data.event.start_date).getTime() -
          //     new Date(res.data.data.creation).getTime();
          //   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          //   console.log(Difference_In_Days)
          //   setTotalDays(Math.floor(Difference_In_Days));
        })
        .catch((e) => {
          console.log("t errrrr", e);
          toast.error('No Data Found')
          setTransferRequst([]);
          SetEntriesLength(0);
          setIsNotFoundName(true);
          if (e=="Network Error") {
            // console.log(">>>>>>>>>>>>>>");
            setLoadingSkeleton(true)
          }
        });
    };
    // console.log(inviteFilter);
    geteventdata();
  }, [statusUpdateSuc, inviteFilter]);

  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleClick = (number) => {
    setCurrentPage(number);
    scroll();
    scrollToTop()
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop()
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop()
      }
    }
  };
  const [transforRequstDetail, setTransforRequstDetail] = useState(null);
  const handleViewDetails = (person) => {
    setStatus(person.approval_status)
    if (person.approval_status == "Approved") {
      setIsApproved(true);
      setIsRejected(false);
    } else if (person.approval_status == "Rejected") {
      setIsApproved(false);
      setIsRejected(true);
    } else {
      setIsApproved(false);
      setIsRejected(false);
    }
    setTransforRequstDetail(person);
    // console.log(person);
    setOpen(true);
  };
  const [status, setStatus]=useState("Pending")
  const handleViewBox = (e) => {
    setStatus(e)
    if (e == "Approved") {
      setIsApproved(true);
      setIsRejected(false);
    } else if (e == "Rejected") {
      setIsApproved(false);
      setIsRejected(true);
    } else {
      setIsApproved(false);
      setIsRejected(false);
    }
  };
  const StatusUpdate = useFormik({
    initialValues: {
      approval_status: transforRequstDetail?.approval_status,
      approval_comment: transforRequstDetail?.approval_comment,
      rejection_comment: transforRequstDetail?.rejection_comment,
      voucher_code: transforRequstDetail?.voucher_code,
    },
    onSubmit: (values) => {
      var statusPass = {
        approval_status: status,
        approval_comment: values.approval_comment,
        rejection_comment: values.rejection_comment,
        voucher_code: values.voucher_code,
      };

      const fetchcategory = async (statusPass) => {
        try {
          await axios
            .put(
              `${window.$domain}/api/resource/Conference Transfer Request/${transforRequstDetail?.name}`,
              statusPass,
              config
            )
            .then((res) => {
              // console.log("updatae",res);
              
              setStatusUpdateSuc(true);
              setTimeout(() => {
                setStatusUpdateSuc(false);
              }, 1000);
            });
        } catch (e) {
          console.log(e);
        }
      };

      fetchcategory(statusPass);
    },

    enableReinitialize: true,
  });
  useEffect(() => {
    const fetchEventsData = async () => {
      try {
        const result = await axios
          .get(
            'https://dash.bestdiplomats.org/api/resource/Events?filters=[["status","in","Active,Upcoming"]]&fields=["*"]'
          )
          .then((response) => {
            const newData = response.data.data
            newData.unshift({name:"None",title:"All"})
            setGetAllEvents(newData)
            
          // console.log("events","getAllEvents",newData,response.data.data);
          });
        
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventsData();
  }, []);
  const applyFiltersTicket = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      // console.log(values);
      if(selected?.name!="None"){
        setInviteFilter(`["event","=","${selected?.name}"]`);
      }else{
         setInviteFilter(`["approval_status","is","set"]`);
        
      }
  
      if (values.approval_status) {
        if (values.approval_status != "None") {
        
          setInviteFilter((prevState) => [
            prevState + `,["approval_status","=","${values.approval_status}"]`,
          ]);
        }
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <div className="mt-4 flex justify-end items-center  sm:mt-0 sm:ml-1 sm:flex-none">
        <form onSubmit={applyFiltersTicket.handleSubmit}>
       <div className="mt-4 flex justify-end gap-3 pb-1 items-center  sm:mt-0 sm:ml-5 sm:flex-none">
       {loadingSkeleton ?<><Skeleton width={100} height={40} /> <Skeleton width={100} height={40} /><Skeleton width={100} height={40} /></>: <> <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block sr-only text-sm font-medium leading-6 text-gray-900">Assigned to</Listbox.Label>
          <div className="relative mt-0">
            <Listbox.Button className="relative w-[18rem] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900  ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.title}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                {getAllEvents?.map((person) => (
                  <Listbox.Option
                    key={person.name}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {person.title}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>  <label htmlFor="approval_status" className="sr-only"></label>
            <select
              id="approval_status"
              name="approval_status"
              className=" block w-60  rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              onChange={applyFiltersTicket.handleChange}
              value={applyFiltersTicket.values.approval_status}
            >
              <option value="None">All</option>
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
              <option value="Rejected">Rejected</option>
              <option value="Appealed">Appealed</option>
              <option value="Blocked">Blocked</option>
            </select>
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Apply Filter
            </button></>}
          </div>
        </form>
      </div>
      <div className=" px-2 py-1 ">
        <p className="felx items-center justify-end text-right font-semibold">
        {loadingSkeleton ?<><Skeleton width={40} height={20} /> </>:  <>Requests: {entriesLength}</>}
        </p>
      </div>
      
      <div className="">
        <div className="mt-3 flex flex-col">
          <div ref={tableRef} className="-my-2 -mx-4 overflow-x-auto max-h-screen sm:-mx-4 lg:-mx-4">
            <div className="inline-block  overflow-x-auto min-w-full py-2 align-middle md:px-2 lg:px-8">
            
              { isNotFoundName?   <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
           <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
            </svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>:  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">  <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-stone-100">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 whitespace-nowrap pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Requested On
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        From
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        To
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Days
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Deduction
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  {loadingSkeleton?<tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     <Skeleton />
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>

                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                   </tr>
                 
               </tbody>: <tbody className="divide-y divide-gray-200 bg-white">
                    {transferRequst?.slice((currentPage - 1) * 60, currentPage * 60).map((person) => (
                      <tr key={person.name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0"  onClick={() => handleViewDetails(person)
                            }>
                            <PrivateImage imageUrl={ person?.user?.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/> 

                            </div>
                            <div className="ml-4">
                              <div
                                className="font-medium text-gray-900 cursor-pointer"
                                onClick={() => handleViewDetails(person)}
                              >
                                {person.delegate_first_name +
                                  " " +
                                  person.delegate_last_name}
                              </div>
                              <div
                                className="text-gray-500 cursor-pointer"
                                onClick={() => handleViewDetails(person)}
                              >
                            {localStorage.role=="Operations Team"? null:person.delegate_email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {moment(person.creation).format("LL")}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {person.event_name}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {person.transfer_to_event_name}
                          </div>
                          <div className="text-gray-500"></div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.delta}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.deduction}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.approval_status == "Pending" ? (
                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-500">
                              {person.approval_status}
                            </span>
                          ) : person.approval_status == "Approved" ? (
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {person.approval_status}
                            </span>
                          ) : (
                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                              {person.approval_status}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>}
                </table></div>}
              
            </div>
          </div>
        </div>
      </div>
      {isNotFoundName? null: <div className="flex items-center justify-between mt-7 bg-white px-3 py-3 sm:px-3  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-start sm:flex-col gap-y-1 sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 60 + 1}
                  </span>{" "}
                  to <span className="font-medium">{Math.min(((currentPage - 1) * 60 + 1) + 60, entriesLength)}</span> of{" "}
                  <span className="font-medium">{entriesLength}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm flex-wrap gap-y-1"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                  {/* [1,2,3,4,5,6,7,8,9,0,12,13,1114,14,14,242,3,3,45,54,65,54 ,64 ,45,6,56,55,66,77,88,99,56,56,7567,8,7,8,7,9,89,89090] */}
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-2 py-1.5 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                      onSubmit={StatusUpdate.handleSubmit}
                    >
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Conference Request
                            </Dialog.Title>

                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* Main */}
                        <div className="divide-y divide-gray-200">
                          <div className="pb-6">
                            <div className="h-24  sm:h-20 lg:h-28 bg-blue-500" />
                            <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                              <div>
                                <div className="-m-1 flex">
                                  <div className="inline-flex overflow-hidden rounded-lg ">
                                  <PrivateImage imageUrl={ transforRequstDetail?.user.user_image} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                                   
                                  </div>
                                </div>
                              </div>
                              <div className="mt-6 sm:ml-4 sm:flex-1">
                                <div>
                                  <div className="flex items-center">
                                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                      {transforRequstDetail?.delegate_first_name +
                                        " " +
                                        transforRequstDetail?.delegate_last_name}
                                    </h3>
                                  </div>
                                  {localStorage.role=="Operations Team"? null:  <p className="text-sm text-gray-500">
                                    {transforRequstDetail?.delegate_email}
                                  </p>}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="px-4 py-5 sm:px-0 sm:py-0">
                            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                              <div className="sm:flex lg:items-center sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  From
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {transforRequstDetail?.event_name}
                                </dd>
                              </div>

                              <div className="sm:flex lg:items-center sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  To
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {transforRequstDetail?.transfer_to_event_name}
                                </dd>
                              </div>

                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Days
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {transforRequstDetail?.delta}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Deducation
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {transforRequstDetail?.deduction}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  First Receipt
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {transforRequstDetail?.first_receipt == "" ||
                                  transforRequstDetail?.first_receipt ==
                                    null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    <a
                                      href={
                                        "https://dash.bestdiplomats.org" +
                                        transforRequstDetail?.first_receipt
                                      }
                                      target="_blank"
                                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                      {" "}
                                      Open
                                      <svg
                                        className="w-5 h-5 ml-1"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                        ></path>
                                      </svg>
                                    </a>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Second Receipt
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {transforRequstDetail?.second_receipt == "" ||
                                  transforRequstDetail?.second_receipt ==
                                    null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    <a
                                      href={
                                        "https://dash.bestdiplomats.org" +
                                        transforRequstDetail?.second_receipt
                                      }
                                      target="_blank"
                                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                      {" "}
                                      Open
                                      <svg
                                        className="w-5 h-5 ml-1"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                        ></path>
                                      </svg>
                                    </a>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Appeal Attachment
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {transforRequstDetail?.appeal_attachment ==
                                    "" ||
                                  transforRequstDetail?.appeal_attachment ==
                                    null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    <a
                                      href={
                                        "https://dash.bestdiplomats.org" +
                                        transforRequstDetail?.appeal_attachment
                                      }
                                      target="_blank"
                                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                      {" "}
                                      Open
                                      <svg
                                        className="w-5 h-5 ml-1"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                        ></path>
                                      </svg>
                                    </a>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Appeal Reason
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {transforRequstDetail?.appeal_reason == "" ||
                                  transforRequstDetail?.appeal_reason ==
                                    null ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : (
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                      {transforRequstDetail?.appeal_reason}
                                    </dd>
                                  )}
                                </dd>
                              </div>

                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Requested On
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {moment(
                                    transforRequstDetail?.creation
                                  ).format("LLLL")}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Voucher Code
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <label
                                    htmlFor="voucher_code"
                                    className=" sr-only"
                                  ></label>
                                  <input
                                    type="text"
                                    name="voucher_code"
                                    id="voucher_code"
                                    onChange={StatusUpdate.handleChange}
                                    value={StatusUpdate.values.voucher_code}
                                    className="mt-1 block w-60 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  ></input>
                                </dd>
                              </div>
                              <div className="sm:flex lg:items-center sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Status
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <label
                                    htmlFor="approval_status"
                                    className=" sr-only"
                                  ></label>
                                  <select
                                    type="text"
                                    name="approval_status"
                                    id="approval_status"
                                    // onMouseEnter={(e)=>{handleViewBox(e._targetInst.pendingProps.value)}}
                                    // onMouseLeave={(e)=>{handleViewBox(e._targetInst.pendingProps.value)}}
                                    // onClick={(e)=>{e.nativeEvent.target.__reactProps$brh22sa31pe.value=="Approved" ? handleViewBox("Approved") : e.nativeEvent.target.__reactProps$brh22sa31pe.value=="Rejected" ? handleViewBox("Rejected") : handleViewBox("Pending")}}
                                    // onMouseEnter={(e)=>{console.log(e._targetInst.pendingProps.value)}}
                                    // onMouseLeave={(e)=>{console.log(e._targetInst.pendingProps.value)}}
                                    // onClick={(e) => {
                                    //   if (e.target.value == "Approved") {
                                    //     setIsApproved(true);
                                    //     setIsRejected(false);
                                    //   } else if (e.target.value == "Rejected") {
                                    //     setIsApproved(false);
                                    //     setIsRejected(true);
                                    //   } else {
                                    //     setIsApproved(false);
                                    //     setIsRejected(false);
                                    //   }
                                    // }}
                                    onChange={(e)=>handleViewBox(e.target.value)}
                                    value={status}
                                    className="mt-1 block w-60 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  >
                                    <option value="Approved">Approved</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Appealed">Appealed</option>
                                    <option value="Blocked">Blocked</option>
                                  </select>
                                </dd>
                              </div>
                              {isApproved ? (
                                <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Approval Comment
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                    <div className="sm:col-span-2">
                                      <textarea
                                        id="approval_comment"
                                        name="approval_comment"
                                        rows={3}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        onChange={StatusUpdate.handleChange}
                                        value={
                                          StatusUpdate.values.approval_comment
                                        }
                                      />
                                    </div>
                                  </dd>
                                </div>
                              ) : null}
                              {isRejected ? (
                                <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Rejection Comment
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                    <div className="sm:col-span-2">
                                      <textarea
                                        id="rejection_comment"
                                        name="rejection_comment"
                                        rows={3}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        onChange={StatusUpdate.handleChange}
                                        value={
                                          StatusUpdate.values.rejection_comment
                                        }
                                      />
                                    </div>
                                  </dd>
                                </div>
                              ) : null}

                              <div className="flex-shrink-0 flex justify-end border-t border-gray-200 px-3 py-3 sm:px-9">
                                {statusUpdateSuc ? (
                                  <button
                                    type=""
                                    className="group  flex w-80 justify-center rounded-md border border-transparent bg-green-500 py-2 px-4 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                  >
                                    Data Updated Successfully
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="group  flex w-28 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default Registrationstatus;
