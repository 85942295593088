import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { useState, useEffect } from "react";
import axios from "axios";
import { Fragment } from "react";
import { Dialog, Menu, Switch, Transition } from "@headlessui/react";

import { Editor } from "@tinymce/tinymce-react";
import {
  ArrowTopRightOnSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";
import { EllipsisVerticalIcon, CheckIcon,ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import { Toaster, toast } from 'sonner'
import PrivateImage from '../PrivateImage/PrivateImage';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DiplomaticExperts = () => {
  // Configrations
  var userEmail;
  var Role;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
   Role=localStorage.role
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [countryRep, setCountryRep] = useState([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [instagramprofile, setInstagramProfile] = useState();
  const [facebookprofile, setFacebookProfile] = useState();
  const [twitterprofile, setTwitterProfile] = useState();
  const [linkedinprofile, setLinkedinProfile] = useState();
  const [businessperson, setBusinessPerson] = useState(false);
  const [student, setStudent] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [totalAmbs, setTotalAmbs] = useState();
  const [isNotFoundAmb,setIsNotFoundAmb ] = useState(false);
  const [previousEditorValue, setPreviousEditorValue] = useState(null);
  const [updateEditorValue, setUpdateEditorValue] = useState(previousEditorValue);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const tableRef = useRef(null);
  const [inviteFilter, setInviteFilter] = useState([]);
  const [bio, setBio] = useState(true);

  useEffect(() => {
    setLoadingSkeleton(true)
    const fetchAmbData = async () => {
      try {
        await axios
          .get(`${window.$domain}/api/method/get-diplomatic-experts?filters=[${inviteFilter}]`,config)
          .then((response) => {
            setCountryRep(response.data.data);
            setpageNumber([]);
            // console.log("expert", response.data.data);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 40);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              console.log(pageNumbers);
            }
            // console.log(response.data.data);
            setTotalAmbs(response.data.data.length);
            setLoadingSkeleton(false)
            setIsNotFoundAmb(false);
            if(response.data.data.length==[]){
              setLoadingSkeleton(true)
              toast.error('No Data Found')
              setIsNotFoundAmb(true);
            }
          });
      } catch (e) {
        console.log(e);
        if (e.code == "ERR_NETWORK") {
          toast.error('No Data Found')
          setIsNotFoundAmb(true);
          
        }
      }
    };
    fetchAmbData();
  }, [submitDone, inviteFilter]);
 
  
  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleClick = (number) => {
    setCurrentPage(number);
    scroll();
    scrollToTop()
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop()
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop()
      }
    }
  };

  const [single, setSingleAmbs] = useState([]);

  const fetchambassdor = async (email) => {
    const result = await axios
      .get(
        `${window.$domain}/api/method/get-diplomatic-expert?email=${email}`,config
      )
      .then((res) => {
        setSingleAmbs(res.data.data);
        setPreviousEditorValue(res.data.data?.application_feedback)
        // console.log("single",res.data.data);
        if (res.data.data.bio == null || res.data.data.bio == "") {
          setBio(true);
        } else {
          setBio(false);
        }
        if (
          res.data.data.instagram_profile == null ||
          res.data.data.instagram_profile == ""
        ) {
          setInstagramProfile(true);
        } else {
          setInstagramProfile(false);
        }
        if (
          res.data.data.facebook_profile == null ||
          res.data.data.facebook_profile == ""
        ) {
          setFacebookProfile(true);
        } else {
          setFacebookProfile(false);
        }
        if (
          res.data.data.linkedin_profile == null ||
          res.data.data.linkedin_profile == ""
        ) {
          setLinkedinProfile(true);
        } else {
          setLinkedinProfile(false);
        }
        if (
          res.data.data.twitter_profile == null ||
          res.data.data.twitter_profile == ""
        ) {
          setTwitterProfile(true);
        } else {
          setTwitterProfile(false);
        }

        if (res.data.data.occupation == "Business Person") {
          // console.log("BP:", true);
          setBusinessPerson(true);
        }
        if (res.data.data.occupation == "Student") {
          // console.log("S:", true);
          setStudent(true);
        }
        if (res.data.data.occupation == "Employee") {
          // console.log("Ep:", true);
          setEmployee(true);
        }
      })
      .catch((e) => console.log(e));
  };

  const applyFiltersInvitation = useFormik({
    initialValues: {},
    onSubmit: (values) => {
    setInviteFilter([])
      //  Payment Package
      if (values.profile_status) {
        if (values.profile_status != "None") {
          // console.log(values.profile_status)
          // setInviteFilter((prevState)=>{[prevState+`[["approval_status","=","${values.status}"]]`]});
          setInviteFilter( `["profile_status","=","${values.profile_status}"]`)
        } else {
          setInviteFilter([]);
        }
      }
    },
    enableReinitialize: true,
  });
  const handleViewDetails = async (email) => {
    setStudent(false);
    setBusinessPerson(false);
    setEmployee(false);
    await fetchambassdor(email);

    setOpen(true);
    // console.log(email);
  };
  const handleChangeupdate = (content, editor) => {
    setUpdateEditorValue(content);
    console.log("Content was updated:", content);
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const formik = useFormik({
    initialValues: {
      status: single?.profile_status,
      project_description: single?.status_review,
      interview_instructions: single?.interview_instructions,
      interview_schedule_date: single?.interview_schedule_date,
    },
    onSubmit: (values) => {
      var jsontoPass = {
        profile_status: values.status,
        status_review: values.project_description,
        // interview_instructions: values.interview_instructions,
        // interview_schedule_date: values.interview_schedule_date,
        application_feedback:updateEditorValue
      };
      const fetchAmbData = async (jsontoPass) => {
        try {
          await axios
            .put(
              `${window.$domain}/api/resource/Diplomatic Expert Profile/${single.name}`,
              jsontoPass
            )
            .then((res) => {
              setIsSuccess(true);
              setSubmitDone(!submitDone);
              toast.success("Profile Successfully Updated ");
              setTimeout(() => {
                setIsSuccess(false);
              }, 2000);
            });
        } catch (e) {
            toast.error("Profile Updated Failed");
          console.log(e);
        }
      };
      fetchAmbData(jsontoPass);
    },

    enableReinitialize: true,
  });
  const [enabledban, setEnabledBan] = useState(false)
  
 const handleToggleShadowBan=(person)=>{
  if(enabledban===true){
    setEnabledBan(false)
    const fetchcategory = async () => {
      var paymentPackeg = {
        shadow_ban: 0
      }
      // console.log("paymentPackeg true",paymentPackeg);
      try {
        await axios
          .put(
            `${window.$domain}/api/resource/Diplomatic Expert Profile/${single?.name}`,
            paymentPackeg,
            config
          )
          .then((res) => {
            // console.log("updatae",res);
            toast.success('Shadow ban Disable successfully')
            setSubmitDone(!submitDone);
          });
      } catch (e) {
        console.log(e);
        toast.error('Failed to Update')
      }
    };
  
    fetchcategory();
    // console.log("true=",enabledban);
  }else{
    setEnabledBan(true)
    const fetchcategory = async () => {
      var paymentPackeg = {
        shadow_ban: 1
      }
      // console.log("paymentPackeg false",paymentPackeg);
      try {
        await axios
          .put(
            `${window.$domain}/api/resource/Diplomatic Expert Profile/${single?.name}`,
            paymentPackeg,
            config
          )
          .then((res) => {
            // console.log("updatae",res);
            toast.success('Shadow ban Enable successfully')
            setSubmitDone(!submitDone);
          });
      } catch (e) {
        console.log(e);
        toast.error('Failed to Update')
      }
    };
  
    fetchcategory();
    // console.log("false=",enabledban);
  }
   }
  const [popUPImage, setPopUPImage] = useState(false);
  const [vedioLink, setVedioLink] = useState(false);
  const handleVedioLink = (link) => {
    setVedioLink(window.$domain+link);
    // console.log("window.$domain"+link);
    setPopUPImage(true);
  };
  return (
    <>

<div className="mt-4 px-4 flex justify-between items-center   sm:ml-1 sm:flex-none">
{Role=="Director Ambassador"&&<h1 className=" text-base  font-semibold leading-6 text-gray-800">Diplomatic Experts</h1>}
<Toaster position='bottom-left' richColors />
          <form onSubmit={applyFiltersInvitation.handleSubmit}>
          <div className="mt-4 mb-2 flex justify-end gap-3 items-center  sm:mt-0 sm:ml-2 sm:flex-none">
          {/* {loadingSkeleton ?<><Skeleton width={80} height={40} /> <Skeleton width={80} height={40} /></>: <>  */}
            <label htmlFor="profile_status" className="sr-only"></label>
            <select
              id="profile_status "
              name="profile_status"
              className=" block w-60  rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              onChange={applyFiltersInvitation.handleChange}
              value={applyFiltersInvitation.values.profile_status              }
            >
              <option value="None">All</option>
              <option value="Approved">Approved</option>
              <option value="In Review">In Review</option>
              <option value="Rejected">Rejected</option>
            </select>
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Apply Filter
            </button>
            {/* </>} */}
          </div>
          </form>
        </div>
        <div className="py-1 px-4 lg:mr-2 flex items-center justify-end sm:mt-0 sm:ml-1 sm:flex-none">
          <p className=" font-semibold flex   items-center text-gray-700">
          { loadingSkeleton ?<Skeleton width={40} height={20} /> :<> Diplomatic Experts: {totalAmbs} </> }
          </p>
        </div>
    <div className="px-2 sm:px-4   lg:px-4">
   
      <div className=" flex flex-col">
        

        <div ref={tableRef}  className="my-2 -mx-4 overflow-x-auto max-h-screen sm:-mx-4 lg:-mx-4">
          <div ref={tableRef} className="inline-block overflow-auto min-w-full py-1 align-middle md:px-3 lg:px-3">
          
            { isNotFoundAmb ? <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
 </svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>:   <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"> <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-stone-100">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Occupation
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Contact Number
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Country
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                {  loadingSkeleton? <tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     
                       <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                     </td>
                    
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td> 
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                    

                     
                   </tr>
                 
               </tbody>: <tbody className="divide-y divide-gray-200 bg-white">
                  {countryRep?.slice((currentPage - 1) * 40, currentPage * 40).map((diplomatic) => (
                    <tr key={diplomatic.name} >
                        
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                            onClick={() => {
                              handleViewDetails(diplomatic.user.email);
                              diplomatic?.shadow_ban==1?setEnabledBan(true):setEnabledBan(false)
                            }}
                          >
                            <PrivateImage imageUrl={diplomatic.user.user_image} className={"h-14 w-14 cursor-pointer rounded-full"}/> 
                           
                          </div>
                          <div className="ml-4">
                            <div
                              onClick={() => {
                                handleViewDetails(diplomatic.user.email);
                                diplomatic?.shadow_ban==1?setEnabledBan(true):setEnabledBan(false)
                              }}
                              className="font-medium cursor-pointer text-gray-900"
                            >
                              {diplomatic.user.first_name} {diplomatic.user.last_name}
                            </div>
                            <div className="text-gray-500">
                              {diplomatic.user.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">{diplomatic.occupation}</div>
                        <div className="text-gray-500"></div>
                      </td>
                      {diplomatic.contact_number == null||diplomatic.contact_number == "" ? (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            {" "}
                            Missing
                          </div>
                          <div className="text-gray-500"></div>
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {" "}
                            {diplomatic.ambassador_country_code}
                            {diplomatic.contact_number}
                          </div>
                          <div className="text-gray-500"></div>
                        </td>
                      )}

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {diplomatic.address_country == "" ||
                        diplomatic.address_country == null ? (
                          <div className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            Missing
                          </div>
                        ) : (
                          <div className="text-gray-900">
                            {" "}
                            {diplomatic.address_country}
                          </div>
                        )}{" "}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {diplomatic.profile_status==null||diplomatic.profile_status==""?  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            Missing
                          </span>:diplomatic.profile_status == "Approved" ? (
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {diplomatic.profile_status}
                          </span>
                        ) : diplomatic.profile_status == "In Review" ? (
                          <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-600">
                            {diplomatic.profile_status}
                          </span>
                        ) : (
                          <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            {diplomatic.profile_status}
                          </span>
                        )}
                      </td>
                     
                    </tr>
                  ))}
                </tbody>}
              </table>  </div>}
          
          </div>
        </div>
      </div>
     
      {isNotFoundAmb ? null: <div className="flex items-center justify-between mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 40 + 1}
                  </span>{" "}
                  to <span className="font-medium">{currentPage * (totalAmbs % 40)}</span> of{" "}
                  <span className="font-medium">{totalAmbs}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}
          <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Profile
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24 bg-blue-500 sm:h-20 lg:h-28" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg ">
                                <PrivateImage imageUrl={ single?.user?.user_image} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                           
                                  
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {single?.user?.first_name}{" "}
                                    {single?.user?.last_name}
                                  </h3>
                                  {/* <span className="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400">
                                    <span className="sr-only">Online</span>
                                  </span> */}
                                </div>
                                <p className="text-sm text-gray-500">
                                  {single?.user?.email}
                                </p>
                              </div>
                              <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                                <div className="ml-3 inline-flex sm:ml-0">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left"
                                  >
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                href="#"
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                View profile
                                              </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                href="#"
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                Copy profile link
                                              </a>
                                            )}
                                          </Menu.Item>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            {bio ? (
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Bio
                                </dt>
                                <dd className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Missing
                                </dd>
                              </div>
                            ) : (
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Bio
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {single.bio}
                                </dd>
                              </div>
                            )}


                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Previously Attended any Conferences
                               </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {single.previously_attended_any_conferences==null||single.previously_attended_any_conferences==""? <dd className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Missing
                                </dd>:single.previously_attended_any_conferences==1 ? (
                                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                    Yes
                                  </span>
                                ) : single.previously_attended_any_conferences ==0 && (
                                  <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-600">
                                    No
                                  </span>
                                ) }
                              </dd>
                            </div>
                            {single.previously_attended_conference_experience_details==null||single.previously_attended_conference_experience_details==""? (
                              <div className="sm:flex flex items-center gap-3 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium mr-6 text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Previously Attended Conference Experience Details
                                </dt>
                                <dd className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Missing
                                </dd>
                              </div>
                            ) : (
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Previously Attended Conference Experience Details
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {single.previously_attended_conference_experience_details}
                                </dd>
                              </div>
                            )}
                          
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Previously Attended Best Diplomats Conference
                               </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {single.previously_attended_best_diplomats_conference==null||single.previously_attended_best_diplomats_conference==""? <dd className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Missing
                                </dd>:single.previously_attended_best_diplomats_conference==1 ? (
                                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                    Yes
                                  </span>
                                ) : single.previously_attended_best_diplomats_conference==0 && (
                                  <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-600">
                                    No
                                  </span>
                                ) }
                              </dd>
                            </div>
                            {single.previously_best_diplomats_experience_details==null||single.previously_best_diplomats_experience_details==""? (
                              <div className="sm:flex flex items-center gap-3 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium mr-6 text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Previously Best Diplomats Experience Details
                                </dt>
                                <dd className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Missing
                                </dd>
                              </div>
                            ) : (
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Previously Best Diplomats Experience Details
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {single.previously_best_diplomats_experience_details}
                                </dd>
                              </div>
                            )}
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Profile Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {single.profile_status == "Approved" ? (
                                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                    {single.profile_status}
                                  </span>
                                ) : single.profile_status == "In Review" ? (
                                  <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-600">
                                    {single.profile_status}
                                  </span>
                                ) : (
                                  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                    {single.profile_status}
                                  </span>
                                )}
                              </dd>
                            </div>

                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Contact Number
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {single.ambassador_country_code}
                                {single.contact_number}
                              </dd>
                            </div>
                            {businessperson && (
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Occupation
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {single.occupation}
                                </dd>
                              </div>
                            )}
                            {employee && (
                              <>
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Occupation
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                    {single.occupation}
                                  </dd>
                                </div>
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Job Role
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                    {single.job_role}
                                  </dd>
                                </div>
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Company
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                    {single.company}
                                  </dd>
                                </div>
                              </>
                            )}
                            {student && (
                              <>
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Occupation
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                    {single.occupation}
                                  </dd>
                                </div>
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Educational Institution
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                    {single.educational_institution}
                                  </dd>
                                </div>
                              </>
                            )}

                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Address
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <p>
                                  {single.address_street}, {single.address_city}
                                  , {single.address_state},{" "}
                                  {single.address_zip_code},{" "}
                                  {single.address_country}
                                </p>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-4">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-60 sm:flex-shrink-0 lg:w-60">
                                  Shadow Ban
                                </dt>
                                  <Switch
      checked={enabledban}
      onChange={() => handleToggleShadowBan(single?.shadow_ban)}
      className={classNames(
        enabledban ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          enabledban ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      >
        <span
          className={classNames(
            enabledban ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        >
          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={classNames(
            enabledban ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        >
          <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
          </div>
                            <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Twitter Profile
                              </dt>
                              {twitterprofile ? (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a className="">
                                    <p className="flex flex-row  gap-1 justify-center items-center rounded-full  bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                      {/* <ArrowTopRightOnSquareIcon className="w-5 h-5" /> */}
                                    </p>
                                  </a>
                                </dd>
                              ) : (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a
                                    className=""
                                    href={single.twitter_profile}
                                    target="_blank"
                                  >
                                    <p className="flex flex-row  gap-1 justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  rounded">
                                      Check Profile
                                      <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                    </p>
                                  </a>
                                </dd>
                              )}
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Facebook Profile
                              </dt>
                              {facebookprofile ? (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a className="">
                                    <p className="flex flex-row  gap-1 justify-center items-center  rounded-full  bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                      {/* <ArrowTopRightOnSquareIcon className="w-5 h-5" /> */}
                                    </p>
                                  </a>
                                </dd>
                              ) : (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a
                                    className=""
                                    href={single.facebook_profile}
                                    target="_blank"
                                  >
                                    <p className="flex flex-row  gap-1 justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  rounded">
                                      Check Profile
                                      <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                    </p>
                                  </a>
                                </dd>
                              )}
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Instagram Profile
                              </dt>
                              {instagramprofile ? (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a className="">
                                    <p className="flex flex-row  gap-1 justify-center items-center rounded-full   bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                      {/* <ArrowTopRightOnSquareIcon className="w-5 h-5" /> */}
                                    </p>
                                  </a>
                                </dd>
                              ) : (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a
                                    className=""
                                    href={single.instagram_profile}
                                    target="_blank"
                                  >
                                    <p className="flex flex-row  gap-1 justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  rounded">
                                      Check Profile
                                      <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                    </p>
                                  </a>
                                </dd>
                              )}
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Linkedin Profile
                              </dt>

                              {/* {(single.linkedin_profile==null||single.linkedin_profile=="") */}
                              {linkedinprofile ? (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a className="">
                                    <p className="flex flex-row  gap-1 justify-center items-center rounded-full  bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                      {/* <ArrowTopRightOnSquareIcon className="w-5 h-5" /> */}
                                    </p>
                                  </a>
                                </dd>
                              ) : (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a
                                    className=""
                                    href={single.linkedin_profile}
                                    target="_blank"
                                  >
                                    <p className="flex flex-row  gap-1 justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  rounded">
                                      Check Profile
                                      <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                    </p>
                                  </a>
                                </dd>
                              )}
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Portfolio Video Link
                              </dt>
                              {single.portfolio_video_link == "" ||
                              single.portfolio_video_link == null ? (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a className="">
                                    <p className="flex flex-row  gap-1 justify-center items-center rounded-full  bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                      {/* <ArrowTopRightOnSquareIcon className="w-5 h-5" /> */}
                                    </p>
                                  </a>
                                </dd>
                              ) : (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a
                                    href={single.portfolio_video_link}
                                    target="_blank"
                                    className=""
                                  >
                                    <p className="flex flex-row  gap-1 justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  rounded">
                                      Check Profile
                                      <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                    </p>
                                  </a>
                                </dd>
                              )}
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Portfolio Video
                              </dt>
                              {single.portfolio_video == "" ||
                              single.portfolio_video == null ? (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <a className="">
                                    <p className="flex flex-row  gap-1 justify-center items-center rounded-full  bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                      {/* <ArrowTopRightOnSquareIcon className="w-5 h-5" /> */}
                                    </p>
                                  </a>
                                </dd>
                              ) : (
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <button onClick={()=>{handleVedioLink(single.portfolio_video)}} className="flex flex-row  gap-1 justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  rounded">
                                      View Video
                                    </button>
                                  
                                </dd>
                              )}
                            </div>
                            <form
                              onSubmit={formik.handleSubmit}
                              className="sm:divide-y sm:divide-gray-200"
                            >
                              {/* <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Interview Schedule Date
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <label
                                    htmlFor="interview_schedule_date"
                                    className="sr-only"
                                  ></label>
                                  <input
                                    type="datetime-local"
                                    className="block w-full rounded-md border-gray-300 py-2 pl-2 pr-2 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    id="interview_schedule_date"
                                    name="interview_schedule_date"
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.interview_schedule_date
                                    }
                                  />
                                </dd>
                              </div> */}
                              {/* <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Interview Instructions
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <div className="sm:col-span-2">
                                    <textarea
                                      id="interview_instructions"
                                      name="interview_instructions"
                                      rows={3}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      onChange={formik.handleChange}
                                      value={
                                        formik.values.interview_instructions
                                      }
                                    />
                                  </div>
                                </dd>
                              </div> */}

                              <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Profile Status
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <label
                                    htmlFor="status"
                                    className="sr-only"
                                  ></label>
                                  <select
                                    id="status"
                                    name="status"
                                    className=" block w-56 rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.status}
                                  >
                                    <option value="Approved">Approved</option>
                                    <option value="In Review">In Review</option>
                                    <option value="Rejected">Rejected</option>
                                  </select>
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5 flext  items-center ">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Profile Review Comments
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:mt-0 sm:ml-6 ">
                                  <div className="sm:col-span-2">
                                    <textarea
                                      id="project_description"
                                      name="project_description"
                                      rows={3}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      onChange={formik.handleChange}
                                      value={formik.values.project_description}
                                    />
                                  </div>
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5 flex flex-col  ">
                                <dt className="text-sm mb-1  font-medium text-gray-500 sm:w-60 sm:flex-shrink-0 lg:w-60">
                                Application Feedback
                                </dt>
                               
                                  <div className="w-full mt-1">
                                  <Editor
                                      initialValue={previousEditorValue}
                                      apiKey="148jqff4vmvlainlcirs1406j5xa3v54yibpjnzm7xh0etg6"
                                      init={{
                                        height: "400",
                                        borderColor: "20px",
                                        width:"600",
                                        branding: false,
                                        menubar: true,
                                        plugins:
                                          "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link  template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                        toolbar:
                                          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                        image_advtab: true,
                                      }}
                                      onEditorChange={handleChangeupdate}
                                    />
                                  </div>
                            
                              </div> 
                             <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                <button
                                  type="submit"
                                  className="mr-3 flex-row items-center  rounded inline-flex justify-center  border border-transparent  bg-blue-500 hover:bg-blue-700 py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                  Update Profile
                                </button>
                              </div>
                             
                            </form>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Popup for Music Link */}
      <Transition.Root show={popUPImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setPopUPImage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4  shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex justify-center items-center bg-orange-200">
                    <ReactPlayer url={vedioLink} playing controls={true}/>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                     
                    <button
                      type="button"
                      className="mt-3 inline-flex w-40 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setPopUPImage(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div> 
    </>
  );
};




export default DiplomaticExperts