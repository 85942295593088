import React, { useEffect, useState } from "react";
import "./Navbar.css"
import { Link, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../../img/Logo-08.png";
import LogoMini from "../../img/small-logo.png";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset, login } from "../../features/auth/authSlice";
import axios from "axios";
import * as qs from "qs";
const navigation = [
  // { name: "Events", href: "/", current: true },
  // { name: "Login", href: "/logincomp", current: true },
  // { name: "Signup", href: "/invitelogin", current: true },
  // { name: "Calendar", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const nevigate = useNavigate();
  const dispatch = useDispatch();

const [userName,setUserName]=useState("")

  const onLogout = () => {
    setAvatar("/files/navAvatar.png");
    setUserName(null);
    dispatch(logout());
    dispatch(reset());
    nevigate("/");
  };


  const [avatar,setAvatar]=useState('/files/navAvatar.png');



  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (localStorage.user) {
      dispatch(login(localStorage.user));
      // window.location.reload(false)
    }
  }, []);

  async function reFreshToken(){
    var rTokenParse= JSON.parse(localStorage.user)
    var rToken= rTokenParse.refresh_token
    try{
    const response = await axios.post(
      "https://dash.bestdiplomats.org/api/method/frappe.integrations.oauth2.get_token",
      qs.stringify({
        grant_type: "refresh_token",
        refresh_token: rToken,
        client_id: window.$ClientID,
        scope: "all openid",
      })
    );
    console.log("Refresh>>",response);

    if(response.status===200){
      var timeIn = Date.now();
      var userAfterRefresh=response.data;
      var fetchIdTokenBeforeParse= localStorage.user;
      var fetchIdTokenAfterParse= JSON.parse(fetchIdTokenBeforeParse);
      var fetchIdToken= fetchIdTokenAfterParse.id_token;
      userAfterRefresh.id_token=fetchIdToken;
    localStorage.setItem("user", JSON.stringify(userAfterRefresh));
    localStorage.setItem("date", timeIn);
    // dispatch(login(JSON.stringify(userAfterRefresh)));
    }else{
      localStorage.removeItem("user");
      localStorage.removeItem("date");
      dispatch(reset());
      nevigate("/logincomp");
    }
  }
  catch(err){
console.log(err);
  }
   
    

  }

  useEffect(() => {

    // -------- Access Token Expiry Check Start ------
    if(localStorage.user){
      var dateInInt= parseInt(localStorage.date)
      var dateInIntWithExpiry=dateInInt+3600000;
      var timeNow= Date.now();
      if (timeNow<dateInIntWithExpiry) {
        dispatch(login(localStorage.user));
      }else{
        reFreshToken()
      }
      // -------- Access Token Expiry Check End ------
    }



  }, []);

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  useEffect(()=>{

    if(localStorage.user_name){
      setUserName(localStorage.getItem("user_name"))
      // setAvatar(localStorage.picture)
    }
    },[user])


    
  

  return (
    <Disclosure as="nav" className="color">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                {/* <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button> */}
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    {" "}
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={LogoMini}
                      alt="Your Company"
                    />
                  </Link>
                  <Link to="/">
                    {" "}
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src={Logo}
                      alt="Your Company"
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:block mt-1">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link to={item.href}>
                        {" "}
                        <a
                          key={item.name}
                          className={classNames(
                            item.current
                              ? "colorButton text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <button
                  type="button"
                  className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                
                

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div className="flex">
                  
                  
                    <Menu.Button type="button" className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={window.$domain + "/files/navAvatar.png"}
                        alt=""
                      />
                    </Menu.Button>
                    {userName ? (<>
                     
                    <Menu.Button
                  type="button"
                  className="p-1 ml-2 text-white hover:text-white hidden lg:block"
                >
                  <span>{userName}</span>
                  
                </Menu.Button>
                </>
                  ): (
                    null
                  )}
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {user ? (
                        <>
                       {localStorage?.role==="Operations Member" || localStorage?.role==="Operations Team" ? null :   <Menu.Item>
                            {({ active }) => (
                              <Link to="/profile">
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Your Profile
                                </a>
                              </Link>
                            )}
                          </Menu.Item>}
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                onClick={onLogout}
                              >
                                Logout
                              </a>
                            )}
                          </Menu.Item>
                        </>
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <>
                            <Link to="/logincomp">
                            <a
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                             
                            >
                              Login
                            </a>
                            </Link>
                            {/* <Link to="/invitelogin">
                            <a
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                             
                            >
                              Signup
                            </a>
                            </Link> */}
                            </>
                          )}
                        </Menu.Item>
                      )}

                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item> */}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
