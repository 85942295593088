import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { PDFDocument, rgb } from 'pdf-lib';
import BlankCertificate from '../../img/Certificatenew.png'
import axios from 'axios';
import fontkit from '@pdf-lib/fontkit'
import FontAbhaya from '../../AbhayaLibre-SemiBold.ttf'
import RobotoBold from '../../Roboto-Bold.ttf'
import AbhayaRegular from '../../AbhayaLibre-Regular.ttf'
import AbhayaBold from '../../AbhayaLibre-Bold.ttf'
import QRCode from 'qrcode'
import { useSelector } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { Document, Page, Text, View, StyleSheet,PDFViewer, Image, Font,PDFDownloadLink, } from '@react-pdf/renderer';
import moment from "moment";

const Certificate = () => {

    var userEmail;
    var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const [currentEventRegisteration, setCurrentEventRegisteration]=useState(null);
  const {eventId,delegateId}= useParams();
  const [qrUriImageFile,setqrUriImageFile]= useState(null);
  const [fontSize,setFontSize]= useState(32);
  const [eventDetails,setEventDetails]= useState(null);
  const [fullNameTop,setFullNameTop]= useState(230);
  const [lowerViewTop,setLowerViewTop]= useState(0);

    Font.register({
        family:'AbhayaLibreSemiBold',
        src:FontAbhaya
      });
    Font.register({
        family:'RobotoBold',
        src:RobotoBold
      });
    Font.register({
        family:'AbhayaRegular',
        src:AbhayaRegular
      });
    Font.register({
        family:'AbhayaBold',
        src:AbhayaBold
      });
      const styles = StyleSheet.create({
        page: {
          
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
        },viewer: {
          width: 870, 
          height: 690,
        },
        bgimages: {
          height:595,
          width:842,
          position: 'absolute'
        },title:{
            top:15,
            textAlign:'center',
            fontSize:58,
            color:'white',
          fontFamily:'AbhayaLibreSemiBold'
        },participation:{
            top:15,
            textAlign:'center',
            fontSize:21,
            color:'#F3D649',
          fontFamily:'RobotoBold'
        },initialline:{
            letterSpacing:1.5,
            top:210,
            textAlign:'center',
            fontSize:16,
            color:'#000000',
          fontFamily:'AbhayaRegular'
        },showcase:{
            top:250,
            textAlign:'center',
            fontSize:17,
            color:'#000000',
          fontFamily:'AbhayaRegular',
          marginHorizontal:140
        },complete:{
            top:260,
            textAlign:'center',
            fontSize:16,
            color:'#000000',
          fontFamily:'AbhayaRegular'
        },completeDate:{
            top:260,
            textAlign:'center',
            fontSize:16,
            color:'#4c8dcb',
          fontFamily:'AbhayaRegular'
        },completeLocation:{
            top:260,
            textAlign:'center',
            fontSize:16,
            color:'#000000',
          fontFamily:'AbhayaRegular'
        },fullname:{
            top:fullNameTop,
            textAlign:'center',
            fontSize:fontSize,
            color:'#4c8dcb',
          fontFamily:'AbhayaRegular'
        },headNameRight:{
            top:321,
            textAlign:'center',
            fontSize:16,
            color:'#4c8dcb',
          fontFamily:'AbhayaBold',
          
        },headNameLeft:{
            top:321,
            textAlign:'center',
            fontSize:16,
            color:'#4c8dcb',
          fontFamily:'AbhayaBold',
          marginLeft:18
        },headPositionRight:{
            top:318,
            textAlign:'center',
            fontSize:12,
            color:'#000000',
          fontFamily:'RobotoBold',
          marginRight:45
          
        },headPositionLeft:{
            top:318,
            textAlign:'center',
            fontSize:12,
            color:'#000000',
          fontFamily:'RobotoBold',
          marginLeft:55
        },lowerView:{
            top:lowerViewTop
        },
        QRImage:{
            top:504,
            left:390,
            position:'absolute',
            width:75}
        
      });

        // States
       
        const generateQR = async text => {
            try {
              return await QRCode.toDataURL(text,{
                color: {
                  dark: '#4c8dcb',  // Blue dots
                  light: '#0000' // Transparent background
                }
              })
              
            } catch (err) {
              console.error(err)
            }
          } 
        
          //   Get User Registeration

      useEffect(() => {
        const fetchEventRegisteration = async () => {
            try {
              
              const result = await axios
                .get(
                  `https://dash.bestdiplomats.org/api/method/get-registration?event=${eventId}&delegate=${delegateId}`,config
                )
                .then((response) => {
                    setCurrentEventRegisteration(response.data.data)
                //   console.log(response.data.data);
                  if ((response.data.data.delegate_profile.user.first_name+response.data.data.delegate_profile.user.last_name).length > 25) {
                    setFontSize(32)
                    setFullNameTop(245)
                    setLowerViewTop(20)
                  }else{
                    setFontSize(48)
                  }
                    
                   generateQR(`https://verify.bestdiplomats.org/verify/${response.data.data.delegate_profile.name}-${eventId}-${response.data.data.name}`).then((res)=>{
                    setqrUriImageFile(res);
                   }); 
                    
                  
                });
              
            } catch (e) {
              
              console.log(e);
            }
          };
          fetchEventRegisteration();
        
      }, []);

    //   Get Event Details
    useEffect(() => {
        const fetchEvent= async () => {
            try {
              
              const result = await axios
                .get(
                    "https://dash.bestdiplomats.org/api/resource/Events/" + eventId
                )
                .then((response) => {
                  // console.log(response.data.data);
                    setEventDetails(response.data.data)
                  
                });
              
            } catch (e) {
              
              console.log(e);
            }
          };
          fetchEvent();
        
      }, []);

  return (
    <div>
    <PDFDownloadLink document={ <Document>
    <Page size={[842,595]} >
    <Image src={BlankCertificate} style={styles.bgimages}></Image>
    <Text style={styles.title}>CERTIFICATE</Text>
    <Text style={styles.participation}>OF PARTICIPATION</Text>
    <Text style={styles.initialline}>THIS CERTIFICATE IS PRESENTED TO</Text>
    <Text style={styles.fullname}>{""}{""+(currentEventRegisteration?.delegate_profile.user.first_name.replace(/\n/g, ""))?.toUpperCase()+" "+(currentEventRegisteration?.delegate_profile.user.last_name.replace(/\n/g, ""))?.toUpperCase()}</Text>
    <View style={styles.lowerView}>
    <Text style={styles.showcase}>For showcasing, an outstanding Negotiation and Leadership skills during the Diplomatic Simulation at {eventDetails?.title}</Text>
    
    <View style={{display: "flex", flexDirection: "row", justifyContent:'center'}}>
    <Text style={styles.complete}>{"Completed this day,"+" "}</Text>
    <Text style={styles.completeDate}>{moment(eventDetails?.end_date).format("D MMMM, YYYY")+","+" "}</Text>
    <Text style={styles.completeLocation}>{''}{eventDetails?.country}</Text>
    </View>

    <View style={{display: "flex", flexDirection: "row", justifyContent:'space-between', marginHorizontal:50}}>
    <Text style={styles.headNameLeft}>Oleksandra Mamchii</Text>
   
    <Text style={styles.headNameRight}>Fawad Ali Langah</Text>
    </View>
    
    <View style={{display: "flex", flexDirection: "row", justifyContent:'space-between', marginHorizontal:26}}>
    <Text style={styles.headPositionLeft}>Academic Lead</Text>
   
    <Text style={styles.headPositionRight}>Director General</Text>
    </View>
    </View>
    <Image source={qrUriImageFile} style={styles.QRImage}></Image>

    </Page>
  </Document>} fileName={`Invitation-${currentEventRegisteration?.delegate_profile.user.full_name}.pdf`}>
  {({ blob, url, loading, error }) =>
        loading ? ( <span className='group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>Loading...</span>) : ( <span className='group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>Download PDF</span>)
      }
  </PDFDownloadLink>

<PDFViewer style={styles.viewer}>
    <Document>
    <Page size={[842,595]} >
    <Image src={BlankCertificate} style={styles.bgimages}></Image>
    <Text style={styles.title}>CERTIFICATE</Text>
    <Text style={styles.participation}>OF PARTICIPATION</Text>
    <Text style={styles.initialline}>THIS CERTIFICATE IS PRESENTED TO</Text>
    <Text style={styles.fullname}>{""}{""+(currentEventRegisteration?.delegate_profile.user.first_name.replace(/\n/g, ""))?.toUpperCase()+" "+(currentEventRegisteration?.delegate_profile.user.last_name.replace(/\n/g, ""))?.toUpperCase()}</Text>
    <View style={styles.lowerView}>
    <Text style={styles.showcase}>For showcasing, an outstanding Negotiation and Leadership skills during the Diplomatic Simulation at {eventDetails?.title}</Text>
    
    <View style={{display: "flex", flexDirection: "row", justifyContent:'center'}}>
    <Text style={styles.complete}>{"Completed this day,"+" "}</Text>
    <Text style={styles.completeDate}>{moment(eventDetails?.end_date).format("D MMMM, YYYY")+","+" "}</Text>
    <Text style={styles.completeLocation}>{''}{eventDetails?.country}</Text>
    </View>

    <View style={{display: "flex", flexDirection: "row", justifyContent:'space-between', marginHorizontal:50}}>
    <Text style={styles.headNameLeft}>Oleksandra Mamchii</Text>
   
    <Text style={styles.headNameRight}>Fawad Ali Langah</Text>
    </View>
    
    <View style={{display: "flex", flexDirection: "row", justifyContent:'space-between', marginHorizontal:25}}>
    <Text style={styles.headPositionLeft}>Academic Lead</Text>
   
    <Text style={styles.headPositionRight}>Director General</Text>
    </View>
    </View>
    <Image source={qrUriImageFile} style={styles.QRImage}></Image>

    </Page>
  </Document>
  </PDFViewer>
    </div>
  )
}

export default Certificate





