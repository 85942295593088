import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import EventSkeleton from "./EventSkeleton";
import moment from "moment";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const people = [
  {
    id: 1,
    name: "Go to Ambassador Dashboard",
    link: "/OperationsDashboard/631ee8473e/ambassador-ops",
    online: true,
  },
  // {
  //   id: 2,
  //   name: "Diplomatic Experts",
  //   link: "diplomaticExperts",
  //   online: true,
  // },
  { id: 2, name: "Country Rep", link: "OperationsDashboard/631ee8473e/country-rep/631ee8473e/representative/631ee8473e", online: true },
  {
    id: 3,
    name: "Go to Ambassador Dashboard",
    link: "/OperationsDashboard/631ee8473e/ambassador-ops",
    online: true,
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Events = () => {
  setTimeout(() => {
    if (localStorage.inviteurl) {
      if (localStorage.redirect == "false") {
        window.location = localStorage.inviteurl;
      }
    }
  }, 3000);

  const [currentUser, setCurrentUser] = useState(null);

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [profileDetails, setprofileDetails] = useState("");
  const [passportDetails, setpassportDetails] = useState("");
  const [flightDetails, setflightDetails] = useState("");
  const [covidDetails, setcovidDetails] = useState("");
  const [visaDetails, setvisaDetails] = useState("");
  const [toShowbanner, setToShowBanner] = useState(false);
  const [selected, setSelected] = useState(people[2]);
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       setLoading(true);
  //       await axios
  //         .get(
  //           "https://dash.bestdiplomats.org/api/method/get-delegate?email=" +
  //             userEmail,
  //           config
  //         )
  //         .then((response) => {
  //           setCurrentUser(response.data.data);
  //           // console.log();
  //           if(response.data.data.user.first_name == '' || response.data.data.user.last_name == '' || response.data.data.user.user_image == '' || response.data.data.user.first_name == null || response.data.data.user.last_name == null || response.data.data.user.user_image == null){
  //             setprofileDetails(' - Profile Details ');
  //             setToShowBanner(true);
  //           }
  //           if(response.data.data.passport_number == '' || response.data.data.passport_upload == '' || response.data.data.passport_expiry == '' || response.data.data.passport_number == null || response.data.data.passport_upload == null || response.data.data.passport_expiry == null){
  //             setpassportDetails(' - Passport Details ');
  //             setToShowBanner(true);
  //           }
  //           if(response.data.data.arrival_flight_time == '' || response.data.data.arrival_flight_code == '' || response.data.data.arrival_flight_time == null || response.data.data.arrival_flight_code == null){
  //             setflightDetails(' - Flight Details ');
  //             setToShowBanner(true);
  //           }
  //           if(response.data.data.covid_vaccination_certificate == '' || response.data.data.covid_vaccination_certificate == null){
  //             setcovidDetails(' - COVID Details ');
  //             setToShowBanner(true);
  //           }
  //           if(response.data.data.visa_scan == '' || response.data.data.visa_scan == null){
  //             setvisaDetails(' - Visa Details ');
  //             setToShowBanner(true);
  //           }

  //         });

  //       setLoading(false);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   fetchUserData();

  // }, []);
  // Functionalities
  useEffect(() => {
    if (localStorage.user) {
      var idToken = JSON.parse(localStorage.user);
      var toDecode = idToken.id_token;
      var Decoded = jwt_decode(toDecode);
      // console.log(Decoded);
      localStorage.setItem("user_email", Decoded.email);
      localStorage.setItem("user_name", Decoded.name);
      localStorage.setItem("picture", Decoded.picture);
      if(Decoded.roles.find((e)=>e==="Chat Department")==="Chat Department"){
        localStorage.setItem("role", "Chat Department");
      }else if(Decoded.roles.find((e)=>e==="Communication Department")==="Communication Department"){
        localStorage.setItem("role", "Communication Department");

      
      }else if(Decoded.roles.find((e)=>e==="Academics Department")==="Academics Department"){
        localStorage.setItem("role", "Academics Department");

      }

      else{
        localStorage.setItem("role", Decoded.roles[0]);
      }
    }
  });

  const [activeEvents, setActiveEvents] = useState([]);
  const [closedEvents, setClosedEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEventsData = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            'https://dash.bestdiplomats.org/api/resource/Events?fields=["*"]'
          )
          .then((response) => {
            setActiveEvents(
              response.data.data.filter(
                (element) => element.status === "Active"
              )
            );
            setClosedEvents(
              response.data.data.filter(
                (element) => element.status === "Closed"
              )
            );
            setUpcomingEvents(
              response.data.data.filter(
                (element) => element.status === "Upcoming"
              )
            );
          });

        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventsData();
  }, []);

  // -------Banner State Handle

  const [bannerShow, setBannerShow] = useState(true);
  const [ambassador, setDirectorAmbassador] = useState(false);
  useEffect(() => {
    if (localStorage.role) {
      if (localStorage.role == "Director Ambassador") {
        setDirectorAmbassador(true);
      }
    }
  }, []);

  function handleBannerCross() {
    setToShowBanner(false);
  }
  //--------End Banner States

  // Styling Components
  const style = {
    card: `relative flex flex-col border-2 border-gray-200 rounded-lg`,
    cardBody: `block flex-grow flex-shrink p-5`,
    cardTitle: `font-medium text-gray-700 mb-3`,
    cardText: `text-gray-500`,
  };

  const inlineStyle = {
    boxShadow: "0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)",
  };

  function Card({ children }) {
    return (
      <div className={style.card} style={inlineStyle}>
        {children}
      </div>
    );
  }

  function CardBody({ children }) {
    return <div className={style.cardBody}>{children}</div>;
  }

  function CardTitle({ children }) {
    return <div className={style.cardTitle}>{children}</div>;
  }

  function CardText({ children }) {
    return <div className={style.cardText}>{children}</div>;
  }

  const ArrowIcon = () => (
    <svg
      className="w-4 h-4 ml-2"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="M12 5l7 7-7 7" />
    </svg>
  );

  const DateIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      {" "}
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
      />{" "}
    </svg>
  );

  //   End Styling Components

  return (
    <>
      {/* Banner */}
      {/* {toShowbanner && (
        <div className="bg-orange-600">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className="flex rounded-lg bg-red-800 p-2">
                  <MegaphoneIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium text-white">
                  <span className="md:hidden">
                    {"Following details are  missing from your profile please fill them ("+ profileDetails + passportDetails + flightDetails+covidDetails+visaDetails+")"} 
                    
                  </span>
                  
                  <span className="hidden md:inline">
                  {"Following details are  missing from your profile please fill them ("+ profileDetails + passportDetails + flightDetails + covidDetails + visaDetails + ")"} 
                  </span>
                </p>
              </div>
              <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                <Link to="/profile">
                  <a style={{"background-color": "#4591DC"}} className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-50">
                    Setup Profile
                  </a>
                </Link>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  onClick={handleBannerCross}
                  className="-mr-1 flex rounded-md p-2 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {/* Banner End */}
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row justify-between">
            <h1 className="text-3xl font-bold tracking-tight text-gray-600">
              Active Conferences
            </h1>
            {ambassador && (
              <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                  <>
                    {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Assigned to</Listbox.Label> */}
                    <div className="relative mt-2">
                      <Listbox.Button className="relative w-96 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          <span
                            aria-label={selected?.online ? "Online" : "Offline"}
                            className={classNames(
                              selected?.online ? "bg-green-400" : "bg-gray-200",
                              "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                            )}
                          />
                          <span className="ml-3 block truncate">
                            {selected?.name}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {people.map((person) => (
                             <Link to={person.link}>
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={person}
                            >
                              {({ selected, active }) => (
                                <>
                                 
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        person.online
                                          ? "bg-green-400"
                                          : "bg-gray-200",
                                        "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                     
                                        {person.name}
                                      
                                      <span className="sr-only">
                                        {" "}
                                        is{" "}
                                        {person.online ? "online" : "offline"}
                                      </span>
                                    </span>
                                  </div>
                                 

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}  
                                </>
                              )}
                            </Listbox.Option>
                            </Link>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            )}
          </div>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          <div className="flex flex-wrap">
            {loading ? (
              <>
                <EventSkeleton />
                <EventSkeleton />
                <EventSkeleton />
                <EventSkeleton />
                <EventSkeleton />
                <EventSkeleton />
              </>
            ) : (
              <>
                {activeEvents?.map((event, index) => {
                  return (
                    <div className="w-full md:w-4/12 mb-6 md:mb-0 md:p-3">
                     

                      <div className="px-3">
                        <div>
                          <img
                            src={window.$domain + event.main_picture}
                            alt=" random imgee"
                            className="w-full object-cover object-center rounded-lg shadow-md max-w-full h-auto md:h-64"
                          />

                          <div className="relative px-4 -mt-16  ">
                            <div className=" p-6 bg-white border border-gray-300 rounded-lg ">
                              <div className="flex items-baseline">
                                <div className="ml-2 text-gray-700 uppercase text-xs font-semibold tracking-wider">
                                  Scheduled:
                                </div>
                                <span className="bg-blue-500 text-white sm:text-sm text-lg px-2 sm:ml-1 inline-block rounded-full  uppercase font-thin-100 sm:font-medium">
                                  <div className="sm:ml-1 ">
                                    {" "}
                                    {moment(event.start_date).format("LL")}
                                  </div>
                                </span>
                              </div>

                              <h4 className="mt-1 text-xl font-semibold uppercase leading-tight  tracking-wide">
                                {event.title}
                              </h4>

                              <div className="mt-1">{event.country}</div>
                              <div className="mt-4">
                                <Link to={"/eventdetail/" + event.name}>
                                  <button className="text-blue-600 text-md font-semibold inline-flex items-center">
                                    View Details
                                    <ArrowIcon />
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {/* /End replace */}
        </div>
        {/* Upcoming Events */}
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl  py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-500">
              Upcoming Conferences
            </h1>
          </div>
        </header>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          <div className="flex flex-wrap">
        {upcomingEvents?.map((event, index) => {
                  return (
                    <div className="w-full md:w-4/12 mb-6 md:mb-0 md:p-3">
                     

                      <div className="px-3">
                        <div>
                          <img
                            src={window.$domain + event.main_picture}
                            alt=" random imgee"
                            className="w-full object-cover object-center rounded-lg shadow-md max-w-full h-auto md:h-64"
                          />

                          <div className="relative px-4 -mt-16  ">
                            <div className=" p-6 bg-white border border-gray-300 rounded-lg ">
                              <div className="flex items-baseline">
                                <div className="ml-2 text-gray-700 uppercase text-xs font-semibold tracking-wider">
                                  Scheduled:
                                </div>
                                <span className="bg-blue-500 text-white sm:text-sm text-lg px-2 sm:ml-1 inline-block rounded-full  uppercase font-thin-100 sm:font-medium">
                                  <div className="sm:ml-1 ">
                                    {" "}
                                    {moment(event.start_date).format("LL")}
                                  </div>
                                </span>
                              </div>

                              <h4 className="mt-1 text-xl font-semibold uppercase leading-tight  tracking-wide">
                                {event.title}
                              </h4>

                              <div className="mt-1">{event.country}</div>
                              <div className="mt-4">
                                <Link to={"/eventdetail/" + event.name}>
                                  <button className="text-blue-600 text-md font-semibold inline-flex items-center">
                                    View Details
                                    <ArrowIcon />
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                </div>
                </div>
        {/* Closed Events */}
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl  py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Closed Conferences
            </h1>
          </div>
        </header>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          <div className="flex flex-wrap">
        {closedEvents?.map((event, index) => {
                  return (
                    <div className="w-full md:w-4/12 mb-6 md:mb-0 md:p-3">
                     

                      <div className="px-3">
                        <div>
                          <img
                            src={window.$domain + event.main_picture}
                            alt=" random imgee"
                            className="w-full object-cover object-center rounded-lg shadow-md max-w-full h-auto md:h-64"
                          />

                          <div className="relative px-4 -mt-16  ">
                            <div className=" p-6 bg-white border border-gray-300 rounded-lg ">
                              <div className="flex items-baseline">
                                <div className="ml-2 text-gray-700 uppercase text-xs font-semibold tracking-wider">
                                  Scheduled:
                                </div>
                                <span className="bg-blue-500 text-white sm:text-sm text-lg px-2 sm:ml-1 inline-block rounded-full  uppercase font-thin-100 sm:font-medium">
                                  <div className="sm:ml-1 ">
                                    {" "}
                                    {moment(event.start_date).format("LL")}
                                  </div>
                                </span>
                              </div>

                              <h4 className="mt-1 text-xl font-semibold uppercase leading-tight  tracking-wide">
                                {event.title}
                              </h4>

                              <div className="mt-1">{event.country}</div>
                              <div className="mt-4">
                                <Link to={"/eventdetail/" + event.name}>
                                  <button className="text-blue-600 text-md font-semibold inline-flex items-center">
                                    View Details
                                    <ArrowIcon />
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                </div>
                </div>
      </main>
    </>
  );
};

export default Events;
