import React from "react";

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Toaster, toast } from 'sonner'

var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};



const Analytics = () => {
  const { eventID } = useParams();
  const [accountRegistartion, setAccountRegistartion] = useState(null);
  const [isNotFound1, setIsNotFound1] = useState(false);
  const [isNotFound2, setIsNotFound2] = useState(false);
  const [isNotFound3, setIsNotFound3] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [accountPayment, setAccountPayment] = useState(null);
  const [accountChekIn, setAccountChekIn] = useState(null);



  const getRegistrationAccount = async () => {
    setLoadingSkeleton(true)
    const result = await axios
      .get(
        `https://dash.bestdiplomats.org/api/method/registrations-counters?filters=[["event","=","${eventID}"]]&group_by=reg_status`,
        config
      )
      .then((res) => {
        setAccountRegistartion(res.data.data);
        if(res.data.data.length==0){
          setIsNotFound1(true)
          // toast.error('No Data Found')
        }
        setLoadingSkeleton(false)
        //   console.log(res.data.data);
        // console.log('getChekInAccount',res.data.data.length);
      })
      .catch((e) => {
        console.log("t Analytic", e);
        if (e.code == "ERR_NETWORK") {
          // toast.error('No Data Found')
          setIsNotFound1(true);
          
        }
      });
  };
  const getPaymentAccount = async () => {
    const result = await axios
      .get(
        `https://dash.bestdiplomats.org/api/method/registrations-counters?filters=[["event","=","${eventID}"]]&group_by=payment_package`,
        config
      )
      .then((res) => {
        setAccountPayment(res.data.data);
        if(res.data.data.length==0){
          setIsNotFound2(true)
          // toast.error('No Data Found')
        }

        // console.log(res.data.data);
      })
      .catch((e) => {
        // console.log("t Analytic", e);
        if (e.code == "ERR_NETWORK") {
          // toast.error('No Data Found')
          setIsNotFound2(true);
          
        }
      });

  };
  const getChekInAccount = async () => {
    const result = await axios
      .get(
        `https://dash.bestdiplomats.org/api/method/get-checkin-counters?filters=[["event","=","${eventID}"]]&group_by=linked_registration`,
        config
      )
      .then((res) => {
        setAccountChekIn(res.data.data);
        if(res.data.data.length==0){
          setIsNotFound3(true)
          // toast.error('No Data Found')
        }
      })
      .catch((e) => {
        console.log("Analytic", e);
        if (e.code == "ERR_NETWORK") {
          // toast.error('No Data Found')
          setIsNotFound3(true);
          
        }
      });
  };
 
  useEffect(() => {
    
    getChekInAccount();
    getPaymentAccount();
    getRegistrationAccount();
  }, []);
 
  
  return (
    <div>
      {/* <Toaster position='bottom-left' richColors /> */}
      {" "}
      <div className=" flex items-center pt-5 lg:mx-0">
        <h2 className="text-lg font-medium tracking-tight text-gray-900 sm:text-lg">
        Registration Status Insights For Delegates
        </h2>
      </div>
     { isNotFound1 ? <div className="min-w-full bg-slate-100 rounded-lg font-bold text-red-500 p-8 flex justify-center items-center h-20 divide-gray-300">
                                  No Data Found
            </div>: <div className="grid grid-cols-1 mt-2 py-2 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {loadingSkeleton ?<> 
      <div className= "col-span-1 divide-y divide-white rounded-lg bg-green-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div> 
          <div className= "col-span-1 divide-y divide-white rounded-lg bg-green-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div>
          <div className= "col-span-1 divide-y divide-white rounded-lg bg-green-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div></>: <>{accountRegistartion?.map((acountreg) => (
            
          <div
            key={acountreg.count}
            className={
              acountreg?.reg_status == "Terminated"
                ? "col-span-1 divide-y divide-white rounded-lg bg-red-100"
                : acountreg?.reg_status == "Active"
                ? "col-span-1 divide-y divide-white rounded-lg bg-green-100"
                : "col-span-1 divide-y divide-white rounded-lg bg-orange-100"
            }
          >
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center justify-between space-x-3">
                  <h3 className={
              acountreg?.reg_status == "Terminated"
                ? "truncate text-lg font-normal text-red-700"
                : acountreg?.reg_status == "Active"
                ? "truncate text-lg font-normal text-green-700"
                : "truncate text-lg font-normal text-orange-700"
            }>
                    {acountreg.reg_status}
                  </h3>
                  <span className={
              acountreg?.reg_status == "Terminated"
                ? "inline-block flex-shrink-0 rounded-full bg-white px-2 py-1 text-xl font-medium text-red-700"
                : acountreg?.reg_status == "Active"
                ? "inline-block flex-shrink-0 rounded-full bg-white px-2 py-1 text-xl font-medium text-green-700"
                : "inline-block flex-shrink-0 rounded-full bg-white px-2 py-1 text-xl font-medium text-orange-700"
            }>
                    {acountreg.count}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
        </>}
      </div>}
      <div className=" flex items-center lg:mx-0">
        <h2 className="text-lg font-medium  tracking-tight text-gray-900 sm:text-lg">
        Payment Package Counters For Delegates
        </h2>
      </div>
      { isNotFound2 ? <div className="min-w-full bg-slate-100 rounded-lg font-bold text-red-500 p-8 flex justify-center items-center h-20 divide-gray-300">
                                  No Data Found
            </div>:  <div className="grid grid-cols-1 mt-2 py-2 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {loadingSkeleton ?<> 
      <div className= "col-span-1 divide-y divide-white rounded-lg bg-gray-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div> 
          <div className= "col-span-1 divide-y divide-white rounded-lg bg-gray-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div>
          <div className= "col-span-1 divide-y divide-white rounded-lg bg-gray-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div></>: <>  {accountPayment?.map((acountpay) => (
          <div className={"bg-blue-100 col-span-1 divide-y divide-gray-200 rounded-lg"}   >
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center justify-between space-x-3">
                  <h3 className=" whitespace-normal truncate text-lg font-light text-gray-900">
                    {acountpay?.payment_package}
                  </h3>
                  <span className="inline-block flex-shrink-0 rounded-full bg-white px-2 py-1 text-xl font-medium text-black">
                    {acountpay?.count}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}</>}
      </div>}
      <div className=" flex items-center lg:mx-0">
        <h2 className="text-lg font-medium  tracking-tight text-gray-900 sm:text-lg">
        Checkin Counters
        </h2>
      </div>
      { isNotFound3 ?<div className="min-w-full bg-slate-100 rounded-lg font-bold text-red-500 p-8 flex justify-center items-center h-20 divide-gray-300">
                                  No Data Found
            </div>: <div className="grid grid-cols-1 mt-2 py-2 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {loadingSkeleton ?<> 
      <div className= "col-span-1 divide-y divide-white rounded-lg bg-blue-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div> 
          <div className= "col-span-1 divide-y divide-white rounded-lg bg-blue-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div>
          <div className= "col-span-1 divide-y divide-white rounded-lg bg-blue-100">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
             <Skeleton width={40} height={20} /> 
            </div>
          </div></>: <>  {accountChekIn?.map((countchkin) => (
          <div
            key={countchkin?.count}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-blue-100 "
          >
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center justify-between space-x-3">
                  <h3 className="whitespace-normal text-lg font-light text-gray-900">
                    {countchkin?.linked_registration}
                  </h3>
                  <span className="inline-block flex-shrink-0 rounded-full bg-white px-2 py-1 text-xl font-medium text-black">
                    {countchkin?.count}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}</>}
      </div> }
    </div>
  );
};

export default Analytics;
