import React, { useEffect, useState } from "react";
import LogoFull from "../../img/LogoFull.png";
import { useFormik } from "formik";
import axios from "axios";
import Signup from "../Signup/Signup";

import { Link, useParams } from "react-router-dom";

const InviteCheck = () => {
  const {inviteId}=useParams();
  const [loading, setLoading] = useState(false);
  const [isDelegate, setIsDelegate] = useState(true);
  const [delegateData, setDelegateData] = useState(true);

  var splitInviteID= inviteId.split('-')[1];

  const checkDelegate = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      const checkDelegateProfile = async () => {
        try {
          setLoading(true);
          await axios
            .get(
              "https://dash.bestdiplomats.org/api/resource/Delegate%20Invitations/" +
              values.email+"-"+
                splitInviteID
            )
            .then((response) => {
              setDelegateData(response.data.data);
              setIsDelegate(true);
              window.location.assign(
                window.$FWDurl+"/signupDelegate/" +
                  response.data.data.invitation_email
              );
            });
          setLoading(false);
        } catch (e) {
          if (e.response?.status == 404) {
            setIsDelegate(false);
          }
        }
      };

      checkDelegateProfile();
    },
    enableReinitialize: true,
  });

  return (
    <>
      <div className="flex mt-16 min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={LogoFull}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Invited Delegates Entry
            </h2>
            <br />
            <p className="text-gray-400">
              You need to login or signup in order to accept our recevied invitation for an upcoming event.
            </p>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={checkDelegate.handleSubmit}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={checkDelegate.handleChange}
                  value={checkDelegate.values.email}
                  className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
            </div>

            <div className="flex flex-col justify-center text-center">
              <button
                type="submit"
                style={{"background-color": "#4591DC"}}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Sign up
              </button>
              <h1 className="text-gray-500 mt-4 mb-4">OR</h1>
              <Link to="/logincomp">
              <button
                
                style={{"background-color": "#4591DC"}}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Login
              </button>
              </Link>
            </div>
          </form>
          {isDelegate ? null : (
            <p className="font-medium text-red-400 text-center">
              This email is not in the invitation list
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default InviteCheck;
