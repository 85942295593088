import React from 'react'

const Done = (props) => {
  return (
    <div className={`flex ml-4 shadow-xl bg-${props.color}-100 w-96 rounded-lg p-4 mb-4 fixed z-10`}>
<p className={`ml-3 text-sm text-${props.color}-700`}>
    <span className="font-medium">{props.text}</span> 
  </p>
</div>
  )
}

export default Done