import axios from "axios";
import React, { useState, useEffect } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import placeholderimg from "../../img/placeholderimg.png";
function PrivateImage({ imageUrl, className }) {
  const [imageSrc, setImageSrc] = useState(null);

  // Configrations
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  useEffect(() => {
    try {
      const result = axios
        .get(
          `https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url=${imageUrl}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
            timeout: 30000,
          }
        )
        .then((response) => {
          var reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onloadend = function () {
            var base64data = reader.result;
            setImageSrc(base64data);
          };
        });
    } catch (e) {
      console.log(">>>>>");
      console.log(e);
    }
  }, [imageUrl]);
  // console.log(imageSrc);
  return (
    <ProgressiveImage src={imageSrc} placeholder={ placeholderimg}>
      {(src) => <img className={className} loading="lazy" src={src} alt="" />}
    </ProgressiveImage>
    
  );
}

export default PrivateImage;
