import React, { useEffect, useState } from "react";
import axios from "axios";

import { saveAs } from "file-saver";

import JSZip, { file } from "jszip";

import { useParams } from "react-router-dom";
import LoadingIcons from "react-loading-icons";

const BulkDiplomaticStance = () => {
  const [isDone, setIsDone] = useState(false);

  const { eventId } = useParams();

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const zip = new JSZip();
  const folder = zip.folder("files");
  
  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };


  // Call to get all Event Registerations
  useEffect(() => {
    const fetchEventRegisteration = async () => {
      try {
        
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/internal-registrations?filters=[["event","=","${eventId}"],["diplomatic_stance","is","set"]]`,
            config
          )
          .then((responseReg) => {
          // console.log(responseReg.data.data);
            const fetchEventData = async () => {
              try {
                const result = await axios
                  .get(
                    "https://dash.bestdiplomats.org/api/resource/Events/" +
                      eventId
                  )
                  .then((responseEvent) => {
                    const forLoop = async () => {
                      for(
                        let index = 0;
                        index <=responseReg.data.data.length;
                        index++
                      ) 
                      {
                        // console.log("Doing>>", index);
                        
                        
                        const getImage = async () => {
                          try {
                            const result = await axios
                              .get(
                                `https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url=${responseReg.data.data[index]?.diplomatic_stance}`,
                                {
                                  headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                  },
                                  responseType: "blob",
                                  timeout: 80000,
                                }
                              )
                              .then((response) => {
                                var file_name=responseReg.data.data[index]?.diplomatic_stance;
                                var splitOne= file_name.split(".")
                                var filetype= splitOne[splitOne.length-1];
                                // console.log(filetype);
                                folder.file(responseReg.data.data[index]?.delegate_profile?.user?.first_name + " " + responseReg.data.data[index]?.delegate_profile?.user?.last_name + "."+ filetype, response.data);

                                
                              });
                          } catch (e) {
                          
                            console.log(e);
                          }
                        };
                        getImage();
                        await sleep(2000);
                      }
                      zip
                        .generateAsync({ type: "blob" })
                        .then((blob) => saveAs(blob, "All Diplomatic Stance"));
                      setIsDone(true);
                    };
                    forLoop();

                    
                  });
              } catch (e) {
                console.log(e);
              }
            };
            fetchEventData();
          });
        
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventRegisteration();
  }, []);
  return (
    <div>
      {isDone ? (
        <span className="group ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-green-700 my-4  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2">
          Done Downloading
        </span>
      ) : (<>
        <span className="group ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <LoadingIcons.SpinningCircles className="w-7 h-7"/>
        </span>
        <div className="flex justify-center">
        <span>This may take a few minutes. Your file will be download automatically.</span>
        </div>
        </>
      )}
    </div>
  );
};

export default BulkDiplomaticStance;
