import React from 'react'
import axios from "axios";
import { Toaster, toast } from 'sonner'

import moment from "moment";
// import {
//   CloudArrowDownIcon,
//   ListBulletIcon,
//   DocumentTextIcon,
//   IdentificationIcon,
//   CreditCardIcon,
//   PaperAirplaneIcon,
//   UserPlusIcon,
//   PencilIcon,
// } from "@heroicons/react/24/outline";
import { XMarkIcon,ChevronRightIcon ,ChevronLeftIcon} from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { Fragment, useState ,useEffect,useRef} from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Done from "../Alerts/Done";
import Papa from "papaparse";
import Tag from "../Tag/Tag";
// import moment from "moment";
import Registration from '../Registration/Registration';
import PrivateImage from '../PrivateImage/PrivateImage';
import { ClearLocalStorage } from '../ClearLocalStorage';

var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

const LocalTeamCheckin = () => {
  const { eventID } = useParams();
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popUPImage, setPopUPImage] = useState(false);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [personDetail, setPersonDetail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);

  const [allRoomSharing, setAllRoomSharing] = useState(null);
  const [totalRoomCount, setTotalRoomCount] =useState(0);
  const tableRef = useRef(null);

  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };


  useEffect(() => {
    setLoadingSkeleton(true)
    const fetchRoomSharing = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/get-localteam-checkin?event=${eventID}`,
            config
          )
          .then((response) => {
            console.log(">>>>> room",response.data.data)
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 30);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              console.log(pageNumbers);
            }
            setTotalRoomCount(response.data.data.length);
            setAllRoomSharing(response.data.data);
            setLoadingSkeleton(false)
            if(response.data.data.length==[]){
              toast.error('No Data Found')
              setLoadingSkeleton(true)
              setIsNotFound(true);
            }
          });
        setLoading(false);
      } catch (e) {
        if(JSON.parse(JSON.stringify(e)).message=="Request failed with status code 403"){
          ClearLocalStorage(JSON.parse(JSON.stringify(e)))
          
        }
        else{
        console.log(JSON.parse(JSON.stringify(e)));
          toast.error('No Data Found')
          setLoadingSkeleton(true)
          setIsNotFound(true);
        }
    
    };
  }
    fetchRoomSharing();
  }, []);
  const handleClick = (number) => {
    setCurrentPage(number);
    scroll();
    scrollToTop()
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    scrollToTop()
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop()
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop()
      }
    }
  };

  const handleChangeIfImage = () => {
    setPopUPImage(true);
  };

  const Handleviewdetail=(person)=>{
    setPersonDetail(person)
    // console.log(person);
   
    setOpen(true)
  }
  const handleDownload = () => {
    const newData = allRoomSharing?.map((item) => ({
     
      Name: item?.linked_registration==="Guest"? item?.registration?.first_name+" "+item?.registration?.last_name:item?.user?.first_name+" "+item?.user?.last_name,
      Gender:item?.registration?.gender,
      Registration_type:item?.linked_registration,
      Payment_Package:item.linked_registration=="Delegate"? item?.registration?.payment_package: item.linked_registration=="Core Team"?item?.registration?.package:item.linked_registration=="Observers"?item?.registration?.package:item?.linked_registration=="Local Team"?item?.registration?.package:item?.registration?.package,
      Hotel_Room_Number: item?.hotel_room_number,
      Room_Status : item?.room_status,
      Passport_Number: item?.registration?.passport_number ,
      Passport_Expiry: item?.registration?.passport_expiry,
  
        }));
  
    const csv = Papa.unparse(newData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "my-file.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
    <div className="flex justify-end mt-3 gap-2">
      
         {/* <div className=" absolute top-14 left-6">
          {isNotFound && <Done text="No Data Found" color="red" />}
        </div>
        <div className=" absolute top-14 left-6">
          {isNotFound && <Done text="No Data Found" color="red" />}
        </div> */}
      <div className="">

        <div className="mt-4 mb-0.5 flex justify-end items-center  sm:mt-0 sm:ml-1 sm:flex-none">
        {loadingSkeleton ?<><Skeleton width={80} height={40} /> </>:   <button
          className="ml-3 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => handleDownload()}
        >
          Download CSV
        </button>}
    
        </div>
      </div>
    </div>
    <div className="py-1">
      <p className="felx justify-end text-right font-semibold">
      {loadingSkeleton ?<><Skeleton width={40} height={20} /> </>: <> Checkins: {totalRoomCount}</>}
      </p>
    </div>
<div className='px-2'>
    <div  className="mt-2 flex flex-col max-h-screen">
      <div  ref={tableRef}  className="-my-2 -mx-4  overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-6">
         
          { isNotFound?  <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
 </svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>:  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"> <table className="min-w-full divide-y  divide-gray-300">
              <thead className="bg-stone-100">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                  Name & Email
                  </th>
                 
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                  Check in time
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                  Check out time
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                   Type
                  </th>
                
                  
                 
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
             {  loadingSkeleton? <tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     <Skeleton />
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                    

                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                   </tr>
                 
               </tbody>:<tbody className="divide-y divide-gray-200 bg-white">
             
                 { allRoomSharing?.slice((currentPage - 1) * 30, currentPage * 30).map((person) => (
                    <tr key={person.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0"   onClick={() => {
                                Handleviewdetail(person);
                              }}>
                            
                        
                          
                               { person?.linked_registration=="Delegate"?   <PrivateImage imageUrl={person?.user?.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/>  : person?.linked_registration=="Core Team"? <PrivateImage imageUrl={person?.user?.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/> :person?.linked_registration=="Observers"? <PrivateImage imageUrl={person?.user?.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/> :person?.linked_registration=="Local Team"?   <PrivateImage imageUrl={person?.user?.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/> :  <PrivateImage imageUrl={person?.registration?.image} className={"h-10 w-10 rounded-full cursor-pointer"}/> }
                              
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900 cursor-pointer"
                              onClick={() => {
                                Handleviewdetail(person);
                              }}>
                              {person?.registration?.first_name +
                                " " +
                                person?.registration?.last_name}
                            </div>
                            <div className="text-gray-500">
                               {person.linked_registration=="Delegate"? person?.user?.email: person.linked_registration=="Core Team"?person?.user?.email:person.linked_registration=="Observers"?person?.user?.email:person.linked_registration=="Local Team"?person?.user?.email:person?.registration?.email}
                       
                            </div>
                          </div>
                        </div>
                      </td>
                    
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person?.checkin_at===null||person?.checkin_at===undefined?<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                      Not Applicable
                          </span>: <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                         { moment(person?.checkin_at).format("lll")}
                        </span>}
                       
                      </td>
                    
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person?.checkout_time===null||person?.checkout_time===undefined?<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                     Not Applicable
                          </span>: <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                         {moment(person?.checkout_time).format("lll")}
                        </span>}
                       
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person?.checkout_type===null||person?.checkout_type===undefined?<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                      Not Applicable
                          </span>:person?.checkout_type=="OUT"?(<span className="inline-flex rounded-full bg-blue-500 px-2 text-xs font-semibold leading-5 text-white">
                      {person?.checkout_type}
                          </span>):(<span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {person?.checkout_type}
                        </span> )}
                        
                      </td>
                      
                   
                     
                    </tr>
                  ))
                }
              </tbody>}
            </table>  </div>}
        
        </div>
      </div>
    </div>
    </div>
    {isNotFound? null: <div className="flex items-center justify-between mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 30 + 1}
                  </span>{" "}
                  to <span className="font-medium">{currentPage * (totalRoomCount % 30)}</span> of{" "}
                  <span className="font-medium">{totalRoomCount}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}
       {/* nationalty for Filters */}
       <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                          Local Team Member
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24  sm:h-20 lg:h-28 bg-blue-500" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg ">
                                {personDetail?.linked_registration=="Delegate"?   <PrivateImage imageUrl={personDetail?.user?.user_image} className={"h-24 w-24  flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/>  : personDetail?.linked_registration=="Core Team"? <PrivateImage imageUrl={personDetail?.user?.user_image} className={"h-24 w-24  flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> :personDetail?.linked_registration=="Observers"? <PrivateImage imageUrl={personDetail?.user?.user_image} className={"h-24 w-24  flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> :personDetail?.linked_registration=="Local Team"?   <PrivateImage imageUrl={personDetail?.user?.user_image} className={"h-24 w-24  flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> :  <PrivateImage imageUrl={personDetail?.registration?.image} className={"h-24 w-24  flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> }
                               
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                  {personDetail?.linked_registration=="Delegate"?  personDetail?.user?.first_name +" " + personDetail?.user?.last_name: personDetail?.linked_registration=="Core Team"? personDetail?.user?.first_name +" " + personDetail?.user?.last_name:personDetail?.linked_registration=="Observers"? personDetail?.user?.first_name +" " + personDetail?.user?.last_name:personDetail?.linked_registration=="Local Team"? personDetail?.user?.first_name +" " + personDetail?.user?.last_name:personDetail?.registration?.first_name +" " + personDetail?.registration.last_name}
                                     
                                  </h3>
                                </div>
                                <p className="text-sm text-gray-500">
                                {personDetail?.linked_registration=="Delegate"? personDetail?.user?.email: personDetail?.linked_registration=="Core Team"?personDetail?.user?.email:personDetail?.linked_registration=="Observers"?personDetail?.user?.email:personDetail?.linked_registration=="Local Team"?personDetail?.user?.email:personDetail?.registration?.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Checkin Passport Scan

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6"> {personDetail?.checkin_passport_scan?(<div  onClick={()=>handleChangeIfImage()} className="h-14 w-14 cursor-pointer flex-shrink-0 rotate-90 sm:h-40 sm:w-40 lg:h-18 lg:w-18">  <PrivateImage imageUrl={personDetail?.checkin_passport_scan.split("https://dash.bestdiplomats.org")[1]} className={"h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40 lg:h-18 lg:w-18"}/> </div>
                              ):(
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                Missing
                              </span>
                              
                             ) }</dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Check In By
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                              {personDetail?.checkin_by===null||personDetail?.checkin_by===undefined?<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                     Not Applicalbe
                          </span>: <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                         {personDetail?.checkin_by}
                        </span>}
                         
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Type

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                              {personDetail?.checkout_type===null||personDetail?.checkout_type===undefined?<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                              Not Applicalbe
                          </span>:personDetail?.checkout_type=="OUT"?(<span className="inline-flex rounded-full bg-blue-400 px-2 text-xs font-semibold leading-5 text-white">
                      {personDetail?.checkout_type}
                          </span>):(<span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {personDetail?.checkout_type}
                        </span> )}
                           
                               

                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                               Time
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                              {personDetail?.checkout_type=="OUT"?personDetail?.checkout_time===null||personDetail?.checkout_time===undefined?<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                              Not Applicalbe
                          </span>:<span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {moment(personDetail?.checkout_time).format("lll")}
                        </span>:personDetail?.checkin_at===null||personDetail?.checkin_at===undefined?<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                              Not Applicalbe
                          </span>: <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {moment(personDetail?.checkin_at).format("lll")}
                        </span>}
                         
                              </dd>
                            </div>
                           
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Passport Number
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                           <span className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                              {personDetail?.registration.passport_number=="" || personDetail?.registration.passport_number==null ?  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                Missing
                              </span>:personDetail?.registration?.passport_number}
                              </span>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Passport Expiry
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                           <span className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                           {personDetail?.registration.passport_expiry=="" || personDetail?.registration.passport_expiry==null ?  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                Missing
                              </span>:personDetail?.registration?.passport_expiry}
                              </span>
                              </dd>
                            </div>

                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Payment Package

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                              {personDetail?.linked_registration=="Delegate"? personDetail?.registration?.payment_package: personDetail?.linked_registration=="Core Team"?personDetail?.registration?.package:personDetail?.linked_registration=="Observers"?personDetail?.registration?.package:personDetail?.linked_registration=="Local Team"?personDetail?.registration?.package:personDetail?.registration?.package}
                              </dd>
                            </div>
                            {/* <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Representing Country

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                             {personDetail?.representing_country}
                              </dd>
                            </div> */}
                          
                            {/* <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Payment Package

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                               
                              {personDetail?.payment_package}
                              </dd>
                            </div> */}
                            {/* <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Address

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.Registration.address}
                              </dd>
                            </div> */}
                          
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
            {/* Popup for Picture */}
            <Transition.Root show={popUPImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setPopUPImage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-2  shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setPopUPImage(false)}
                    ref={cancelButtonRef}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                  <div className="flex mb-16 mt-10 justify-center items-center rotate-90 bg-orange-200">
                  <PrivateImage imageUrl={personDetail?.checkin_passport_scan?.split("https://dash.bestdiplomats.org")[1]} />
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {/* <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                 
                </button> */}
                    {/* <button
                      type="button"
                      className="mt-3 inline-flex w-40 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setPopUPImage(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  </>
  )
}


export default LocalTeamCheckin