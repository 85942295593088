import {
    CheckBadgeIcon,
    CheckIcon,
    LinkIcon,
    NoSymbolIcon,
    PaperAirplaneIcon,
    XMarkIcon,
  } from "@heroicons/react/24/outline";
  import InputEmoji, { async } from "react-input-emoji";
  import {
    addDoc,
    doc,
    collection,
    serverTimestamp,
    setDoc,
    Timestamp,
    updateDoc,
    getDocs,
    getDoc,
    deleteDoc,
    where,
  } from "firebase/firestore";
  import EmojiPicker, {
    EmojiStyle,
    SkinTones,
    Theme,
    Categories,
    EmojiClickData,
    Emoji,
    SuggestionMode,
    SkinTonePickerLocation,
  } from "emoji-picker-react";
  import { debounce } from 'lodash';
  import { query, onSnapshot, orderBy, limit } from "firebase/firestore";
  import { createRef, useEffect, useRef, useState, useTransition } from "react";
  import moment from "moment";
  import { useParams } from "react-router-dom";
  import axios from "axios";
  import ProgressiveImage from "react-progressive-graceful-image";
  import placeholderimg from "../../../img/placeholderimg.png";
  import { Toaster, toast } from "sonner";
  import { Switch } from "@headlessui/react";
  import Skeleton from "react-loading-skeleton";
  import { Fragment } from "react";
  import { Dialog, Transition } from "@headlessui/react";
  import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

  import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PrivateImage from "../../PrivateImage/PrivateImage";
import { db } from "../../firebase";
import { DeleteAllMessage, OneSMSDelete } from "./DeleteDiplomaticChat";
  // Initialize Firebase Storage
  const storage = getStorage();
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  
  var userEmail;
  var userName;
  var userRole;
  var userPicture;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    userRole = localStorage.role;
    userPicture = localStorage.picture;
    userName = localStorage.user_name;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  
 
  const DiplomaticChat = ({ eventResponse }) => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const messagesEndRef = useRef();
    const toScroll = useRef(null);
    const [messages, setMassages] = useState([]);
    const [value, setValue] = useState("");
    const [file, setFile] = useState(null);
  
    const currentUser = [{}];
    const { eventID } = useParams();
    const [userImage, setUserimage] = useState([]);
    const [senderName, setSenderName] = useState(null);
    const textAreaRef = useRef(null);
  
    const [filteredData, setFilteredData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [storeDeleteMessageId, setStoreDeleteMessageId] = useState(null);
  
    useEffect(() => {
      const fetchEventRegisteration = async () => {
        try {
          const result = await axios
            .get(
              `https://dash.bestdiplomats.org/api/method/get-diplomatic-experts?filters=[["profile_status","in","Approved"]]`,
              config
            )
            .then((response) => {
              setUserimage(response.data.data);
              // console.log(">>>>>>>", response.data.data);
            });
        } catch (e) {
          console.log(e);
        }
      };
      fetchEventRegisteration();
    }, []);
    let Sendercuntry;
    let SenderName;
    let Gender;
    function getUserImageByName(name) {
      // console.log(userImage);
      const foundItem = userImage.find(
        (item) => item?.name === name
      );
  
      if (foundItem) {
        // console.log("userimage", foundItem?.delegate_profile?.user?.user_image);
  
        return foundItem?.user.user_image;
      } else {
        return null;
      }
    }
    function getUserGender(name) {
      const foundItem = userImage.find(
        (item) => item?.name === name
      );
      // console.log("foundItem?.gender", foundItem?.gender);
  
      if (foundItem) {
        return (SenderName = foundItem?.user?.first_name);
      }
    }
    function getUserName(name) {
      // console.log(name);
      const foundItem = userImage.find(
        (item) => item?.name === name
      );
  
      if (foundItem) {
        return (SenderName = foundItem?.user?.last_name);
      }
    }
    function  getUserShadowban(name){
      const foundItem = userImage.find(
        (item) => item?.name === name
      );
  
      if (foundItem) {
        const icons= foundItem?.shadow_ban;
        switch (icons) {
          case 1:
            return <NoSymbolIcon className="w-5 text-red-600"/>;
          case 0:
            return null;
          default:
            return null; 
        }
          
      }
    }
    function getUsercountry(name) {
      // console.log(name);
      const foundItem = userImage.find(
        (item) => item?.name === name
      );
  
      if (foundItem) {
        return (Sendercuntry = `(${foundItem?.representing_country})`);
      }
    }
  
    const [isSwiped, setIsSwiped] = useState(null);
    const [isSwip, setIsSwip] = useState(false);
    const handleSwipeStart = () => {
      // setIsSwiped(true);
    };
    const handleSwipeEnd = () => {
      // setIsSwiped(false);
    };
    const handleReply = (e) => {
      setIsSwiped(e);
      setIsSwip(true);
      // console.log("handleSwipeStart", e);
    };
    // const handleUpload = () => {
    //   if (file) {
    //     const storageRef = firebase.storage().ref();
    //     const fileRef = storageRef.child(file.name);
    //     fileRef.put(file).then((snapshot) => {
    //       console.log('File uploaded successfully');
    //       // Get the download URL
    //       snapshot.ref.getDownloadURL().then((downloadURL) => {
    //         // Save the download URL and other message data in Firestore
    //         const firestore = firebase.firestore();
    //         firestore.collection('messages').add({
    //           fileURL: downloadURL,
    //           // Other message data
    //         });
    //       });
    //     });
    //   }
    // };
    // send file
    const parentDocRef = doc(db, "DiplomaticExpertChat","Messages");
    const subcollectionRef = collection(parentDocRef, "Message");
    const [isScrolFalse, setIsScrolFalse] = useState(false);
    const [flightDocument, setFlightDocument] = useState(false);
    const [loaderFlightFile, setLoaderFlightFile] = useState(false);
    const [uploadFileResponse, setUploadFileResponse] = useState(null);
    const uploadFlightDocument = async (e) => {
      const fileFlight = e.target.files[0];
      const formData = new FormData();
      formData.append("file", fileFlight);
      formData.append("doctype", "Files Access");
      formData.append("is_private", 1);
  
      setLoaderFlightFile(true);
  
      try {
        await axios
          .post(
            `https://dash.bestdiplomats.org/api/method/upload_file`,
            formData,
            config
          )
          .then(async (responseFlight) => {
            // console.log("><>?><<<", responseFlight?.data?.message?.file_url);
            // console.log(
            //   " url >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
            //   responseFlight.data
            // );
            await setUploadFileResponse(responseFlight.data);
            setShowImagePopup(true);
  
            setLoaderFlightFile(false);
          });
      } catch (e) {
        console.log(e);
        toast.error("Try Again");
        setLoaderFlightFile(false);
      }
    };
  
    const handleSendsubmit = async (e) => {
      setShowImagePopup(false);
      try {
        await addDoc(subcollectionRef, {
          content: uploadFileResponse?.message?.file_url,
          delegateRegistrationID: null,
          is_deleted: false,
          dateTime: serverTimestamp(),
          idFrom: userEmail,
          idTo: eventID,
          type: "File",
          isAdmin: true,
          senderName:
            userRole === "Academics Department" || userRole=== "Communication Department"
              ? userRole
              : userName,
          senderImage: userPicture,
          userRole: userRole,
          is_reply_to_admin:
            isSwiped?.id == null
              ? false
              : isSwiped?.isAdmin === true
              ? true
              : false,
          reply_owner_name:
            isSwiped?.senderName == null ? false : isSwiped?.senderName,
          reply_to:
            isSwiped?.id == null
              ? false
              : isSwiped?.isAdmin === true
              ? userName
              : isSwiped.idFrom,
          is_reply:
            isSwiped?.id == null
              ? false
              : isSwiped?.is_reply === null
              ? false
              : true,
          reply_message_content:
            isSwiped?.id == null
              ? false
              : isSwiped?.content == null
              ? null
              : isSwiped?.content,
          reply_type:
            isSwiped?.id == null
              ? false
              : isSwiped?.type == null
              ? null
              : isSwiped?.type,
          is_reply:
            isSwiped?.id == null ? false : isSwiped?.id == null ? false : true,
          reply_message_id:
            isSwiped?.id == null
              ? false
              : isSwiped?.id == null
              ? null
              : isSwiped?.id,
        });
        setIsSwiped(null);
        setIsSwip(false);
        scrollToBottom()
        setShowImagePopup(false);
      } catch (error) {
        console.log(error);
        toast.success("Try Again");
        setShowImagePopup(false);
      }
      setIsSwiped(null);
      setIsSwip(false);
      setShowImagePopup(false);
    };
  
    const handleSendMessage = async (e) => {
      e.preventDefault();
      if (value?.trim() === "") {
        toast.error("Enter valid message!");
        return;
      }
      try {
        await addDoc(subcollectionRef, {
          content: value,
          delegateRegistrationID: null,
          is_deleted: false,
          dateTime: serverTimestamp(),
          idFrom: userEmail,
          idTo: eventID,
          type: "String",
          isAdmin: true,
          senderName:
            userRole === "Academics Department" || userRole=== "Communication Department"
              ? userRole
              : userName,
          senderImage: userPicture,
          userRole: userRole,
          is_reply_to_admin:
            isSwiped?.id == null
              ? false
              : isSwiped?.isAdmin === true
              ? true
              : false,
          reply_owner_name:
            isSwiped?.senderName == null ? false : isSwiped?.senderName,
          reply_to:
            isSwiped?.id == null
              ? false
              : isSwiped?.isAdmin === true
              ? userName
              : isSwiped.idFrom,
          is_reply:
            isSwiped?.id == null
              ? false
              : isSwiped?.is_reply === null
              ? false
              : true,
          reply_message_content:
            isSwiped?.id == null
              ? false
              : isSwiped?.content == null
              ? null
              : isSwiped?.content,
          reply_type:
            isSwiped?.id == null
              ? false
              : isSwiped?.type == null
              ? null
              : isSwiped?.type,
          is_reply:
            isSwiped?.id == null ? false : isSwiped?.id == null ? false : true,
          reply_message_id:
            isSwiped?.id == null
              ? false
              : isSwiped?.id == null
              ? null
              : isSwiped?.id,
        });
        setIsSwiped(null);
        setIsSwip(false);
        scrollToBottom()
        textAreaRef.current.value = "";
      } catch (error) {
        console.log(error);
      }
      setIsSwiped(null);
      setIsSwip(false);
      setValue("");
      textAreaRef.current.value = "";
    };
    const chatContainerRef = useRef(null);
    const scrollToBottom = () => {
      document.getElementById("toscroll")?.scrollTo({
        top: document.getElementById("toscroll").scrollHeight,
        behavior: "smooth",
      });
    };
    useEffect(()=>{ if(isScrolFalse===true){
      console.log("isScrolFalse");
                   }
                 else
                    {
                   scrollToBottom()}
    }, [messages]);
    
    useEffect(() => {
      const q = query(
        collection(db, `DiplomaticExpertChat/Messages/Message`),
         orderBy("dateTime")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messages = [];
        querySnapshot.forEach((doc) => {
          messages.push({ id: doc.id, ...doc.data() });
        });
        // console.log("message", messages);
        setMassages(messages);
        setFilteredData(messages)
      });
  
      return () => unsubscribe;
    }, []);
    // console.log("Messages", messages);
  
    const [dateGroup, setDateGroup] = useState("");
  
    useEffect(() => {
      const lastMessage = messages[messages.length - 1];
      const currentDate = moment
        .unix(lastMessage?.dateTime?.seconds)
        .format("LL");
  
      if (currentDate !== dateGroup) {
        setDateGroup(currentDate);
      }
    }, [messages]);
  
    const textAreaEl = createRef();
    const [show, setShow] = useState(false);
    const getText = function () {
      // console.log(textAreaEl.current.value);
    };
  
    const handleInputChange = (e) => {
      setValue(e.target.value);
    };
  
    const onClick = (emojiData) => {
      // console.log(">>>>>>>>>0", emojiData.emoji);
      setValue(value + emojiData.emoji);
      // setShowEmojiPicker(!showEmojiPicker);
    };
    const handleKeyDown = async (e) => {
      if (e.key === "Enter") {
        if (e.shiftKey) {
          // Add a new line in the textarea
          const textarea = e.target;
          textarea.value = textarea.value + "\n";
          e.preventDefault();
        } else {
          if (value?.trim() === "") {
            toast.error("Enter valid message!");
            return;
          }
          const parentDocRef = doc(db,"DiplomaticExpertChat", "Messages");
          const subcollectionRef = collection(parentDocRef, "Message");
          try {
            await addDoc(subcollectionRef, {
              content: value,
              is_deleted: false,
              delegateRegistrationID: null,
              dateTime: serverTimestamp(),
              idFrom: userEmail,
              idTo: eventID,
              type: "String",
              isAdmin: true,
              senderName:
                userRole === "Academics Department" || userRole=== "Communication Department"
                  ? userRole
                  : userName,
              reply_owner_name:
                isSwiped?.senderName == null ? false : isSwiped?.senderName,
              senderImage: userPicture,
              userRole: userRole,
              is_reply_to_admin:
                isSwiped?.id == null
                  ? false
                  : isSwiped?.isAdmin === true
                  ? true
                  : false,
              reply_to:
                isSwiped?.id == null
                  ? false
                  : isSwiped?.isAdmin === true
                  ? isSwiped?.senderName
                  : isSwiped.idFrom,
              is_reply:
                isSwiped?.id == null
                  ? false
                  : isSwiped?.is_reply === null
                  ? false
                  : true,
              reply_message_content:
                isSwiped?.id == null
                  ? false
                  : isSwiped?.content == null
                  ? null
                  : isSwiped?.content,
              is_reply:
                isSwiped?.id == null
                  ? false
                  : isSwiped?.id == null
                  ? false
                  : true,
              reply_message_id:
                isSwiped?.id == null
                  ? false
                  : isSwiped?.id == null
                  ? null
                  : isSwiped?.id,
              reply_type:
                isSwiped?.id == null
                  ? false
                  : isSwiped?.type == null
                  ? null
                  : isSwiped?.type,
            });
            setIsSwiped(null);
            setIsSwip(false);
            setValue("");
            scrollToBottom()
            textAreaRef.current.value = "";
          } catch (error) {
            console.log(error);
          }
          // console.log("enter", value);
          setValue("");
        }
      }
    };
  
    const getNumNewLines = (text) => {
      // Count the number of new lines in the text
      const regex = /\n/g;
      const matches = text.match(regex);
      return matches ? matches.length : 0;
    };
  
    const shouldApplyClass = (text) => {
      const numNewLines = getNumNewLines(text);
      return numNewLines >= 1;
    };
    const checkCollectionExistence = async (eventID) => {
      const eventDocRef = doc(collection(db, "DiplomaticExpertChatEnable"), "Enable");
      const docSnapshot = await getDoc(eventDocRef);
      return docSnapshot.exists();
    };
    const textareaClass = shouldApplyClass(value) ? "scrollable-textarea" : "";
    const [collectionData, setCollectionData] = useState([]);
    const [isEnabled, setIsEnabled] = useState(false);
    const [enabled, setEnabled] = useState();
    useEffect(() => {
      const fetchCollectionData = async (eventID) => {
        try {
          const docSnapshot = await getDoc(
            doc(collection(db, "DiplomaticExpertChatEnable"), "Enable")
          );
          if (docSnapshot.exists()) {
            const collectionData = docSnapshot.data();
            setEnabled(!collectionData.enable_value);
            // console.log('Collection data:', collectionData);
          } else {
            // console.log('Collection does not exist.');
            setEnabled(false);
          }
        } catch (error) {
          console.error("Error fetching collection data:", error);
        }
      };
  
      fetchCollectionData(eventID);
    }, [eventID]);
  
    const handleUpdateClick = async () => {
      try {
        const collectionExists = await checkCollectionExistence(eventID);
        if (collectionExists) {
          // Collection exists, perform update
          await updateDoc(doc(collection(db, "DiplomaticExpertChatEnable"), "Enable"), {
            enable_value: enabled,
          });
          // console.log("Chat updated successfully");
          toast.success(
            enabled ? "Chat Disable successfully" : "Chat Enable successfully"
          );
        } else {
          // Collection does not exist, perform create
          await setDoc(doc(collection(db, "DiplomaticExpertChatEnable"), "Enable"), {
            enable_value: enabled,
          });
          toast.success("Chat Enable successfully");
          // console.log('Collection created successfully.');
        }
      } catch (error) {
        console.error("Error updating/creating collection:", error);
      }
    };
    const [DeleteAllMessages, setDeleteAllMessages] = useState(false);
    // Delete all Sms in the subcollection
    const handleDeleteAllMessage = () => {
      setDeleteAllMessages(true);
    };
    // Delete an SMS message by ID
    const handleDeleteClick = () => {
      setShowPopup(true);
    };
    const handleConfirmDelete = () => {
      OneSMSDelete(subcollectionRef, storeDeleteMessageId);
      setShowPopup(false);
    };
    const [isPending, startTransition] = useTransition()
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearch = debounce((term) => {
      // Filter data based on search term
      const filteredData = messages.filter((item) =>
        item?.content?.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredData(filteredData);
    }, 200);
  
    const handleSearch = (e) => {
      const searchTerm = e.target.value;
      setSearchTerm(searchTerm);
      debouncedSearch(searchTerm);
    };
  
    const [scrollToMessageId, setScrollToMessageId] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [currentResultIndex, setCurrentResultIndex] = useState(-1);
  
    
    const highlightedMessageRef = useRef(null); 
    // Ref for the currently highlighted message
  console.log("searchResults",currentResultIndex);
    useEffect(() => {
      // Scroll  the scrollToMessageId changes
      scrollToMessage(scrollToMessageId);
    }, [scrollToMessageId]);
  
    useEffect(() => {
      // Update the search results whenever the search term changes
      const filteredMessages = messages.filter((message) =>
        message.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredMessages);
      setCurrentResultIndex(-1);
       // Reset current result index on search term change
    }, [searchTerm]);
  
    const scrollToMessage = (messageId) => {
      // console.log("?>?>?>",messageId);
      // Find the index of the message with the provided ID
      const messageIndex = messages.findIndex((message) => message.id === messageId);
      // console.log("messageIndex",messageIndex);
      // Check if the chat container  exists and the message index is valid
      if (toScroll.current && messageIndex !== -1) {
        const messageElement = toScroll.current.children[messageIndex];
        if (messageElement) {
          // Scroll the chat container  top of the desired message
          messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    };
  
    const handleSearchInputChange = (event) => {
      setSearchTerm(event.target.value);
      setScrollToMessageId(null); 
      // Reset scroll position when search term changes
    };
    const handleEnterKeyPress = (e) => {
      if (e.key === 'Enter' && searchResults.length > 0) {
        // search result when the user presses Enter
        if (searchResults.length > 0) {
          // const nextResultIndex = (currentResultIndex + 1) % searchResults.length
          setCurrentResultIndex((prevIndex) => (prevIndex + 1) % searchResults.length);
          
          setScrollToMessageId(searchResults[currentResultIndex]?.id);
          console.log(searchResults[currentResultIndex]?.id);
        }
      }
    };
  
    useEffect(() => {
      //  Enter key press when the component mounts
      document.addEventListener('keydown', handleEnterKeyPress);
  
      // Cleanup when the component unmounts
      return () => {
        document.removeEventListener('keydown', handleEnterKeyPress);
      };
    }, [searchResults]);
   
    const [repliedMessageId, setRepliedMessageId] = useState(null);
  
    const repliedMessageRefs = useRef({});
  
    const handleReplyClick = (repliedId) => {
      setRepliedMessageId(repliedId);
    };
  
    useEffect(() => {
      if (repliedMessageId !== null && repliedMessageRefs.current[repliedMessageId]) {
        const repliedMessage = repliedMessageRefs.current[repliedMessageId];
        repliedMessage.scrollIntoView({ behavior: "smooth", block: "center" });
        setRepliedMessageId(null); // Reset repliedMessageId after scrolling
      }
    }, [repliedMessageId]);
  
    return (
      <>
      <div className="container mx-auto">
        <Toaster position="bottom-left" richColors />
        <div className="min-w-full border  rounded lg:grid lg:grid-cols-3">
          {/* <OneToOneChat eventID={eventID} /> */}
          <div className="hidden lg:col-span-4 lg:block">
            <div className="w-full">
              <div className="relative flex items-center p-3 border-b border-gray-300">
                {/* Remove Delete all Button */}
                <div className="flex absolute right-2 top-4 justify-end ">
                  {/* <button
                    className="block w-full rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    onClick={handleDeleteAllMessage}
                  >
                    Delete All{" "}
                  </button> */}
                              <div className="relative w-72">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" className="w-6 h-6 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
        </div>
        <input type="text" id="voice-search"
         value={searchTerm}
            onChange={handleSearchInputChange}
            onKeyDown={handleEnterKeyPress}
             className="bg-gray-50 border w-full border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  pl-10 p-2.5 " placeholder="Search "  />

                           </div> 
                           
                </div>
           

                <img
                  className="object-cover w-10 h-10 rounded-full"
                  src={placeholderimg}
                  alt="username"
                />
                <span className="block ml-2 font-bold text-gray-600">
                  {eventResponse}

                  <div className="flex justify-end">
                    <Switch.Group
                      as="div"
                      className="flex items-center   justify-end"
                    >
                      <span className="flex flex-grow  flex-col">
                        <Switch.Label
                          as="span"
                          className="text-sm pr-2 font-medium leading-6 text-gray-900"
                          passive
                        >
                          Allow Diplomatic Expert to chat
                        </Switch.Label>
                      </span>
                      <Switch
                        checked={enabled}
                        onChange={() => {
                          setEnabled(!enabled);
                        }}
                        onClick={() => {
                          setTimeout(() => {
                            handleUpdateClick();
                          }, 1000);
                        }}
                        className={classNames(
                          enabled ? "bg-indigo-600" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            enabled ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </Switch.Group>{" "}
                  </div>
                </span>
              </div>

              <div
              ref={toScroll}
                // ref={toScroll}
                // scrollbar-hide
                id="toscroll"                
                className="  w-full p-6 overflow-y-auto h-[64rem]"
              >
                {filteredData?.map((message, index) => {
                  const currentMessageDate = moment
                    .unix(message?.dateTime?.seconds)
                    .format("LL");
                  const previousMessageDate =
                    index > 0
                      ? moment
                          .unix(filteredData[index - 1]?.dateTime?.seconds)
                          .format("LL")
                      : "";
                  // console.log("saa",message.id);
                  return (
                    <>
                      {/* Render date only if it's different from the previous message */}
                      { message.is_deleted===true?"": currentMessageDate !== previousMessageDate &&
                          (currentMessageDate == "Invalid date" ? (
                            " "
                          ) : (
                            <div className="text-center mb-2">
                              <div class="divider">
                                <div className="inline-block px-2 py-1 text-sm text-gray-500 bg-gray-200 rounded-full">
                                  {currentMessageDate}
                                </div>
                              </div>
                            </div>
                          ))
                        // <div className="chat-date">{currentMessageDate}</div>
                      }

                      {/* Render message */}
                    {  message.is_deleted===true?" ":<div ref={messagesEndRef}>
                        <div >
                          <div 
                            className={`chat ${
                              message.idFrom === userEmail
                                ? "chat-end"
                                : "chat-start "
                            }`}
                          >
                            <div className="chat-image avatar">
                              <div className="w-10 rounded-full">
                                <ProgressiveImage
                                  src={
                                    message.isAdmin === true
                                      ? message.senderImage
                                      : "https://dash.bestdiplomats.org" +
                                        getUserImageByName(message.idFrom)
                                  }
                                  placeholder={placeholderimg}
                                >
                                  {(src) => (
                                    <img
                                      className="w-10 rounded-full"
                                      loading="lazy"
                                      src={src}
                                      alt=""
                                    />
                                  )}
                                </ProgressiveImage>
                              </div>
                            </div>
                            <div className="chat-header">
                             <span className="flex">
                             {message.isAdmin === true ? (
                                message.senderName
                              ) : getUsercountry(message.idFrom) ? (
                                getUserGender(message.idFrom) +
                                " " +
                                getUserName(message.idFrom) 
                               
                              ) : (
                                <Skeleton
                                  className="mb-1"
                                  width={60}
                                  height={15}
                                />
                              )}
                              <span className="w-4 ml-1">{getUserShadowban(message.idFrom)}</span>
                              </span> 
                            </div>

                            <div
                              style={{
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "pre-wrap",
                              }}
                              // className={` ${
                              //   message.idFrom === userEmail
                              //     ? "chat-bubble cursor-pointer chat-bubble-primary banner-back"
                              //     : "chat-bubble cursor-pointer chat-bubble-accent chatbubble"
                              // }`}
                              key={message.id}
                              ref={(element) => (repliedMessageRefs.current[message.id] = element)}
                              className={`message ${searchTerm && message.content.includes(searchTerm) ? ' text-red-900' : ''} ${
                                  message.idFrom === userEmail
                                    ? "chat-bubble cursor-pointer chat-bubble-primary banner-back"
                                    : "chat-bubble cursor-pointer chat-bubble-accent chatbubble"
                                }`}
                              // ref={searchResults[currentResultIndex]?.id === message.id ? "text-red-900" : null}
                              // onClick={() => setScrollToMessageId(message.id)}
                            >
                              {message.type == "File" ? (<> {message.is_reply && (
                                <>
                                  <div 
                                  className="chat-bubble ruplychatbubble chat-start mb-1 p-2 flex flex-col mr-3"
                                   
            >
                                    {" "}
                                    <div className="px-2 mb-1 rounded-md bg-gray-300 border-l-4  whitespace-pre mr-5  flex justify-start border-blue-700 text-gray-900">
                                      {message.is_reply_to_admin === true
                                        ? message.isAdmin===false?message.reply_to: message.reply_owner_name
                                        : getUserGender(message.reply_to) +
                                          " " +
                                          getUserName(message.reply_to) 
                                        }<span className="w-4 ml-1">{getUserShadowban(message.reply_to)}</span>
                                    </div>
                                   <div className=" truncate ml-2 mr-1 w-72">
                                      {message.reply_type === "File" ? (
                                        message.reply_message_content.endsWith(
                                          ".png"
                                        ) ||
                                        message.reply_message_content.endsWith(
                                          ".jpg"
                                        ) ||
                                        message.reply_message_content.endsWith(
                                          ".jpeg"
                                        ) ||
                                        message.reply_message_content.endsWith(
                                          ".tiff"
                                        ) ? (
                                          <div
                                            // href={
                                            //   "https://dash.bestdiplomats.org" +
                                            //   message.reply_message_content
                                            // }
                                            // target="_blank"
                                          >
                                            <PrivateImage
                                              imageUrl={
                                                message.reply_message_content
                                              }
                                              className={"w-60 h-24"}
                                            />
                                          </div>
                                        ) : (
                                          <a
                                            className=" flex justify-center  items-center mr-4 mt-1 rounded-md border border-transparent bg-white px-1 py-1 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
                                            href={
                                              "https://dash.bestdiplomats.org" +
                                              message.reply_message_content
                                            }
                                            target="_blank"
                                          >
                                            {message?.reply_message_content?.split("/").pop()}
                                          </a>
                                        )
                                      ) :  message?.reply_message_content
                                      }
                                    </div>
                                  </div>
                                  
                                </>
                              )}
                               { message.content.endsWith(".png") ||
                                message.content.endsWith(".jpg") ||
                                message.content.endsWith(".jpeg") ||
                                message.content.endsWith(".tiff") ? (
                                  <div
                                    // href={
                                    //   "https://dash.bestdiplomats.org" +
                                    //   message.content
                                    // }
                                    // target="_blank"
                                  >
                                    <PrivateImage
                                      imageUrl={message.content}
                                      className={"w-64"}
                                    />
                                    {/* <img
                                className="w-64"
                                src={ "https://dash.bestdiplomats.org"+message.content }
                               /> */}
                                  </div>
                                ) : (
                                  <a
                                    className=" flex justify-center items-center w-72 rounded-md border border-transparent bg-white px-1 py-1 text-base font-medium text-gray-900 hover:bg-gray-100 "
                                    href={
                                      "https://dash.bestdiplomats.org" +
                                      message.content
                                    }
                                    target="_blank"
                                  >
                                     {message?.content?.split("/").pop()}
                                  </a>
                                )}
                               
                              </>) : message.is_reply === false ? (
                                message.content
                              ) : (
                                <>
                                  <div className="chat-bubble ruplychatbubble chat-start p-2 flex flex-col mr-4">
                                    {" "}
                                    <div className="px-2 mb-1 rounded-md bg-gray-300 border-l-4  whitespace-pre mr-5  flex justify-start border-blue-700 text-gray-900">
                                      {message.is_reply_to_admin === true
                                        ? message.reply_to
                                        : getUserGender(message.reply_to) +
                                          " " +
                                          getUserName(message.reply_to) }
                                          <span className="w-4 ml-1">{getUserShadowban(message.reply_to)}</span>
                                    </div>
                                   <div className=" truncate ml-2 mr-1 w-72" 
                                 onClick={() => handleReplyClick(message.reply_message_id)}>
                                      {message.reply_type === "File" ? (
                                        message.reply_message_content.endsWith(
                                          ".png"
                                        ) ||
                                        message.reply_message_content.endsWith(
                                          ".jpg"
                                        ) ||
                                        message.reply_message_content.endsWith(
                                          ".jpeg"
                                        ) ||
                                        message.reply_message_content.endsWith(
                                          ".tiff"
                                        ) ? (
                                          <div
                                            // href={
                                            //   "https://dash.bestdiplomats.org" +
                                            //   message.reply_message_content
                                            // }
                                            // target="_blank"
                                          >
                                            <PrivateImage
                                              imageUrl={
                                                message.reply_message_content
                                              }
                                              className={"w-60 h-24"}
                                            />
                                          </div>
                                        ) : (
                                          <a
                                            className=" flex justify-center  items-center mr-4 mt-1 rounded-md border border-transparent bg-white px-1 py-1 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
                                            href={
                                              "https://dash.bestdiplomats.org" +
                                              message.reply_message_content
                                            }
                                            target="_blank"
                                          >
                                           {message?.reply_message_content?.split("/").pop()}
                                          </a>
                                        )
                                      ) :  message?.reply_message_content
                                      }
                                    </div>
                                  </div>
                                  {message.content}
                                </>
                              )}
                              <div className="flex justify-end  items-center">
                                {" "}
                                <p
                                  className="underline cursor-pointer text-sm font-bold pr-2"
                                  onClick={() => {
                                    handleReply(message);
                                    // handleSwipeStart();
                                    // handleSwipeEnd();
                                  }}
                                >
                                  Reply
                                </p>
                                |
                                <p
                                  className="underline cursor-pointer  text-sm font-bold pl-2"
                                  onClick={() => {
                                    handleDeleteClick();
                                    setStoreDeleteMessageId(message.id);
                                  }}
                                >
                                  Delete
                                </p>
                              </div>
                            </div>
                            <div className="chat-footer opacity-50 ">
                              {moment
                                .unix(message?.dateTime?.seconds)
                                .format("LT") == "Invalid date"
                                ? "Now"
                                : moment
                                    .unix(message?.dateTime?.seconds)
                                    .format("LT")}
                              {/* { Date(message?.lastSeen?.seconds * 1000)?.toLocaleString() }  */}
                            </div>
                          </div>
                        </div>
                      </div>}
                    </>
                  );
                })}
              </div>
              {/* <div className="  bottom-0 w-full  ">
      <form onSubmit={handleSendMessage} className="containerWrap flex">
        <input value={value} onChange={e => setValue(e.target.value)} className="block w-full rounded-md border-0 py-2 rounded-r-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text" />
        <button type="submit" className="w-auto bg-gray-500 text-white rounded-r-lg px-5 text-sm"><PaperAirplaneIcon className="w-6 h-6" /></button>
      </form>
    </div> */}
            </div>
            <div className=" w-full p-1 border-t border-gray-300">
              {isSwip && (
                <div className=" border-l-8 mb-1 rounded-sm rounded-l-lg border-blue-700 py-1 pr-1   bg-gray-200">
                  <div className=" mt-1   flex justify-between gap-2 ">
                    <div className=" px-2 flex rounded-sm bg-gray-100 truncate ">
                      {isSwiped.isAdmin === true
                        ? isSwiped.senderName
                        : getUserGender(isSwiped.idFrom) +
                          " " +
                          getUserName(isSwiped.idFrom)}
                          <span className="w-4 ml-1">{getUserShadowban(isSwiped.idFrom)}</span>
                    </div>
                    <XMarkIcon
                      className="h-6 w-6 text-gray-500 cursor-pointer"
                      onClick={() => {
                        setIsSwip(false);
                        setIsSwiped(null);
                      }}
                    />
                  </div>
                  <div className="flex px-2 justify-between items-center">
                    <div className="w-50 h-10 truncate ">
                      {isSwiped.type == "String" ? (
                        isSwiped.content
                      ) : isSwiped.content.endsWith(".png") ||
                        isSwiped.content.endsWith(".jpg") ||
                        isSwiped.content.endsWith(".jpeg") ||
                        isSwiped.content.endsWith(".tiff") ? (
                        <a
                          href={
                            "https://dash.bestdiplomats.org" + isSwiped.content
                          }
                          target="_blank"
                        >
                          <PrivateImage
                            imageUrl={isSwiped.content}
                            className={"w-64"}
                          />
                        </a>
                      ) : (
                        <a
                          className=" flex justify-center mt-1 items-center w-full rounded-md border border-transparent bg-white px-1 py-1 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
                          href={
                            "https://dash.bestdiplomats.org" + isSwiped.content
                          }
                          target="_blank"
                        >
                            {isSwiped?.content?.split("/").pop()}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="flex-grow relative ">
                <textarea
                  className={`block w-full  scrollbar-hide py-2  pl-4 pr-28 ${textareaClass}  rounded-lg outline-none border border-slate-300 focus:text-gray-700 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1`}
                  style={{
                    width: "100%",
                    minHeight: "40px",
                    maxHeight: shouldApplyClass(value) ? "90px" : "40px",
                  }}
                  value={value}
                  ref={textAreaRef}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Type a message"
                />
                {/* {showEmojiPicker && (
                <Picker data={data} onEmojiSelect={console.log} />
              )} */}
                <div className="absolute right-2 bottom-1 ml-6 flex gap-2 items-center">
                  <button
                    onClick={handleSendMessage}
                    className="  bg-transparent border-none cursor-pointer"
                    type="submit"
                  >
                    <svg
                      className="w-6 h-6 text-gray-500 origin-center transform rotate-90"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="focus:outline-none  "
                    onClick={() => {
                      console.log("Show Picker happen");
                    }}
                  >
                    {loaderFlightFile ? (
                      <svg
                        fill="#87CEEB"
                        viewBox="0 0 1792 1792"
                        className="h-6 flex-no-shrink animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                      </svg>
                    ) : (
                      <>
                        <label
                          for="formFileSm"
                          className=" flex items-center cursor-pointer rounded-md border border-gray-300 bg-gray-300 px-0.5 py-0.5 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400"
                        >
                          <LinkIcon className="w-6 h-6" />
                        </label>
                        <input
                          className=" sr-only "
                          id="formFileSm"
                          type="file"
                          onChange={uploadFlightDocument}
                        ></input>
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="focus:outline-none  "
                    onClick={() => {
                      setShowEmojiPicker(!showEmojiPicker);
                      
                    }}
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      className="w-8 h-8"
                      strokeWidth={1.5}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          {" "}
          {showEmojiPicker && (
            <EmojiPicker
              onEmojiClick={onClick}
              autoFocusSearch={false}
              // theme={Theme.AUTO}
              serchDisablaed
              // skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
              height={400}
              width="66.5%"
              // emojiVersion="0.6"
              lazyLoadEmojis={true}
              // previewConfig={{
              //   defaultCaption: "Pick one!",
              //   defaultEmoji: "1f92a" // 🤪
              // }}
              // suggestedEmojisMode={SuggestionMode.RECENT}
              // skinTonesDisabled
              // searchPlaceHolder="Filter"
              // defaultSkinTone={SkinTones.MEDIUM}
              emojiStyle={EmojiStyle.apple}
              // categories={[
              //   {
              //     name: "Fun and Games",
              //     category: Categories.ACTIVITIES
              //   },
              //   {
              //     name: "Smiles & Emotions",
              //     category: Categories.SMILEYS_PEOPLE
              //   },
              //   {
              //     name: "Flags",
              //     category: Categories.FLAGS
              //   },
              //   {
              //     name: "Yum Yum",
              //     category: Categories.FOOD_DRINK
              //   }
              // ]}
            />
          )}
        </div>
      </div>
      <Transition.Root show={DeleteAllMessages} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDeleteAllMessages}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setDeleteAllMessages(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="">
                        <p className="text-sm text-gray-500">
                          Are you sure want to Delete all messages
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-60 justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 "
                      onClick={() =>{DeleteAllMessage(subcollectionRef)
                        setDeleteAllMessages(false)}}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={showPopup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShowPopup}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setShowPopup(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="">
                        <p className="text-sm text-gray-500">
                          Are you sure want to Delete message
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-60 justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 "
                      
                      onClick={() =>{ handleConfirmDelete()
                        setIsScrolFalse(true)}}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={showImagePopup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShowImagePopup}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowImagePopup(false);
                        setUploadFileResponse(null);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="">
                        <p className="text-sm text-gray-500">
                          {uploadFileResponse?.message?.file_name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-60 gap-2 justify-center items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-bold text-gray-200  shadow-sm hover:bg-blue-500 "
                      onClick={handleSendsubmit}
                    >
                      <p className=" font-semibold text-lg">Send</p>
                      <svg
                        className="w-7 h-7 text-gray-200 origin-center transform rotate-90"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                      </svg>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
    );
  };
  
  
  

export default DiplomaticChat