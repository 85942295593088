import { Toaster, toast } from "sonner";
   import React from 'react'
   export const ClearLocalStorage = async () => {

    toast.error('Timeout: You have been logged out')
    setTimeout(() => {
        
        localStorage.clear();
        window.location.reload();
    }, 2000);
 
    
   }
   
   <Toaster richColors/> 
   
    // window.location.reload()
  
  