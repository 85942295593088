import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlankLetter from "../../img/InvitationLetter.png";
import FontRoboto from "../../Roboto-Regular.ttf";
import axios from "axios";
import QRCode from "qrcode";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import toFilled from "../../img/toFilled.pdf";
import JSZip from "jszip";
import LoadingIcons  from 'react-loading-icons';

const InvitaionAll = () => {
  Font.register({
    src: FontRoboto,
  });
  const styles = StyleSheet.create({
    page: {},
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    viewer: {
      width: 610,
      height: 870,
    },
    bgimages: {
      height: 842,
      position: "absolute",
    },
    to: {
      top: 190,
      left: 78,
      fontSize: 12,
      marginTop: 4,
    },
    exellency: {
      top: 190,
      left: 78,
      fontSize: 12,
      marginTop: 9,
    },
    firstPara: {
      top: 190,
      marginHorizontal: 78,
      textAlign: "justify",
      fontSize: 12,
      marginTop: 9,
      lineHeight: 1.7,
    },
    secondPara: {
      top: 190,
      marginHorizontal: 77,
      textAlign: "justify",
      fontSize: 12,
      marginTop: 9,
      lineHeight: 1.7,
    },
    QRImage: {
      top: 155,
      left: 440,
      position: "absolute",
      width: 75,
    },
  });

  const { eventId } = useParams();
  const [currentUser, setCurrentUser] = useState();
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [allEventRegisterations, setAllEventRegisterations] = useState();

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const zip = new JSZip();
  const folder = zip.folder("files");

  const firstNameToTitleCase = (firstname) => {
    return firstname
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const secondNameToTitleCase = (secondname) => {
    return secondname
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };
  const fetchEventRegisteration = async (eventPass) => {
    try {
      const result = await axios
        .get(
          `https://dash.bestdiplomats.org/api/method/internal-registrations?filters=[["event","=","${eventId}"],["reg_status","!=","Suspended"]]`,
          config
        )
        .then((response) => {
          generatePdfDocument(toFilled);
          setAllEventRegisterations(response.data.data);
          // console.log(response.data.data);

          if (response.data.data.length > 0) {
            const forLoop = async () => {
              for (let index = 0; index < response.data.data.length; index++) {
                await sleep(1000);

                setCurrentUser(response.data.data[index]);
                var isGender;
                var firstname = firstNameToTitleCase(
                  response.data.data[index].delegate_profile.user.first_name
                );
                var lastname = secondNameToTitleCase(
                  response.data.data[index].delegate_profile.user.last_name
                );
                if (
                  response.data.data[index].delegate_profile.gender == "Male"
                ) {
                  isGender = "Mr.";
                } else if (
                  response.data.data[index].delegate_profile.gender == "Female"
                ) {
                  isGender = "Miss.";
                } else {
                  isGender = "Mr./Miss.";
                }

                const generateQR = async (text) => {
                  try {
                    return await QRCode.toDataURL(text, {
                      color: {
                        dark: "#000000", // Blue dots
                        light: "#0000", // Transparent background
                      },
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };

                generatePdfDocument(
                  <Document>
                    <Page size={[595, 842]}>
                      <Image
                        source={BlankLetter}
                        style={styles.bgimages}
                      ></Image>
                      <Image
                        source={generateQR(
                          window.$FWDurl +
                            "/InvitationValidate/" +
                            eventId +
                            "/" +
                            response.data.data[index].delegate_profile.user.name
                        )}
                        style={styles.QRImage}
                      ></Image>
                      <Text style={styles.to}>To</Text>
                      <Text style={styles.to}>The Visa Officer,</Text>
                      <Text
                        style={styles.to}
                      >{`Embassy/Consulate of ${eventPass?.country}`}</Text>
                      <Text style={styles.exellency}>Your Excellency,</Text>
                      <Text
                        style={styles.firstPara}
                      >{`It gives me immense pleasure to inform you that the ${
                        eventPass?.title
                      } is going to be held at ${eventPass?.venue} in ${
                        eventPass?.city
                      }, ${eventPass?.country} from ${moment(
                        eventPass?.start_date
                      ).format("D MMMM, YYYY")} to ${moment(
                        eventPass?.end_date
                      ).format(
                        "D MMMM, YYYY"
                      )}. Best Diplomats is a New York based International Organization which focuses on training & crafting Future Diplomats through Diplomatic Simulations. It serves as a platform for Young Leaders and Change makers to exchange ideas and resolve the most challenging global issues. These Diplomatic Simulations are an ideal avenue for aspiring Future Diplomats to grasp and grip the lobbying and critical thinking skill set that will enable them to become a better diplomat.`}</Text>
                      <Text
                        style={styles.secondPara}
                      >{`We hereby Confirm that ${isGender} ${firstname} ${lastname}, Bearer of Passport Number ${response.data.data[index].delegate_profile?.passport_number}, has been successfully confirmed as a participant at ${eventPass?.title}. In connection with this event, we hereby certify that the Accommodation, Meals and Local Transportation of the aforementioned individual during the course of the Event will be fully covered by the Best Diplomats Organization.`}</Text>
                    </Page>
                  </Document>,
                  firstname,
                  lastname
                );
              }

              zip
                .generateAsync({ type: "blob" })
                .then((blob) => saveAs(blob, "All Invitation Letters"));
              setIsDone(true);
            };
            forLoop();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  const generatePdfDocument = async (documentData, firstname, lastname) => {
    const blob = await pdf(documentData).toBlob();
    folder.file(firstname + " " + lastname + ".pdf", blob);

    // saveAs(blob,  firstname+" "+lastname);
  };

  //   Event Details
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const result = await axios
          .get("https://dash.bestdiplomats.org/api/resource/Events/" + eventId)
          .then((response) => {
            setEvent(response.data.data);
            //  console.log(response.data.data.country);
            fetchEventRegisteration(response.data.data);
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventData();
  }, []);

  //   useEffect(() => {
  //     const fetchEventRegisteration = async () => {
  //       try {

  //         const result = await axios
  //           .get(
  //             "https://dash.bestdiplomats.org/api/method/get-registrations?event=" + eventId,
  //             config
  //           )
  //           .then((response) => {
  //             generatePdfDocument(toFilled)
  //             setAllEventRegisterations(response.data.data);
  //             console.log(response.data.data);

  //             if((response.data.data).length>0){

  //                 for (let index = 0; index < (response.data.data).length; index++) {

  //                         console.log(">>Reg in loop Printing");

  //                     setCurrentUser(response.data.data[index]);
  //                     var isGender;
  //                       var firstname=firstNameToTitleCase(response.data.data[index].delegate_profile.user.first_name)
  //                       var lastname= secondNameToTitleCase(response.data.data[index].delegate_profile.user.last_name)
  //                       if(response.data.data[index].delegate_profile.gender=='Male'){
  //                         isGender="Mr."
  //                       }else if(response.data.data[index].delegate_profile.gender=='Female'){
  //                         isGender="Miss."
  //                       }else{
  //                        isGender="Mr./Miss."
  //                       }

  //                         const generateQR = async text => {
  //                             try {
  //                               return await QRCode.toDataURL(text,{
  //                                 color: {
  //                                   dark: '#000000',  // Blue dots
  //                                   light: '#0000' // Transparent background
  //                                 }
  //                               })

  //                             } catch (err) {
  //                               console.error(err)
  //                             }
  //                           }

  //                         generatePdfDocument(<Document>
  //                             <Page size={[595,842]} >
  //                             <Image source={BlankLetter} style={styles.bgimages}></Image>
  //                             <Image source={generateQR(window.$FWDurl+"/InvitationValidate/"+eventId+"/"+response.data.data[index].delegate_profile.user.name)} style={styles.QRImage}></Image>
  //                             <Text style={styles.to}>To</Text>
  //                             <Text style={styles.to}>The Visa Officer,</Text>
  //                             <Text style={styles.to}>{`Embassy/Consulate of ${event?.country}`}</Text>
  //                             <Text style={styles.exellency}>Your Excellency,</Text>
  //                             <Text style={styles.firstPara}>{`It gives me immense pleasure to inform you that the ${event?.title} is going to be held at ${event?.venue} in ${event?.city}, ${event?.country} from ${moment(event?.start_date).format("D MMMM, YYYY")} to ${moment(event?.end_date).format("D MMMM, YYYY")}. Best Diplomats is a New York based International Organization which focuses on training & crafting Future Diplomats through Diplomatic Simulations. It serves as a platform for Young Leaders and Change makers to exchange ideas and resolve the most challenging global issues. These Diplomatic Simulations are an ideal avenue for aspiring Future Diplomats to grasp and grip the lobbying and critical thinking skill set that will enable them to become a better diplomat.`}</Text>
  //                             <Text style={styles.secondPara}>{`We hereby Confirm that ${isGender} ${firstname} ${lastname}, Bearer of Passport Number ${response.data.data[index].delegate_profile?.passport_number}, has been successfully confirmed as a participant at ${event?.title}. In connection with this event, we hereby certify that the Accommodation, Meals and Local Transportation of the aforementioned individual during the course of the Event will be fully covered by the Best Diplomats Organization.`}</Text>
  //                              </Page>
  //                           </Document>, firstname , lastname)

  //                 }
  //             }

  //           });

  //       } catch (e) {
  //         console.log(e);
  //       }
  //     };
  //     fetchEventRegisteration();
  //   }, []);

  //   const generatePdfDocument = async (documentData,firstname,lastname) => {
  //     const zip = new JSZip();
  //     const folder = zip.folder("files");
  //     const blob = await pdf(documentData).toBlob();
  //     folder.file(firstname+" "+lastname+".pdf", blob);
  //     zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, firstname+" "+lastname));
  //     // saveAs(blob,  firstname+" "+lastname);
  // };

  return (
    <div>
      {isDone ? (
        <span className="group ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-green-700 my-4  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2">
          Done Downloading
        </span>
      ) : (
        <>
        <span className="group ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <LoadingIcons.SpinningCircles className="w-7 h-7"/>
      
        </span>
        <div className="flex justify-center">
        <span>This may take a few minutes. Your file will be download automatically.</span>
        </div>
        </>
      )}
    </div>
  );
};

export default InvitaionAll;
