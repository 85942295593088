import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CRProfileModuleSkeleton = () => {
  return (
    <> <div className="grid  grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-2 mt-4 border border-gray-200 rounded-md ">
    <div className="flex gap-4 items-start    flex-row px-1 py-4 sm:px-1 xl:px-1">
      <div className="">
      <Skeleton
                                  borderRadius={50}
                                  width={96}
                                  height={96}
                                />
        
      </div>
      <div className="flex flex-col  pt-5 ">
        <div className="">
          <h3 className="text-base font-semibold  tracking-tight text-gray-900">
          <Skeleton width={100} height={12} />
          </h3>
          <p className="text-sm font-semibold  text-gray-700">
          <Skeleton width={100} height={12} />
          </p>
          <p className="text-sm font-semibold  text-green-700">
          <Skeleton width={100} height={12} />
          </p>
        </div>
        <div className="flex gap-4 pt-4 ">
        <Skeleton width={50} height={25} />

        <Skeleton width={50} height={25} />
        </div>
      </div>
    </div>
    <div className="grid grid-cols-1  border-l sm:grid-row-2 lg:grid-row-2">
      <dl className=" grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-t-md bg-white  md:grid-cols-3 md:divide-x md:divide-y-0">
        <div className=" flex justify-center items-center flex-col px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items- justify-between md:block lg:flex">
            <div className="flex items-baseline text-xl font-semibold text-indigo-600">
            <Skeleton width={40} borderRadius={40} height={25} />
            </div>
          </dd>
          <dt className="text-base items-center font-normal text-gray-900">
            Last Active
          </dt>
        </div>
         <div className=" flex justify-center items-center flex-col px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items- justify-between md:block lg:flex">
            <div className="flex items-baseline text-xl font-semibold text-indigo-600">
            <Skeleton width={40} borderRadius={40} height={25} />
            </div>
          </dd>
          <dt className="text-base items-center font-normal text-gray-900">
         Points
          </dt>
        </div> 
        <div className=" flex justify-center items-center flex-col px-2 py-2 sm:p-2">
          <dd className="mb-1 flex items- justify-between md:block lg:flex">
            <div className="flex items-baseline text-xl font-semibold text-indigo-600">
            <Skeleton   borderRadius={40} width={40} height={40} />
           
            </div>
          </dd>
          <dt className="text-base items-center font-normal text-gray-900">
         Badge
          </dt>
        </div>
      </dl>

      <dl className=" grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-b-md bg-white border-t  md:grid-cols-2 md:divide-x md:divide-y-0">
        
          <div
            
            className=" flex justify-center items-center flex-col px-2 py-2 sm:p-2"
          >
            <dd className="mb-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-xl font-semibold text-indigo-600">
              <Skeleton width={40} borderRadius={40} height={25} />
              </div>
            </dd>
            <dt className="text-base font-normal text-gray-900">
            Successful Leads
            </dt>
          </div> 
          <div
            
            className=" flex justify-center items-center flex-col px-2 py-2 sm:p-2"
          >
            <dd className="mb-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-xl font-semibold text-indigo-600">
              <Skeleton width={40} borderRadius={40} height={25} />
              </div>
            </dd>
            <dt className="text-base font-normal text-gray-900">
            Total Leads
            </dt>
          </div>
      
      </dl>
    </div>
  </div></>
  )
}

export default CRProfileModuleSkeleton