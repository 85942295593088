import { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import {  XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";
var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const EventItinerary = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [isNotFoundName, setIsNotFoundName] = useState(false)
  const { eventID } = useParams();
  const [getAllEvents, setGetAllEvents] = useState([]);

  const tableRef = useRef(null);
  const [itineraries, setItineraries] = useState([]);
   useEffect(() => {
    const geteventdata = async () => {
      const result = await axios
        .get(
          `https://dash.bestdiplomats.org/api/method/event-itinerary-with-rundown?filters=[["event","=","${eventID}"]]`,
          config
        )
        .then((res) => {
            if(res.data.data.length==[]){
                setIsNotFoundName(true);
              }
          // console.log(res.data.data);
          setItineraries(res.data.data)
          setLoadingSkeleton(false)
       
        })
        .catch((e) => {
          console.log("t errrrr", e);
          setLoadingSkeleton(false)
          setIsNotFoundName(true);
 })
    };
    // console.log(inviteFilter);
    geteventdata();
  }, []);

 
 

  

//   useEffect(() => {
//     const fetchEventsData = async () => {
//       try {
//         const result = await axios
//           .get(
//             'https://dash.bestdiplomats.org/api/resource/Events?filters=[["status","in","Active,Upcoming"]]&fields=["*"]'
//           )
//           .then((response) => {
//             const newData = response.data.data
//             newData.unshift({name:"None",title:"All"})
//             setGetAllEvents(newData)
            
//           });
        
//       } catch (e) {
//         console.log(e);
//       }
//     };
//     fetchEventsData();
//   }, []);


  return (
    <>
 
      <div className="">
        <div className="mt-3 flex flex-col">
          <div ref={tableRef} className="-my-2 -mx-4 overflow-x-auto h-[40rem] sm:-mx-4 lg:-mx-4">
            <div className="inline-block  overflow-x-auto min-w-full space-y-8 py-2 align-middle md:px-2 lg:px-8">
            
              { isNotFoundName? <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                  </svg>
                   <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>: loadingSkeleton? <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-stone-100">
                    <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-start text-sm font-semibold text-gray-900 sm:pl-2"
                    >
                      Time
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Activity
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start  text-sm font-semibold text-gray-900"
                    >
                      Accommodation
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Non Accommodation
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-2 pr-2 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    </tr>
                  </thead>
                
                  <tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-2  text-sm sm:pl-2">
                     <div className="flex items-center">
                       
                               <Skeleton  width={90}  height={10}/>
                           
                          
                        </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     <Skeleton />
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                   
                     
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                   </tr>
                 
               </tbody>
               </table>: 
               itineraries?.map((person, index) => ( <>
                       
                        <h1 className=" underline text-lg font-bold text-center items-center">{index==0?"Detailed Itinerary":""}</h1>
                 <div className="overflow-hidden ring-1 ring-gray-200  md:rounded-lg">
               <div className="flex flex-row justify-center items-center py-4 gap-y-2">
                <div className=" flex-1 flex flex-col justify-center items-center py-4 gap-y-2">
                <h1 className=" underline text-lg font-semibold items-center">{index==0?"First Day":`Day ${index+1}`}</h1>
                   <h1  className=" underline text-base font-semibold items-center">{  moment(person?.date).format('LL')}<sup>th</sup></h1>
                  
                </div>
                   <h1 className="flex justify-end text-base font-normal text-end items-center py-2 px-2"><Link  className="rounded-md   gap-2 bg-blue-500 px-4 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" to={`itinerary-details/${eventID}/${person.name}`}>Update</Link></h1>
                   
                    </div>
                  
                    <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-stone-100">
                    <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-start text-sm font-semibold text-gray-900 sm:pl-2"
                    >
                      Time
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Activity
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start  text-sm font-semibold text-gray-900"
                    >
                      Accommodation
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Non Accommodation
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-2 pr-2 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    </tr>
                  </thead>
                 <tbody className="divide-y divide-gray-200">
                  {person?.rundown?.map((person, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-2">
                        {moment(person?.start_time, "HH:mm:ss").format('HH:mm')}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <div
                          className="prose mt-4"
                          dangerouslySetInnerHTML={{ __html: person.activity }}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.accommodation === 1 ? (
                          <CheckIcon className="h-6 w-6 text-gray-500" />
                        ) : (
                          <XMarkIcon className="h-6 w-6 text-gray-500" />
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.non_accommodation === 1 ? (
                          <CheckIcon className="h-6 w-6 text-gray-500" />
                        ) : (
                          <XMarkIcon className="h-6 w-6 text-gray-500" />
                        )}
                      </td>
                    
                    </tr>
                  ))}
                </tbody>
                </table>
                </div></>)) }
              
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default EventItinerary