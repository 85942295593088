import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PDFDocument, rgb } from "pdf-lib";
import BlankLetter from "../../img/invitation_letter_footer.png";
import BlankLetterTurkiye from "../../img/TurkeytoFill.png";
import BlankLetterlogo from "../../img/Logo-05.png";
import signature from "../../img/signature.png";
import signatureTurkey from "../../img/signature_discarded.png";
import signaturethailand from "../../img/signaturethailandletter.png";
import othersignatur from "../../img/othersignatur.png";
import Stamp from "../../img/Stamp-1.png";
import FontRoboto from "../../Roboto-Regular.ttf";

import axios from "axios";
import fontkit from "@pdf-lib/fontkit";
import QRCode from "qrcode";
import { useSelector } from "react-redux";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import moment from "moment";
import { FcDisplay } from "react-icons/fc";

const InvitationLetter = () => {
  Font.register({
    family: "Roboto",
    src: FontRoboto,
  });
  const styles = StyleSheet.create({
    page: {},
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    viewer: {
      width: 610,
      height: 870,
    },
    bgimages: {
      height: 842,
      position: "absolute",
    },
    to: {
      left: 40,
      fontSize: 13,
      marginTop: 50,
    },
    visa: {
      left: 40,
      fontSize: 13,
      marginTop: 4,
      lineHeight: 1.5,
    },
    salu: {
      left: 40,
      fontSize: 13,
      lineHeight: 1.5,
      paddingBottom: 4,
    },
    exellency: {
      left: 40,
      fontSize: 13,
      marginTop: 3,
    },
    firstPara: {
      top: 0,
      fontFamily: "Roboto",
      marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 13,
      marginTop: 13,
      lineHeight: 1.7,
    },
    secondPara: {
      top: 3,
      fontFamily: "Roboto",
      marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 13,
      lineHeight: 1.7,
      paddingBottom: "10px",
    },
    secondPara1: {
      top: 750,
      position: "absolute",
      left: 110,
      textAlign: "center",
      fontSize: 14,
      marginTop: 9,
      lineHeight: 1.3,
      fontWeight: "bold",

      // fontSize:"14px"
    },
    secondPara2: {
      top: 770,
      position: "absolute",
      left: 110,
      textAlign: "center",
      fontSize: 14,
      marginTop: 5,
      lineHeight: 1.3,
      fontWeight: "bold",
    },
    secondPara3: {
      top: 790,
      position: "absolute",
      left: 220,
      textAlign: "center",
      fontSize: 14,
      marginTop: 4,
      lineHeight: 1.3,
      fontWeight: "bold",
    },
    secondParat1: {
      top: 750,
      position: "absolute",
      left: 75,
      textAlign: "center",
      fontSize: 14,
      marginTop: 9,
      lineHeight: 1.3,
      fontWeight: "bold",

      // fontSize:"14px"
    },
    secondParat2: {
      top: 770,
      position: "absolute",
      left: 120,
      textAlign: "center",
      fontSize: 14,
      marginTop: 5,
      lineHeight: 1.3,
      fontWeight: "bold",
    },

    pictit: {
      fontSize: 17,
      color: "white",
      lineHeight: 1.2,
    },

    toptitle: {
      fontSize: 18,
      color: "white",
      lineHeight: 1.2,
    },
    underlinetitle: {
      textAlign: "center",
      textDecoration: "underline",
      top: 30,
      fontSize: 15,
      marginTop: 4,
      fontWeight: "bold",
    },
    QRImage: {
      top: 160,
      left: 450,
      position: "absolute",
      width: 85,
    },
    bg1: {
      backgroundColor: "#4691dc",
      height: "140px",
      textAlign: "center",
      paddingTop: "10px",
      paddingBottom: "6px",

      display: "flex",
      justifyContent: "center",

      flexDirection: "column",
    },
    bg2: {
      marginTop: 4,
      backgroundColor: "#4691dc",
      height: "5px",
      gap: 2,
    },
    toplogo: {
      width: "40px",
    },
    Image: {
      zIndex: -1,

      textAlign: "center",
      left: 0,
      color: "red",
      marginHorizontal: 190,
      width: 180,
      height: 180,
    },
    ftimg: {
      top: 811.5,
      position: "absolute",
      height: "25px",
      zIndex: -1,
      fontSize: 14,
      marginTop: 2,
      lineHeight: 0,
    },
    reagrds: {
      top: 610,
      position: "absolute",
      left: 40,
      textAlign: "justify",
      fontSize: 13,
      marginTop: 10,
      lineHeight: 0,
    },
    othrsignatur: {
      top: 640,
      position: "absolute",
      left: 30,
      color: "red",
      // left: "15",
      width: "140",
    },
    othrsignaturedubai:{
      top: 630,
      position: "absolute",
      left: 30,
      color: "red",
      // left: "15",
       width: "110",
       height:"40"
    },

    signatur: {
      top: 640,
      position: "absolute",
      left: 40,
      color: "red",
      left: "15",
      width: "160",
    },
    regnalhd: {
      top: 670,
      position: "absolute",
      left: 40,
      // marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 13,
      // marginTop: 3,
      lineHeight: 0,
    },
    fotname: {
      top: 690,
      position: "absolute",
      left: 40,
      // marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 13,
      // marginTop: 4,
      lineHeight: 0,
    },
    ftlink: {
      top: 708,
      position: "absolute",
      left: 40,
      color: "blue",

      textAlign: "justify",
      fontSize: 13,

      lineHeight: 0,
    },

    location: {
      top: 725,
      position: "absolute",
      left: 40,

      textAlign: "justify",
      fontSize: 13,

      lineHeight: 0,
    },
    stamp: {
      zIndex: -1,
      opacity: 0.9,
      position: "absolute",
      top: "620",
      left: "170",
      width: "80",
      height: "80",
    },
  });

  const stylesTurk = StyleSheet.create({
    page: {},
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    viewer: {
      width: 610,
      height: 870,
    },
    bgimages: {
      height: 842,
      position: "absolute",
    },
    to: {
      top: 190,
      left: 42,
      fontSize: 12,
      marginTop: 4,
    },

    exellency: {
      top: 190,
      left: 42,
      fontSize: 12,
      marginTop: 9,
    },
    firstPara: {
      top: 190,
      marginHorizontal: 42,
      textAlign: "justify",
      fontSize: 12,
      marginTop: 9,
      lineHeight: 1.7,
    },
    secondPara: {
      top: 190,
      marginHorizontal: 42,
      textAlign: "justify",
      fontSize: 12,
      marginTop: 9,
      lineHeight: 1.7,
    },

    QRImage: {
      top: 155,
      left: 440,
      position: "absolute",
      width: 75,
    },
  });

  const { eventId } = useParams();
  const [currentUser, setCurrentUser] = useState();
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState(false);
  const [qrUriImageFile, setqrUriImageFile] = useState();
  const [isRegisteredFinal, setIsRegisteredFinal] = useState();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  
  const [firstParaGraph1, setFirstParaGraph1] = useState(null);
  const [secondParaGraph2, setSecondParaGraph2] = useState(null);

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [isTrukiyeEvent, setIsTurkiyeEvent] = useState(false);
  const [isOtherEvent, setIsOtherEvent] = useState(false);
  useEffect(() => {
    if (eventId == "ecd56a5ae2") {
      setIsTurkiyeEvent(true);
    } else {
      setIsOtherEvent(true);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await axios
          .get(
            "https://dash.bestdiplomats.org/api/method/get-delegate?email=" +
              userEmail,
            config
          )
          .then((responseUser) => {
            // console.log("user", responseUser.data);
            // setCurrentUser(responseUser.data.data);
            // const firstNameToTitleCase = (firstname) => {
            //   return firstname
            //     .toLowerCase()
            //     .split(" ")
            //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            //     .join(" ");
            // };
            // const secondNameToTitleCase = (secondname) => {
            //   return secondname
            //     .toLowerCase()
            //     .split(" ")
            //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            //     .join(" ");
            // };
            // setFirstName(
            //   firstNameToTitleCase(responseUser.data.data.user.first_name)
            // );
            // setLastName(
            //   secondNameToTitleCase(responseUser.data.data.user.last_name)
            // );
            // if (responseUser.data.data.gender == "Male") {
            //   setIsGender("Mr.");
            // } else if (responseUser.data.data.gender == "Female") {
            //   setIsGender("Miss.");
            // } else {
            //   setIsGender("Mr./Miss.");
            // }
            const fetchEventRegisteration = async () => {
              try {
                setLoading(true);
                const result = await axios
                  .get(
                    `https://dash.bestdiplomats.org/api/method/get-registration?event=${eventId}&delegate=${responseUser?.data?.data?.name}`,
                    config
                  )
                  .then((response) => {
                    // console.log(response.data);
                    if (response.data.error == "Not found") {
                      setIsRegisteredFinal("Not Registered");
                    } else {
                      // console.log(response.data.data.name);
                      setIsRegisteredFinal(
                        window.$FWDurl +
                          "/InvitationValidate/" +
                          eventId +
                          "/" +
                          responseUser.data.data.user.name
                      );
                    }
                    if (
                      response.data.data.payment_package == "Full Experience" ||
                      response.data.data.payment_package ==
                        "Full Experience (Second Payment Pending)"
                    ) {
                      var fullExperience=true;
                    }
                    const firstNameToTitleCase = (firstname) => {
                      return firstname
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");
                    };
                    const secondNameToTitleCase = (secondname) => {
                      return secondname
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");
                    };
                    const fetchEventData = async () => {
                      try {
                        setLoading(true);
                        const result = await axios
                          .get(
                            "https://dash.bestdiplomats.org/api/resource/Events/" +
                              eventId
                          )
                          .then((eventres) => {
                            // console.log("cuntry", eventres.data.data);
                            setEvent(eventres.data.data);

                            const currrentuser = async () => {
                              await axios
                                .get(
                                  "https://dash.bestdiplomats.org/api/method/get-delegate?email=" +
                                    userEmail,
                                  config
                                )
                                .then((resuser) => {
                                  // console.log("user", resuser.data);
                                  var isGender;
                                  setCurrentUser(resuser.data.data);
                                  if (resuser.data.data.gender == "Male") {
                                    // console.log(resuser.data.data.gender);
                                    isGender = "Mr.";
                                  } else if (
                                    resuser.data.data.gender == "Female"
                                  ) {
                                    isGender = "Miss.";
                                  } else {
                                    isGender = "Mr./Miss.";
                                  }
                                  if (eventres.data.data.country == "Turkiye") {
                                    const fetchdata = async () => {
                                      try {
                                        setLoading(true);
                                        const result = await axios
                                          .get(
                                            `https://dash.bestdiplomats.org/api/resource/Invitation Letter?filters=[["for_conference","=","${eventId}"],["language","=","Turkish"]]&fields=["*"]`,
                                            config
                                          )
                                          .then((response) => {
                                            var parat_1 =
                                              response?.data?.data[0]
                                                .body_paragraph_1;
                                            var parat_2 =
                                              response?.data?.data[0]
                                                .body_paragraph_2;
                                            var parat_3 =
                                              response?.data?.data[0]
                                                .body_paragraph_2_full_experience;
                                            setFirstParaGraph1(parat_1);
                                            if (fullExperience) {
                                              let sp_parat_3 =
                                                parat_3?.split(
                                                  ">DELEGATE_NAME"
                                                );
                                              let slp_parat3 =
                                                sp_parat_3[0] +
                                                isGender +
                                                " " +
                                                firstNameToTitleCase(
                                                  resuser.data.data.user
                                                    .first_name
                                                ) +
                                                " " +
                                                secondNameToTitleCase(
                                                  resuser.data.data.user
                                                    .last_name
                                                ) +
                                                sp_parat_3[1];
                                              var slip_parat_3 =
                                                slp_parat3.split(
                                                  ">PASSPORT_NUMBER"
                                                );
                                              var final_parat_3 =
                                                slip_parat_3[0] +
                                                resuser.data.data
                                                  .passport_number +
                                                slip_parat_3[1];
                                              // console.log(">><<<>", final_parat_3);
                                              setSecondParaGraph2(
                                                final_parat_3
                                              );
                                            } else {
                                              let sp_parat_2 =
                                                parat_2?.split(
                                                  ">DELEGATE_NAME"
                                                );
                                              let slp_parat2 =
                                                sp_parat_2[0] +
                                                isGender +
                                                " " +
                                                firstNameToTitleCase(
                                                  resuser.data.data.user
                                                    .first_name
                                                ) +
                                                " " +
                                                secondNameToTitleCase(
                                                  resuser.data.data.user
                                                    .last_name
                                                ) +
                                                sp_parat_2[1];
                                              var slip_parat_2 =
                                                slp_parat2.split(
                                                  ">PASSPORT_NUMBER"
                                                );
                                              var final_parat_2 =
                                                slip_parat_2[0] +
                                                resuser.data.data
                                                  .passport_number +
                                                slip_parat_2[1];
                                              // console.log(
                                              //   ">><<<>>",
                                              //   final_parat_2
                                              // );
                                              setSecondParaGraph2(
                                                final_parat_2
                                              );
                                            }

                                            // console.log(response.data.data);
                                            setLoading(false);
                                            setRecipient(response.data.data[0]);
                                          });
                                      } catch (e) {
                                        console.log(e);
                                      }
                                    };
                                    fetchdata();
                                  } else {
                                    const fetchdata = async () => {
                                      try {
                                        setLoading(true);
                                        const result = await axios
                                          .get(
                                            `https://dash.bestdiplomats.org/api/resource/Invitation Letter?filters=[["for_conference","=","${eventId}"],["language","=","English"]]&fields=["*"]`,
                                            config
                                          )
                                          .then((response) => {
                                            // console.log(
                                            //   firstNameToTitleCase(
                                            //     resuser.data.data.user
                                            //       .first_name
                                            //   )
                                            // );

                                            var para_1 =
                                              response?.data?.data[0]
                                                .body_paragraph_1;
                                            var para_2 =
                                              response?.data?.data[0]
                                                .body_paragraph_2;
                                            var para_3 =
                                              response?.data?.data[0]
                                                .body_paragraph_2_full_experience;
                                            // console.log(para)
                                            let sp_para1 =
                                              para_1?.split(">EVENT_START");
                                            let slp_para =
                                              sp_para1[0] +
                                              moment(
                                                eventres.data.data.start_date
                                              ).format("D MMMM, YYYY") +
                                              sp_para1[1];
                                            var slp_para_1 =
                                              slp_para.split(">EVENT_END");
                                            var final_para_1 =
                                              slp_para_1[0] +
                                              moment(
                                                eventres.data.data.end_date
                                              ).format("D MMMM, YYYY") +
                                              slp_para_1[1];
                                            // console.log(final_para_1);
                                            setFirstParaGraph1(final_para_1);
                                            if (fullExperience) {
                                              let sp_para_3 =
                                                para_3?.split(">DELEGATE_NAME");
                                              let slp_para3 =
                                                sp_para_3[0] +
                                                isGender +
                                                " " +
                                                firstNameToTitleCase(
                                                  resuser.data.data.user
                                                    .first_name
                                                ) +
                                                " " +
                                                secondNameToTitleCase(
                                                  resuser.data.data.user
                                                    .last_name
                                                ) +
                                                sp_para_3[1];
                                              var slip_para_3 =
                                                slp_para3.split(
                                                  ">PASSPORT_NUMBER"
                                                );
                                              var final_para_3 =
                                                slip_para_3[0] +
                                                resuser.data.data
                                                  .passport_number +
                                                slip_para_3[1];
                                              setSecondParaGraph2(final_para_3);
                                              console.log(
                                                ">>>full",
                                                final_para_3
                                              );

                                              setRecipient(
                                                response.data.data[0]
                                              );
                                              // console.log(response.data.data);
                                            } else {
                                              let sp_para_2 =
                                                para_2?.split(">DELEGATE_NAME");
                                              let slp_para2 =
                                                sp_para_2[0] +
                                                isGender +
                                                " " +
                                                firstNameToTitleCase(
                                                  resuser.data.data.user
                                                    .first_name
                                                ) +
                                                " " +
                                                secondNameToTitleCase(
                                                  resuser.data.data.user
                                                    .last_name
                                                ) +
                                                sp_para_2[1];
                                              var slip_para_2 =
                                                slp_para2.split(
                                                  ">PASSPORT_NUMBER"
                                                );
                                              var final_para_2 =
                                                slip_para_2[0] +
                                                resuser.data.data
                                                  .passport_number +
                                                slip_para_2[1];
                                              // console.log(">>>",final_para_2);
                                              setSecondParaGraph2(final_para_2);

                                              setRecipient(
                                                response.data.data[0]
                                              );
                                              // console.log(response.data.data);
                                            }
                                          });
                                        setLoading(false);
                                      } catch (e) {
                                        console.log(">>>>>>>>>>>>>", e);
                                      }
                                    };
                                    fetchdata();
                                  }
                                });
                            };
                            currrentuser();
                          });
                        setLoading(false);
                      } catch (e) {
                        console.log(e);
                      }
                    };

                    fetchEventData();
                  });
                setLoading(false);
              } catch (e) {
                console.log(e);
              }
            };
            fetchEventRegisteration();
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchUserData();
  }, []);
  const [recipient, setRecipient] = useState(null);

  //   Event Details
  useEffect(() => {}, []);

  useEffect(() => {
    const generateQR = async (text) => {
      try {
        setqrUriImageFile(
          await QRCode.toDataURL(text, {
            color: {
              dark: "#000000", // Blue dots
              light: "#0000", // Transparent background
            },
          })
        );
      } catch (err) {
        console.error(err);
      }
    };
    generateQR(isRegisteredFinal);
  }, [isRegisteredFinal]);

  return (
    <div>
      {isOtherEvent && (
        <>
          <PDFDownloadLink
            document={
              <Document>
                <Page size="A4">
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                      gap: "5px",
                    }}
                  >
                    <View style={styles.bg1}>
                      <Text>
                        <Image
                          src={"https://i.ibb.co/HXXskrL/Logo-02.png"}
                          style={{ width: "95px", height: "85px" }}
                        ></Image>
                      </Text>
                      <Text style={styles.pictit}>Best Diplomats</Text>
                      <Text style={styles.toptitle}>
                        {"" + recipient?.letterhead_tagline}
                      </Text>
                    </View>
                    <Text style={styles.bg2}></Text>
                    <Text style={styles.underlinetitle}>
                      {"" + recipient?.underlined_document_title}
                    </Text>
                    <Image src={qrUriImageFile} style={styles.QRImage}></Image>
                    <Text style={styles.to}>To</Text>
                    <Text style={styles.visa}>
                      {"" + recipient?.recipient_designation}
                    </Text>
                    <Text style={styles.salu}>
                      {"" + recipient?.recipient_location}
                    </Text>
                    <Text style={styles.exellency}>
                      {"" + recipient?.recipient_salutation}
                    </Text>
                    <Image
                      src={BlankLetterlogo}
                      style={{
                        zIndex: -3,
                        opacity: 0.1,
                        position: "absolute",
                        top: "290",
                        left: "40",
                        width: "510",
                        height: "310",
                      }}
                    ></Image>
                    <View style={{ textAlign: "justify" }}>
                      <Text style={styles.firstPara}>{firstParaGraph1}</Text>
                      <Text style={styles.secondPara}>{secondParaGraph2} </Text>
                    </View>
                    <Text style={styles.reagrds}>Regards,</Text>
                    <Image
                      src={event?.country == "Thailand" ? signaturethailand : othersignatur}
                      style={event?.country == "UAE" ? styles.othrsignaturedubai : styles.othrsignatur}
                    ></Image>
                    <Image style={styles.stamp} src={Stamp}></Image>
                    <Text style={styles.regnalhd}>
                      {"" + recipient?.sender_designation}
                    </Text>
                    <Text style={styles.fotname}>
                      {"" + recipient?.sender_full_name}
                    </Text>
                    <Text style={styles.ftlink}>
                      {"" + recipient?.sender_email}
                    </Text>
                    <Text style={styles.location}>
                      {"" + recipient?.sender_location}
                    </Text>
                    <Text style={styles.secondPara1}>
                      {"" + recipient?.letterhead_footer_line_1}
                    </Text>
                    <Text style={styles.secondPara2}>
                      {"" + recipient?.letterhead_footer_line_2}
                    </Text>
                    <Text style={styles.secondPara3}>
                      {"" + recipient?.letterhead_footer_line_3}
                    </Text>
                    <Image src={BlankLetter} style={styles.ftimg}></Image>
                  </View>
                </Page>
              </Document>
            }
            fileName={`Invitation-${currentUser?.user?.first_name}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <span className="group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  Loading...
                </span>
              ) : (
                <span className="group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  Download PDF
                </span>
              )
            }
          </PDFDownloadLink>
          <PDFViewer style={styles.viewer}>
            <Document>
              <Page size="A4">
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    gap: "5px",
                  }}
                >
                  <View style={styles.bg1}>
                    <Text>
                      <Image
                        src={"https://i.ibb.co/HXXskrL/Logo-02.png"}
                        style={{ width: "95px", height: "85px" }}
                      ></Image>
                    </Text>
                    <Text style={styles.pictit}>Best Diplomats</Text>
                    <Text style={styles.toptitle}>
                      {"" + recipient?.letterhead_tagline}
                    </Text>
                  </View>
                  <Text style={styles.bg2}></Text>
                  <Text style={styles.underlinetitle}>
                    {"" + recipient?.underlined_document_title}
                  </Text>
                  <Image src={qrUriImageFile} style={styles.QRImage}></Image>
                  <Text style={styles.to}>To</Text>
                  <Text style={styles.visa}>
                    {"" + recipient?.recipient_designation},
                  </Text>
                  <Text style={styles.salu}>
                    {"" + recipient?.recipient_location}
                  </Text>
                  <Text style={styles.exellency}>
                    {"" + recipient?.recipient_salutation},
                  </Text>
                  <Image
                    src={BlankLetterlogo}
                    style={{
                      zIndex: -3,
                      opacity: 0.09,
                      position: "absolute",
                      top: "290",
                      left: "40",
                      width: "510",
                      height: "310",
                    }}
                  ></Image>
                  <View style={{ textAlign: "justify" }}>
                    <Text style={styles.firstPara}>{"" + firstParaGraph1}</Text>
                    <Text style={styles.secondPara}>
                      {"" + secondParaGraph2}{" "}
                    </Text>
                  </View>
                  <Text style={styles.reagrds}>Regards,</Text>
                  <Image
                      src={event?.country=="Thailand"? signaturethailand:othersignatur}
                      style={event?.country=="UAE"? styles.othrsignaturedubai:styles.othrsignatur}
                  ></Image>
                  <Image style={styles.stamp} src={Stamp}></Image>
                  <Text style={styles.regnalhd}>
                    {"" + recipient?.sender_designation}
                  </Text>
                  <Text style={styles.fotname}>
                    {"" + recipient?.sender_full_name}
                  </Text>
                  <Text style={styles.ftlink}>
                    {"" + recipient?.sender_email}
                  </Text>
                  <Text style={styles.location}>
                    {"" + recipient?.sender_location}
                  </Text>
                  <Text style={styles.secondPara1}>
                    {"" + recipient?.letterhead_footer_line_1}
                  </Text>
                  <Text style={styles.secondPara2}>
                    {"" + recipient?.letterhead_footer_line_2}
                  </Text>
                  <Text style={styles.secondPara3}>
                    {"" + recipient?.letterhead_footer_line_3}
                  </Text>
                  <Image src={BlankLetter} style={styles.ftimg}></Image>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </>
      )}
      {isTrukiyeEvent && (
        <>
          <PDFDownloadLink
            document={
              <Document>
                <Page size="A4">
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                      gap: "5px",
                    }}
                  >
                    <View style={styles.bg1}>
                      <Text>
                        <Image
                          src={"https://i.ibb.co/HXXskrL/Logo-02.png"}
                          style={{ width: "95px", height: "85px" }}
                        ></Image>
                      </Text>
                      <Text style={styles.pictit}>Best Diplomats</Text>
                      <Text style={styles.toptitle}>
                        {"" + recipient?.letterhead_tagline}
                      </Text>
                    </View>
                    <Text style={styles.bg2}></Text>
                    <Text style={styles.underlinetitle}>
                      {"" + recipient?.underlined_document_title}
                    </Text>
                    <Image src={qrUriImageFile} style={styles.QRImage}></Image>
                    <Text style={styles.to}>To</Text>
                    <Text style={styles.visa}>
                      {"" + recipient?.recipient_designation},
                    </Text>
                    <Text style={styles.salu}>
                      {"" + recipient?.recipient_location}
                    </Text>
                    <Text style={styles.exellency}>
                      {"" + recipient?.recipient_salutation},
                    </Text>
                    <Image
                      src={BlankLetterlogo}
                      style={{
                        zIndex: -3,
                        opacity: 0.09,
                        position: "absolute",
                        top: "290",
                        left: "40",
                        width: "510",
                        height: "310",
                      }}
                    ></Image>

                    <Text style={styles.firstPara}>{"" + firstParaGraph1}</Text>
                    <Text style={styles.secondPara}>
                      {"" + secondParaGraph2}{" "}
                    </Text>

                    <Text style={styles.reagrds}>Regards,</Text>
                    <Image src={event?.country == "Malaysia" ? signature : signatureTurkey} style={styles.signatur}></Image>
                    <Image
                      style={{
                        zIndex: -1,
                        opacity: 0.9,
                        position: "absolute",
                        top: "630",
                        left: "160",
                        width: "80",
                        height: "80",
                      }}
                      src={Stamp}
                    ></Image>
                    <Text style={styles.regnalhd}>
                      {"" + recipient?.sender_designation}
                    </Text>
                    <Text style={styles.fotname}>
                      {"" + recipient?.sender_full_name}
                    </Text>
                    <Text style={styles.ftlink}>
                      {"" + recipient?.sender_email}
                    </Text>
                    <Text style={styles.location}>
                      {"" + recipient?.sender_location}
                    </Text>
                    <Text style={styles.secondParat1}>
                      {"" + recipient?.letterhead_footer_line_1}
                    </Text>
                    <Text style={styles.secondParat2}>
                      {"" + recipient?.letterhead_footer_line_2}
                    </Text>
                    <Text style={styles.secondPara3}>
                      {"" + recipient?.letterhead_footer_line_3}
                    </Text>
                    <Image src={BlankLetter} style={styles.ftimg}></Image>
                  </View>
                </Page>
              </Document>
            }
            fileName={`Invitation-${currentUser?.user?.first_name}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <span className="group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  Loading...
                </span>
              ) : (
                <span className="group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  Download PDF
                </span>
              )
            }
          </PDFDownloadLink>
          <PDFViewer style={styles.viewer}>
            <Document>
              <Page size="A4">
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    gap: "5px",
                  }}
                >
                  <View style={styles.bg1}>
                    <Text>
                      <Image
                        src={"https://i.ibb.co/HXXskrL/Logo-02.png"}
                        style={{ width: "95px", height: "85px" }}
                      ></Image>
                    </Text>
                    <Text style={styles.pictit}>Best Diplomats</Text>
                    <Text style={styles.toptitle}>
                      {"" + recipient?.letterhead_tagline}
                    </Text>
                  </View>
                  <Text style={styles.bg2}></Text>
                  <Text style={styles.underlinetitle}>
                    {"" + recipient?.underlined_document_title}
                  </Text>
                  <Image src={qrUriImageFile} style={styles.QRImage}></Image>
                  <Text style={styles.to}>To</Text>
                  <Text style={styles.visa}>
                    {"" + recipient?.recipient_designation},
                  </Text>
                  <Text style={styles.salu}>
                    {"" + recipient?.recipient_location}
                  </Text>
                  <Text style={styles.exellency}>
                    {"" + recipient?.recipient_salutation},
                  </Text>
                  <Image
                    src={BlankLetterlogo}
                    style={{
                      zIndex: -3,
                      opacity: 0.09,
                      position: "absolute",
                      top: "290",
                      left: "40",
                      width: "510",
                      height: "310",
                    }}
                  ></Image>

                  <Text style={styles.firstPara}>{"" + firstParaGraph1}</Text>
                  <Text style={styles.secondPara}>
                    {"" + secondParaGraph2}{" "}
                  </Text>

                  <Text style={styles.reagrds}>Regards,</Text>
                  <Image src={event?.country == "Malaysia" ? signature : signatureTurkey} style={styles.signatur}></Image>
                  <Image
                    style={{
                      zIndex: -1,
                      opacity: 0.9,
                      position: "absolute",
                      top: "630",
                      left: "160",
                      width: "80",
                      height: "80",
                    }}
                    src={Stamp}
                  ></Image>
                  <Text style={styles.regnalhd}>
                    {"" + recipient?.sender_designation}
                  </Text>
                  <Text style={styles.fotname}>
                    {"" + recipient?.sender_full_name}
                  </Text>
                  <Text style={styles.ftlink}>
                    {"" + recipient?.sender_email}
                  </Text>
                  <Text style={styles.location}>
                    {"" + recipient?.sender_location}
                  </Text>
                  <Text style={styles.secondParat1}>
                    {"" + recipient?.letterhead_footer_line_1}
                  </Text>
                  <Text style={styles.secondParat2}>
                    {"" + recipient?.letterhead_footer_line_2}
                  </Text>
                  <Text style={styles.secondPara3}>
                    {"" + recipient?.letterhead_footer_line_3}
                  </Text>
                  <Image src={BlankLetter} style={styles.ftimg}></Image>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </>
      )}
    </div>
  );
};

export default InvitationLetter;
