import React, { useEffect, useState } from 'react'

import BlankCertificate from '../../img/Certificatenew.png'
import FontAbhaya from '../../AbhayaLibre-SemiBold.ttf'
import RobotoBold from '../../Roboto-Bold.ttf'
import AbhayaRegular from '../../AbhayaLibre-Regular.ttf'
import AbhayaBold from '../../AbhayaLibre-Bold.ttf'
import QRCode from 'qrcode'
import { Document, Page, Text, View, StyleSheet, Image, Font, } from '@react-pdf/renderer';
import moment from "moment";

const CustomCertificate = (props) => {

    if (
        (
          props.FirstName +
          props.LastName
        ).length > 25
      ) {
        
        var setFontSize = 32;
        var setFullNameTop = 245;
        var setLowerViewTop = 20;
      } else {
        
        var setFontSize = 48;
        var setFullNameTop = 230;
        var setLowerViewTop = 0;
      }

    Font.register({
        family: "AbhayaLibreSemiBold",
        src: FontAbhaya,
      });
      Font.register({
        family: "RobotoBold",
        src: RobotoBold,
      });
      Font.register({
        family: "AbhayaRegular",
        src: AbhayaRegular,
      });
      Font.register({
        family: "AbhayaBold",
        src: AbhayaBold,
      });
      const styles = StyleSheet.create({
        page: {},
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
        viewer: {
          width: 870,
          height: 690,
        },
        bgimages: {
          height:595,
          width:842,
          position: "absolute",
        },
        title: {
          top: 15,
          textAlign: "center",
          fontSize: 58,
          color: "white",
          fontFamily: "AbhayaLibreSemiBold",
        },
        participation: {
          top: 15,
          textAlign: "center",
          fontSize: 21,
          color: "#F3D649",
          fontFamily: "RobotoBold",
        },
        initialline: {
          letterSpacing: 1.5,
          top: 210,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        showcase: {
          top: 250,
          textAlign: "center",
          fontSize: 17,
          color: "#000000",
          fontFamily: "AbhayaRegular",
          marginHorizontal: 140,
        },
        complete: {
          top: 260,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        completeDate: {
          top: 260,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        },
        completeLocation: {
          top: 260,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        fullname: {
          textAlign: "center",
          color: "#4c8dcb",
          fontFamily: "AbhayaRegular",
        },
        headNameRight: {
          position:'absolute',
          top: 375,
          left:72,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
        },
        headNameLeft: {
          position:'absolute',
          left:650,
          top: 375,
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
          marginLeft: 18,
        },
        headPositionRight: {
          position:'absolute',
          top: 395,
          left:78,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginRight: 45,
        },
        headPositionLeft: {
          position:'absolute',
          left:657,
          top: 395,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginLeft: 24,
        },
        QRImage: {
          top: 510,
          left: 390,
          position: "absolute",
          width: 75,
        },
      });

    // States
   
    const [qrUriImageFile, setqrUriImageFile] = useState();
  
  const generateQR = async (text) => {
    try {
      setqrUriImageFile(
        await QRCode.toDataURL(text, {
          color: {
            dark: "#4591DC", // Blue dots
            light: "#0000", // Transparent background
          },
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  generateQR(props.qrText);
    
  return (
  <Document>
    <Page size={[842,595]} >
    <Image src={BlankCertificate} style={styles.bgimages}></Image>
    <Text style={styles.title}>CERTIFICATE</Text>
    <Text style={styles.participation}>{`OF ${props.type}`}</Text>
    <Text style={styles.initialline}>THIS CERTIFICATE IS PRESENTED TO</Text>
    <Text  style={{
                          top: setFullNameTop,
                          fontSize: setFontSize,
                          textAlign: "center",
                          color: "#4c8dcb",
                          fontFamily: "AbhayaRegular",
                        }}>{""}{""+(props.FirstName)?.toUpperCase()+" "+(props.LastName)?.toUpperCase()}</Text>
    <View  style={{ top: setLowerViewTop }}>
    <Text style={styles.showcase}>{props.first_line}</Text>
    
    
    <Text style={styles.complete}>{props.second_line}</Text>
    

    
    <Text style={styles.headNameRight}>Oleksandra Mamchii</Text>
   
    <Text style={styles.headNameLeft}>Fawad Ali Langah</Text>
    
    
    
    <Text style={styles.headPositionRight}>Academic Lead</Text>
   
    <Text style={styles.headPositionLeft}>Director General</Text>
   
    </View>
    <Image source={qrUriImageFile} style={styles.QRImage}></Image>

    </Page>
  </Document>
  )
}

export default CustomCertificate