import React from 'react'

const Tag = (props) => {
  return (
    <span className={`inline-flex rounded-full bg-${props.color}-100 px-2 text-xs font-semibold leading-5 text-${props.color}-800`}>
                        {props.text}
                        </span>
  )
}

export default Tag